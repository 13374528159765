import React from 'react';
import { Card, CardBody, CardHeader, Row, Col, InputGroup, Input, InputGroupAddon } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { createInvoiceContent, getInvoiceComponents } from "../../actions/Invoices";
import AsyncSelect from "react-select/async/dist/react-select.esm";

class InvoiceNewContentPanel extends React.Component {

    constructor( props ) {
        super( props );

        this.state = {
            component: { id: "", comment: "", quantity: "", price: "" },
            components: []
        };
        this.handleComponentInput = this.handleComponentInput.bind( this );
        this.handlePriceChange = this.handlePriceChange.bind( this );
        this.handleCommentChange = this.handleCommentChange.bind( this );
        this.handleQuantityChange = this.handleQuantityChange.bind( this );
        this.getSelectOptions = this.getSelectOptions.bind( this );
    }

    componentDidMount() {
        this.props.getInvoiceComponents().then( components => {
            this.setState( {
                components
            } );
        } );
    }

    handleComponentInput( input ) {
        for ( let i = 0; i < this.state.components.length; i++ ) {
            if ( this.state.components[ i ].id === input.value ) {
                this.setState( {
                    component: {
                        id: input.value,
                        price: this.state.components[ i ].price,
                        comment: this.state.component.comment,
                        quantity: 1,
                        uom: this.state.components[ i ].uom
                    }
                } );
            }
        }
    }

    handlePriceChange( event ) {
        this.setState( {
            component: {
                id: this.state.component.id,
                price: event.target.value,
                comment: this.state.component.comment,
                uom: this.state.component.uom,
                quantity: this.state.component.quantity
            }
        } );
    }

    handleCommentChange( event ) {
        this.setState( {
            component: {
                id: this.state.component.id,
                price: this.state.component.price,
                comment: event.target.value,
                uom: this.state.component.uom,
                quantity: this.state.component.quantity
            }
        } )
    }

    handleQuantityChange( event ) {
        this.setState( {
            component: {
                id: this.state.component.id,
                quantity: event.target.value,
                comment: this.state.component.comment,
                price: this.state.component.price,
                uom: this.state.component.uom
            }
        } );
    }

    getSelectOptions( input, callback ) {
        let componentSelect = [];
        for ( let i = 0; i < this.state.components.length; i++ ) {
            componentSelect.push( { value: this.state.components[ i ].id, label: this.state.components[ i ].name } );
        }
        callback( null, {
            options: componentSelect,
            complete: true
        } );
    };

    updateInvoice() {
        this.props.createInvoiceContent( this.props.invoice.id, this.state.component.id, this.state.component.comment, this.state.component.price, this.state.component.quantity ).then( invoice => {
            this.setState( {
                component: {
                    id: "",
                    comment: "",
                    quantity: "",
                    price: ""
                }
            } );
            this.props.updated( invoice );
        } );
    }

    render() {
        const { component, components } = this.state;
        return (
            <Card className="card-default">
                <CardHeader>
                    <h3>New content</h3>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={ 3 }>

                            <AsyncSelect
                                loadOptions={ ( input, update ) => {
                                    update( components.filter( component => {
                                        return component.name.toLowerCase().includes( input.toLowerCase() );
                                    } ).map( component => {
                                        return {
                                            value: component.id,
                                            label: component.name
                                        }
                                    } ) );
                                } }
                                defaultOptions
                                onChange={ this.handleComponentInput }
                            />
                        </Col>
                        <Col md={ 3 }>
                            <Input
                                type="text" className="form-control" value={ component.comment }
                                onChange={ this.handleCommentChange }/></Col>
                        <Col md={ 3 }>
                            <InputGroup>
                                <Input type="number" value={ component.quantity }
                                       onChange={ this.handleQuantityChange }/>
                                <InputGroupAddon addonType="append">{ component.uom || "" }</InputGroupAddon>

                            </InputGroup>
                        </Col>
                        <Col md={ 3 }>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">&euro;</InputGroupAddon>
                                <Input placeholder="price" value={ component.price }
                                       onChange={ this.handlePriceChange } onKeyPress={ e => {
                                    if ( e.key === 'Enter' ) {
                                        this.updateInvoice();
                                    }
                                } }/>
                            </InputGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        user: state.UserInfo
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( {
        getInvoiceComponents,
        createInvoiceContent
    }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( InvoiceNewContentPanel ) );

