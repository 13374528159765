import { action } from './Util';

export const LAYOUT_CHANGE = "LAYOUT_CHANGE";

export function setLayout(logo, color, sidebar, title) {
  document.title = title;
  return dispatch => {
    dispatch( action( LAYOUT_CHANGE, {logo, color, sidebar, title} ) );
  };
}

export function setDefaultLayout() {
  document.title = "PushTo.Space";
  return dispatch => {
    dispatch( action( LAYOUT_CHANGE, {} ) );
  };
}