import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Button, Col, Row, Table } from 'reactstrap';
import {
  createInvoiceContent,
  getInvoice,
  getInvoiceComponents,
  sendInvoiceReminder,
  updateInvoice
} from "../../actions/Invoices";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Loader from "../Common/Loader.jsx";
import { API_LOCATION } from "../../Constants";
import InvoicePaymentPanel from "./InvoicePaymentPanel";
import {
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import InvoiceDetailsPanel from "./InvoiceDetailsPanel";
import InvoiceNewContentPanel from "./InvoiceNewContentPanel";
import prettyBytes from "../Common/PrettyBytes";


class Invoice extends React.Component {

  constructor() {
    super();
    this.state = {
      invoice: {},
      sendMailButton: { text: "Send mail", style: "secondary" },


    };
    this.sendEmail = this.sendEmail.bind( this );
    this.updateInvoiceCallback = this.updateInvoiceCallback.bind( this );
    this.fetchInvoice = this.fetchInvoice.bind( this );
    this.sendInvoiceDueDateReminder = this.sendInvoiceDueDateReminder.bind( this );
    this.sendInvoiceNewReminder = this.sendInvoiceNewReminder.bind( this );
  }

  componentDidMount() {
    this.setState( { invoice: this.props.invoices[ this.props.match.params.invoiceId ] } );
    this.fetchInvoice();
  }

  updateInvoiceCallback( invoice ) {
    this.setState( { invoice } );
  }

  fetchInvoice() {
    this.props.getInvoice( this.props.match.params.invoiceId ).then( invoice => {
      this.setState( { invoice } );
    } );
  }


  sendEmail( event ) {
    this.setState( { sendMailButton: { text: "Sending", style: "warning" } } );
    this.props.sendInvoiceReminder( this.props.match.params.invoiceId, event ).then( () => {
      this.setState( { sendMailButton: { text: "Sent", style: "success" } } );
      setTimeout( function() {
        this.setState( { sendMailButton: { text: "Send mail", style: "secondary" } } );
      }.bind( this ), 5000 );
    } ).catch( () => {

      this.setState( { sendMailButton: { text: "Error", style: "danger" } } );
      setTimeout( function() {
        this.setState( { sendMailButton: { text: "Send mail", style: "secondary" } } );
      }.bind( this ), 5000 );
    } );
  }

  sendInvoiceDueDateReminder( event ) {
    this.props.sendInvoiceReminder( this.props.match.params.invoiceId, "due" );
  }

  sendInvoiceNewReminder( event ) {
    this.props.sendInvoiceReminder( this.props.match.params.invoiceId, "new" );
  }

  toggleDD = dd => {
    this.setState( {
      [ dd ]: !this.state[ dd ]
    } )
  };

  render() {
    const { invoice } = this.state;
    const { match, user } = this.props;
    return (
      <ContentWrapper>
        <div className="content-heading">
          Invoice #{ match.params.invoiceId }
        </div>

        <Row>
          <Col lg={ 4 }>

            <InvoiceDetailsPanel updated={ this.updateInvoiceCallback } invoice={ invoice }/>

            <InvoicePaymentPanel fetchHandler={ this.fetchInvoice } invoice={ invoice }/>

          </Col>
          <Col lg={ 8 }>
            <Card className="card-default">
              <CardHeader>
                { ( invoice.id !== undefined ) &&
                  <Button
                    onClick={ () => window.open( `${API_LOCATION}/invoices/${invoice.id}/print` ) }
                    color="primary"
                    className="pull-right">
                    <em className="fa fa-print fa-fw mr-sm" />Print</Button>
                }
                { ( user.admin ) ?
                  <div className="pull-right" style={ { marginRight: '5px' } }>
                    <ButtonDropdown
                      isOpen={ this.state[ `ddSingleS` ] }
                      toggle={ () => this.toggleDD( `ddSingleS` ) } id={ `send_button` }>
                      <DropdownToggle caret color={ this.state.sendMailButton.style }>
                        { this.state.sendMailButton.text }
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={ () => this.sendEmail( "new" ) }>New invoice</DropdownItem>
                        <DropdownItem onClick={ () => this.sendEmail( "days" ) }>Due date approaching</DropdownItem>
                        <DropdownItem onClick={ () => this.sendEmail( "due" ) }>Past due date</DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                  : ""
                }
                <h3>Content</h3>
              </CardHeader>
              <CardBody>
                { ( invoice.id !== undefined && invoice.contents !== undefined ) ?
                  <div>
                    <Table responsive hover>
                      <thead>
                      <tr>
                        <th>Item #</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Unit Price</th>
                        <th className="text-right">Total</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        invoice.contents.map( function( content ) {
                            return <tr key={ content.id }>
                              <td>{ content.element.id }</td>
                              <td>{ content.element.name } <b>{ content.comment }</b></td>
                                { (content.element.uom === "B/Hrs") ?
                                  <td>{ prettyBytes(content.quantity) } { content.element.uom }</td>
                                  :
                                  <td>{ parseFloat(content.quantity.toFixed(3)) } { content.element.uom }</td>
                                }
                              <td>&euro; { parseFloat( Math.round( content.unitprice * 100 ) / 100 ).toFixed( 2 ) }</td>
                              <td
                                className="text-right">&euro; { parseFloat( Math.round( content.price * 100 ) / 100 ).toFixed( 2 ) }</td>
                            </tr>
                          }
                        )
                      }
                      </tbody>
                    </Table>
                    <Row style={ { margin: '0px', padding: '0.75rem' } }>
                      <Col sm={ 8 }/>
                      <Col sm={ 4 } className="pv">
                        <div className="clearfix">
                          <p className="pull-left">Subtotal</p>
                          <p
                            className="pull-right mr">&euro; { parseFloat( Math.round( invoice.total * 100 ) / 100 ).toFixed( 2 ) }</p>
                        </div>
                        <div className="clearfix">
                          <p className="pull-left">Tax (21%)</p>
                          <p
                            className="pull-right mr">&euro; { parseFloat( Math.round( invoice.tax_total * 100 ) / 100 ).toFixed( 2 ) }</p>
                        </div>
                        <div className="clearfix">
                          <p className="pull-left h3">TOTAL</p>
                          <p
                            className="pull-right mr h3">&euro; { parseFloat( Math.round( invoice.grand_total * 100 ) / 100 ).toFixed( 2 ) }</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  :
                  <Loader/>
                }
              </CardBody>
            </Card>

            { ( invoice.id !== undefined && invoice.due_date === "DRAFT" && user.admin ) &&
            <InvoiceNewContentPanel updated={ this.updateInvoiceCallback } invoice={ invoice }/>
            }

          </Col>
        </Row>
      </ContentWrapper>

    );
  }
}

const mapStateToProps = ( state ) => {
  return {
    user: state.UserInfo,
    invoices: state.Invoices
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( {
    getInvoice,
    updateInvoice,
    getInvoiceComponents,
    createInvoiceContent,
    sendInvoiceReminder
  }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( Invoice );
