import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Table, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Loader from "../Common/Loader";
import {
    containsRule, countVisibles, isInitialized, meIsAdmin, NewSearch
} from "../Common/Utils";
import NoResults from "../Common/NoResults";
import { getDatabases } from "../../actions/Databases";
import DatabasesOverviewRow from "./DatabasesOverviewRow";
import NewDatabasePanel from "./NewDatabasePanel";
import SearchBar from "../Common/SearchBar";


class DatabasesOverview extends React.Component {

    constructor( props ) {
        super( props );
        this.search = NewSearch( [
            ( n, c ) => {
                return containsRule( c.id.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.name.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.description.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.user.name.toLowerCase(), n )
            }
        ], () => Object.values( this.props.databases ) );
        this.search.onUpdate( () => this.forceUpdate() )
    }

    componentDidMount() {
        this.search.refresh();
        this.props.getDatabases();
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.search.refresh();
    }


    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    Databases
                </div>
                <Row>
                    <Col lg={ 8 }>
                        <Card className="card-default">
                            <CardHeader>
                                <h3 style={ { display: 'inline' } }>{ this.search.printVisibles([ "databases", "database" ]) }</h3>
                                <SearchBar search={ this.search }/>
                            </CardHeader>
                            <CardBody>
                                { isInitialized( this.search.getVisibles() ) ?
                                    <div>
                                        <Table responsive hover>
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                { meIsAdmin() &&
                                                <th>User</th>
                                                }
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Size</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            { this.search.getVisibles().map( database => {
                                                    return <DatabasesOverviewRow database={ database }/>;
                                                } ) }
                                            </tbody>
                                        </Table>
                                        { countVisibles( this.search.getVisibles() ) === 0 &&
                                        <NoResults/>
                                        }
                                    </div>
                                    :
                                    <Loader/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={ 4 }>
                        <NewDatabasePanel/>
                    </Col>
                </Row>
            </ContentWrapper>);
    }
}

const mapStateToProps = ( state ) => {
    return {
        databases: state.Databases,
        users: state.Users
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { getDatabases }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( DatabasesOverview );
