import React from 'react';
import Timestamp from "react-timestamp";
import { Link, withRouter } from "react-router-dom";
import { meIsAdmin } from "../Common/Utils";


class InvoicesOverviewRow extends React.Component {

    render() {
        const { invoice } = this.props;
        return (
            <tr>
                <td>
                    <Link to={ `/console/invoice/${ invoice.id }` }
                          style={ { cursor: "pointer" } }>{ invoice.id }</Link>
                </td>
                { meIsAdmin() &&
                <td>
                    <Link to={ `/console/user/${ invoice.user.id }` }
                          style={ { cursor: "pointer" } }>{ invoice.user.name }</Link>
                </td>
                }
                <td><Timestamp date={ invoice.date } options={ { format: "date" } }/></td>
                <td>{
                    invoice.due_date === "DRAFT" ?
                        <div className="inline wd-xxs badge badge-warning">
                            Draft</div>
                        :
                        <Timestamp date={ invoice.due_date } options={ { format: "date" } }/>
                }</td>
                <td>&euro; { parseFloat( Math.round( invoice.grand_total * 100 ) / 100 ).toFixed( 2 ) }</td>
                <td>
                    {
                        (invoice.due_date === "DRAFT") ?
                            <div className="inline wd-xxs badge badge-info">
                                N/A</div>
                            :
                            (invoice.grand_total - invoice.paid_payments_total > 0) ?
                                (invoice.due_date > (new Date()).getTime() / 1000) ?
                                    <div
                                        className="inline wd-xxs badge badge-warning">
                                        No</div>
                                    :
                                    <div
                                        className="inline wd-xxs badge badge-danger">
                                        No</div>

                                :
                                <div
                                    className="inline wd-xxs badge badge-success">
                                    Yes</div>
                    }
                </td>
            </tr>);
    }
}

export default withRouter( InvoicesOverviewRow );
