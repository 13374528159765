import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Table, Card, CardBody, CardHeader, Col, Row, Input, Button } from "reactstrap";
import Loader from "../Common/Loader";
import {
    containsRule, countVisibles, isInitialized, meIsAdmin, resetVisibles, searchArray,
    visibleTitle
} from "../Common/Utils";
import NoResults from "../Common/NoResults";
import { getCrons, deleteCron, createCron } from "../../actions/Crons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import UserSelect from "../User/UserSelect";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";


class WebcronsOverview extends React.Component {

    constructor( props ) {
        super( props );
        this.state = { user_id: "", url: "", interval: "" };
        this.handleSearch = this.handleSearch.bind( this );
        this.createCronjob = this.createCronjob.bind( this );
    }

    componentDidMount() {
        this.props.getCrons();
    }

    componentWillUnmount() {
        resetVisibles( this.props.webcrons );
    }

    createCronjob() {
        this.props.createCron(this.state.url, this.state.interval, this.state.user_id).then(() =>{
            this.props.getCrons();
        });
    }

    handleSearch( event ) {
        searchArray( event.target.value, this.props.webcrons, [
            ( n, c ) => {
                return containsRule( c.id.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.url.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.user.name.toLowerCase(), n )
            }
        ] );
        this.forceUpdate();
    }

    render() {
        const webcrons = Object.values( this.props.webcrons );
        return (
            <ContentWrapper>
                <div className="content-heading">
                    Webcrons
                </div>
                <Row>
                    <Col lg={ 12 }>
                        <Card className="card-default">
                            <CardHeader>
                                <h3 style={ { display: 'inline' } }>{ visibleTitle( webcrons, [ "webcrons", "webcron" ] ) }</h3>
                                <input placeholder="Search" type="text" autoComplete="off"
                                       className="form-control pull-right" onChange={ this.handleSearch }/>
                            </CardHeader>
                            <CardBody>
                                { isInitialized( webcrons ) ?
                                    <div>
                                        <Table responsive hover>
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                { meIsAdmin() &&
                                                <th>User</th>
                                                }
                                                <th>Url</th>
                                                <th>Interval</th>
                                                <th>Last response-code</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            { webcrons.filter( webcron => (webcron.visible === undefined || webcron.visible) )
                                                .map( webcron => {
                                                    return (<tr key={ webcron.id }>
                                                        <td>{ webcron.id }</td>
                                                        <td><Link
                                                            to={ `/console/user/${ webcron.user.id }` }>{ webcron.user.name }</Link>
                                                        </td>
                                                        <td>{ webcron.url }</td>
                                                        <td>{ webcron.cron }</td>
                                                        <td>???</td>
                                                        <td><span onClick={ () => {
                                                            this.props.deleteCron( webcron.id ).then( this.props.getCrons )
                                                        } } className="pull-right"
                                                                  style={ { color: 'red', cursor: 'pointer' } }>
                        <FontAwesomeIcon icon={ faTimes } style={ { marginRight: '5px' } }/></span>
                                                        </td>
                                                    </tr>);
                                                } ) }
                                            </tbody>
                                        </Table>
                                        { countVisibles( webcrons ) === 0 &&
                                        <NoResults/>
                                        }
                                    </div>
                                    :
                                    <Loader/>
                                }
                            </CardBody>
                        </Card>
                        <Card className="card-default">
                            <CardHeader>
                                <h3>New webcron</h3>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={ 3 }>
                                        { meIsAdmin() &&
                                        <UserSelect users={ this.props.users }
                                                    callback={ id => this.setState( { user_id: id } ) }/>
                                        }
                                    </Col>
                                    <Col md={ 5 }>
                                        <Input
                                            type="text" className="form-control" placeholder={ 'Url' }
                                            onChange={ e => this.setState( { url: e.target.value } ) }/>
                                    </Col>

                                    <Col md={ 2 }>
                                        <Input
                                            type="text" className="form-control" placeholder={ 'Interval' }
                                            onChange={ e => this.setState( { interval: e.target.value } ) }/></Col>
                                    <Col md={ 2 }>
                                        <Button onClick={ this.createCronjob } color="primary">
                                            <FontAwesomeIcon icon={ faPlusCircle }/>
                                            Create
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ContentWrapper>);
    }
}

const mapStateToProps = ( state ) => {
    return {
        webcrons: state.Crons,
        users: state.Users
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { getCrons, createCron, deleteCron }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( WebcronsOverview );
