import {createRequestTypes, action} from './Util';
import * as api from '../services/Api';

export const GET_VOLUMES = createRequestTypes('GET_VOLUMES');
export const CREATE_VOLUME = createRequestTypes('CREATE_VOLUME');
export const GET_VOLUME_SIZES = createRequestTypes('GET_VOLUME_SIZES');
export const GET_VOLUME_DEPLOYMENTS = createRequestTypes('GET_VOLUME_DEPLOYMENTS');
export const CREATE_VOLUME_FTP = createRequestTypes('CREATE_VOLUME_FTP');
export const DELETE_VOLUME_FTP = createRequestTypes('DELETE_VOLUME_FTP');

export const getVolumesDispatchers = {
  request: () => action(GET_VOLUMES.REQUEST),
  success: (volumes) => action(GET_VOLUMES.SUCCESS, volumes),
  failure: (error) => action(GET_VOLUMES.FAILURE, error),
};
export const createVolumeDispatchers = {
  request: () => action(CREATE_VOLUME.REQUEST),
  success: (volume) => action(CREATE_VOLUME.SUCCESS, volume),
  failure: (error) => action(CREATE_VOLUME.FAILURE, error),
};
export const getVolumeDeploymentsDispatchers = {
  request: () => action(GET_VOLUME_DEPLOYMENTS.REQUEST),
  success: (deployments) => action(GET_VOLUME_DEPLOYMENTS.SUCCESS, deployments),
  failure: (error) => action(GET_VOLUME_DEPLOYMENTS.FAILURE, error),
};
export const getVolumeSizesDispatchers = {
  request: () => action(GET_VOLUME_SIZES.REQUEST),
  success: (sizes) => action(GET_VOLUME_SIZES.SUCCESS, sizes),
  failure: (error) => action(GET_VOLUME_SIZES.FAILURE, error),
};
export const createVolumeFtpUserDispatchers = {
  request: () => action(CREATE_VOLUME_FTP.REQUEST),
  success: (volumes) => action(CREATE_VOLUME_FTP.SUCCESS, volumes),
  failure: (error) => action(CREATE_VOLUME_FTP.FAILURE, error),
};
export const deleteVolumeFtpUserDispatchers = {
  request: () => action(DELETE_VOLUME_FTP.REQUEST),
  success: (volumes) => action(DELETE_VOLUME_FTP.SUCCESS, volumes),
  failure: (error) => action(DELETE_VOLUME_FTP.FAILURE, error),
};

export function getVolumes() {
    return dispatch => {
        dispatch(getVolumesDispatchers.request());
        return api.getVolumes()
            .then(volumes => {
                dispatch(getVolumesDispatchers.success(volumes));
                return volumes;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(getVolumesDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function createVolume(name, engine, template, account, backup_policy, user_id) {
    return dispatch => {
        dispatch(createVolumeDispatchers.request());
        return api.createVolume(name, engine, template, account, backup_policy, user_id)
            .then(volume => {
                dispatch(createVolumeDispatchers.success(volume));
                return volume;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(createVolumeDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function getVolumeSizes(volume_id) {
    return dispatch => {
        dispatch(getVolumeSizesDispatchers.request());
        return api.getVolumeSizes(volume_id)
            .then(sizes => {
                dispatch(getVolumeSizesDispatchers.success(sizes));
                return sizes;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(getVolumeSizesDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function getVolumeDeployments(volume_id) {
    return dispatch => {
        dispatch(getVolumesDispatchers.request());
        return api.getVolumeDeployments(volume_id)
            .then(deployments => {
                dispatch(getVolumeDeploymentsDispatchers.success(deployments));
                return deployments;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(getVolumeDeploymentsDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function createNewFtpUser( volume_id, password ) {
    return dispatch => {
        dispatch(createVolumeFtpUserDispatchers.request());
        return api.createVolumeFtpUser(volume_id, password)
            .then(ftp => {
                dispatch(createVolumeFtpUserDispatchers.success(ftp));
                return ftp;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(createVolumeFtpUserDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function deleteFtpUser( volume_id, ftp_id ) {
    return dispatch => {
        dispatch(deleteVolumeFtpUserDispatchers.request());
        return api.deleteVolumeFtpUser(volume_id, ftp_id)
            .then(ftp => {
                dispatch(deleteVolumeFtpUserDispatchers.success(ftp));
                return ftp;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(deleteVolumeFtpUserDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}
