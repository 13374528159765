import React from 'react';
import { Button, Col, Form, FormGroup, Table } from 'reactstrap';
import Loader from "../Common/Loader.jsx";
import { createNewMailboxAlias, deleteMailboxAlias } from "../../actions/Domains";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";


class MailBoxAliasesPanel extends React.Component {

  constructor() {
    super();
    this.state = {
      mailbox: {},
      addingAlias: false,
      addAliasButton: { text: "Add", disabled: false, style: "primary" }
    };
    this.addAlias = this.addAlias.bind( this );
    this.deleteAlias = this.deleteAlias.bind( this );
  }

  componentDidMount() {
    this.setState( { mailbox: this.props.mailbox } );
  }

  componentWillReceiveProps( newProps, newContext ) {
    this.setState( { mailbox: newProps.mailbox } );
  }

  addAlias() {
    this.props.createNewMailboxAlias( this.props.mailbox.domainId, this.props.mailbox.id, this.aliasInput.value ).then( alias => {
      this.aliasInput.value = "";
      let mailbox = this.state.mailbox;
      mailbox.aliases.push( alias );
      this.setState( { mailbox, addingAlias: false } );
      setTimeout( function() {
        this.setState( { addAliasButton: { text: "Add", disabled: false, style: "primary" } } );
      }.bind( this ), 5000 );
    } ).catch( () => {
      this.setState( { addAliasButton: { disabled: true, text: "Error", style: "danger" } } );
      setTimeout( function() {
        this.setState( { addAliasButton: { text: "Add", disabled: false, style: "primary" } } );
      }.bind( this ), 5000 );
    } );
  }

  deleteAlias( aliasId ) {
    let mailbox = this.state.mailbox;
    let aliases = mailbox.aliases;
    mailbox.aliases = [];
    aliases.map( alias => {
      if ( alias.id !== aliasId ) {
        mailbox.aliases.push( alias );
      }
      return true;
    } );
    this.setState( {
      mailbox
    } );
    this.props.deleteMailboxAlias( this.props.mailbox.domainId, this.props.mailbox.id, aliasId );
  }

  render() {
    const { addAliasButton, mailbox } = this.state;
    const { addAlias, deleteAlias } = this;
    return (
      <Card className="card-default">
        <CardHeader>
          <div style={ { fontSize: '14px' } }>
            Aliases
            { ( this.state.addingAlias ) ?
              mailbox.aliases !== undefined && mailbox.aliases.length > 0 &&
              <Button onClick={ () => {
                this.setState( {
                  addingAlias: false
                } );
              } } className={ 'pull-right' } color={ "danger" } style={ { color: 'white' } }>Cancel</Button>
              :
              mailbox.aliases !== undefined && mailbox.aliases.length > 0 &&
              <Button onClick={ () => {
                this.setState( {
                  addingAlias: true
                } );
              } } className={ 'pull-right' } color={ "primary" } style={ { color: 'white' } }>New
                alias</Button>
            }
          </div>
        </CardHeader>
        <CardBody>
          { ( mailbox.address !== undefined ) ?
            !this.state.addingAlias && mailbox.aliases.length > 0 ?
              <Table responsive bordered hover>
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th/>
                </tr>
                </thead>
                <tbody>
                {
                  mailbox.aliases.map( function( alias ) {
                      return <tr key={ alias.id }>
                        <td>{ alias.id }</td>
                        <td>{ alias.address }
                        </td>
                        <td>
                            <span onClick={ () => { deleteAlias( alias.id ); } }
                                  style={ { color: "red", cursor: "pointer" } }>
                          <FontAwesomeIcon icon={ faTimes } style={ { marginRight: '5px' } }/>
                        </span>
                        </td>

                      </tr>
                    }
                  )

                }
                </tbody>
              </Table>
              :
              <Form className="form-horizontal">
                <FormGroup>
                  <Col lg={ 2 }>
                    <Label>Alias</Label>
                  </Col>
                  <Col lg={ 10 }>
                    <Input
                      innerRef={ input => this.aliasInput = input }
                      type="email" placeholder="Alias"/>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col lg={ 2 }>
                    <Label>To</Label>
                  </Col>
                  <Col lg={ 10 }>
                    <Input
                      type="email" placeholder="Alias" disabled value={ mailbox.address }/>
                  </Col>
                </FormGroup>
                <Button ref="addAliasButton" onClick={ addAlias }
                        className={ 'btn-block' }
                        disabled={ addAliasButton.disabled }
                        color={ addAliasButton.style }>
                  { addAliasButton.text }
                </Button>
              </Form>
            :
            <Loader/>
          }
        </CardBody>
      </Card>
    );
  }
}

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { createNewMailboxAlias, deleteMailboxAlias }, dispatch )
};

export default connect( null, mapDispatchToProps )( MailBoxAliasesPanel );

