import React from 'react';
import { Table } from 'reactstrap';
import Loader from "../Common/Loader.jsx";
import { connect } from "react-redux";
import { getVolumeDeployments } from "../../actions/Volumes";
import { bindActionCreators } from "redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import { isInitialized } from "../Common/Utils";


class VolumeDeploymentsPanel extends React.Component {

  constructor() {
    super();
    this.state = {
      deployments: undefined
    };
  }

  componentWillReceiveProps( nextProps, nextContext ) {
    this.props.getVolumeDeployments( nextProps.volume.id ).then( deployments => {
      this.setState( { deployments } );
    } );
  }

  render() {
    const { deployments } = this.state;
    return (
      <Card className="card-default">
        <CardHeader>
          <div style={ { fontSize: '14px' } }>
            Deployments
          </div>
        </CardHeader>
        <CardBody>
          { isInitialized( deployments ) ?
            deployments.length === 0 ?
              <div style={{textAlign:'center'}}><em className="fa fa-rocket" style={{fontSize: '50px',display:'block', marginBottom: '10px'}}/><h6 style={{fontStyle:'italic'}}>You don't have any automatic deployments yet!</h6></div>
              :
              <Table responsive hover>
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Source</th>
                  <th/>
                </tr>
                </thead>
                <tbody>
                {
                  deployments.map( deployment => {
                      return <tr key={ deployment.id }>
                        <td>{ deployment.id }</td>
                        <td>{ deployment.archive.root }</td>
                      </tr>
                    }
                  )
                }

                </tbody>
              </Table>
            :
            <Loader/>
          }
        </CardBody>
      </Card>
    );
  }
}

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { getVolumeDeployments }, dispatch )
};

export default connect( null, mapDispatchToProps )( VolumeDeploymentsPanel );

