import React, { Component } from 'react';
import prettyBytes from "../Common/PrettyBytes";
import { bindActionCreators } from "redux";
import {
    detachDiskFromVm,
    powerOffVm,
    powerOnVm,
    getDisks,
    attachDiskToVm,
    deleteVm,
    createImageFromVm, removeForwardFromIp, addForwardToIp
} from "../../actions/Vms";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import StateButton from "../Common/StateButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { Col } from "reactstrap";
import Select from "react-select";
import Input from "reactstrap/lib/Input";
import Row from "reactstrap/lib/Row";


class VmsOverviewRow extends Component {

    constructor( props ) {
        super( props );
        this.state = {
            addingDisk: false,
            addingForward: false,
            deletingVm: false,
            disks: [],
            new_port: {protocol: "TCP", port: 22}
        };
        this.deleteVm = this.deleteVm.bind( this );
    }


    deleteVm() {
        return this.props.deleteVm( this.props.vm.id ).then( this.props.callback );
    }

    render() {
        const { vm } = this.props;
        return (
            <tr key={ vm.id }>
                <td>{ vm.id }</td>
                <td>{ vm.name }</td>
                <td>{ vm.image && vm.image.name }<br/><br/>
                    {
                        (vm.disks.length < 2) &&
                        <span onClick={ () => {
                            this.props.createImageFromVm( vm.id );
                        } } style={ {
                            cursor: 'pointer',
                            color: "#1b72e2"
                        } }><i>Create new image from this vm</i></span>
                    }
                </td>
                <td>
                    {
                        (vm.state === "up") &&
                        <div>
                            <div className="inline wd-xxs badge badge-success">Up</div>
                            <br/>
                            <span onClick={ () => {
                                this.props.powerOffVm( vm.id ).then( this.props.callback );
                            } } style={ { cursor: 'pointer', color: "#1b72e2" } }><i>Power off</i></span>
                        </div>
                    }
                    {
                        (vm.state === "created") &&
                        <div>
                            <div className="inline wd-xxs badge badge-success">Ready</div>
                            <br/>
                            <span onClick={ () => {
                                this.props.powerOnVm( vm.id ).then( this.props.callback );
                            } } style={ { cursor: 'pointer', color: "#1b72e2" } }><i>Power on</i></span>
                        </div>
                    }
                    {
                        (vm.state === "down") &&
                        <div>
                            <div className="inline wd-xxs badge badge-warning">Down</div>
                            <br/>
                            <span onClick={ () => {
                                this.props.powerOnVm( vm.id ).then( this.props.callback );
                            } } style={ { cursor: 'pointer', color: "#1b72e2" } }><i>Power on</i></span>
                        </div>
                    }
                    {
                        (vm.state === "failed") &&
                        <div>
                            <div className="inline wd-xxs badge badge-danger">Failed</div>
                        </div>
                    }
                    {
                        (vm.state === "creating") &&
                        <div>
                            <div className="inline wd-xxs badge badge-info">Setting up</div>
                        </div>
                    }
                    {
                        (vm.state === "pending") &&
                        <div>
                            <div className="inline wd-xxs badge badge-info">Pending</div>
                        </div>
                    }
                </td>
                <td>
                    { vm.ips && vm.ips.map( ip => {
                        return <span>{ ip.ip }<br/><br/>
                            {
                                ip.forwards && ip.forwards.map( forward => {
                                    return <span>
                                        <i>{ forward.public_ip }:{ forward.public_port } -> { forward.protocol }/{ forward.private_port }</i>
                                        <span style={ { cursor: 'pointer', color:'red', marginLeft: '5px' } } onClick={ () => {
                                            this.props.removeForwardFromIp( vm.id, ip.id, forward.id ).then(this.props.callback);
                                        } }>
                                            <FontAwesomeIcon icon={ faTimes }/>
                                        </span>
                                        <br/>

                                    </span>
                                } )
                            }
                            { (this.state.addingForward !== ip.id && ip.forwards.length < 5) &&
                            <span  style={ { cursor: 'pointer', color: "#1b72e2" } } onClick={ () => {
                                this.setState( { addingForward: ip.id } )
                            } }>Add new port-forward</span>
                            }
                            { (this.state.addingForward === ip.id) &&
                            <Row>

                                <Col lg={ 4} style={{paddingRight: 0}}>
                                    <Select options={[
                                        { value: 'tcp', label: 'TCP' },
                                        { value: 'udp', label: 'UDP' }
                                    ]} onChange={ ( newValue ) => {
                                        let new_portTemp = this.state.new_port;
                                        new_portTemp.protocol = newValue.value;
                                        this.setState( { new_port: new_portTemp } );
                                        return newValue;
                                    } } />
                                </Col>
                                <Col lg={ 4} style={{paddingLeft: 0}}><Input
                                    type="number" className="form-control"
                                    placeholder={ '22' }
                                    value={this.state.new_port.port}
                                    onChange={ e => {
                                        let new_portTemp = this.state.new_port;
                                        new_portTemp.port = e.target.value;
                                        this.setState( { new_port: new_portTemp } );
                                        return e.target.value;
                                    } } />
                                </Col>
                                <Col lg={ 4} >
                                    <StateButton runClick={ () =>{
                                        return this.props.addForwardToIp(vm.id, ip.id, this.state.new_port.protocol.toUpperCase() + "/" + this.state.new_port.port).then(() => {
                                            this.props.callback();
                                            this.setState({addingForward:false})
                                        });
                                    } }
                                                 texts={ [ 'Add', 'Adding', 'Added', 'Failed' ] }
                                                 color="primary"/>
                                </Col>
                            </Row>
                            }
                        </span>;
                    } ) }

                </td>
                <td>{ parseInt( vm.cpus ? vm.cpus : 0 ) }</td>
                <td>{ prettyBytes( parseInt( vm.mem ? vm.mem : 0 ) * 1000 * 1000 * 1000 ) }</td>
                <td>{ vm.disks &&
                vm.disks
                    .sort( ( a, b ) => a.node - b.node )
                    .map( disk => {
                        return <span><b>Node { disk.node }</b> <br/>Size: { prettyBytes( parseInt( disk.size ) * 1000 * 1000 * 1000 ) }<br/>
                            { (disk.node > 0 && (vm.state === "down" || vm.state === "created")) &&
                            <span onClick={ () => {
                                this.props.detachDiskFromVm( vm.id, disk.id ).then( this.props.callback );

                            } } style={ { cursor: 'pointer', color: "#1b72e2" } }>Detach<br/></span>
                            }<br/></span>;
                    } ) }
                    <br/>
                    { !this.state.addingDisk ?

                        <span onClick={ () => {
                            this.setState( { addingDisk: true } )
                        } } style={ { cursor: 'pointer', color: "#1b72e2" } }>Add disk</span>
                        :
                        <AsyncSelect
                            loadOptions={ ( input, update ) => {
                                if ( this.state.disks.length === 0 ) {
                                    this.props.getDisks().then( disks => {
                                        update( disks.filter( disk => {
                                            if ( disk.attached ) {
                                                return false;
                                            }
                                            if ( input === "" ) {
                                                return true;
                                            }
                                            return disk.name.toLowerCase().includes( input.toLowerCase() ) || disk.id.toLowerCase().includes( input.toLowerCase() );
                                        } ).map( disk => {
                                            return {
                                                value: disk.id,
                                                label: disk.id + " | " + disk.name + " (" + prettyBytes( disk.size * 1000 * 1000 * 1000 ) + ")"
                                            }
                                        } ) )
                                    } )
                                } else {
                                    update( this.state.disks.filter( disk => {
                                        if ( input === "" ) {
                                            return true;
                                        }
                                        return disk.name.toLowerCase().includes( input.toLowerCase() );
                                    } ).map( user => {
                                        return {
                                            value: user.id,
                                            label: user.name
                                        }
                                    } ) )
                                }
                            } }
                            defaultOptions
                            onChange={ ( newValue ) => {
                                this.setState( { addingDisk: false } );
                                this.props.attachDiskToVm( vm.id, newValue.value ).then( this.props.callback );
                                return newValue;
                            } }
                        />
                    }
                </td>
                <td>
                    {
                        (vm.state === "down" || vm.state === "failed" || vm.state === "created") &&
                        <div>
                            { this.state.deletingVm ?

                                <div>
                                    <p>Warning: deleting the virtual-machine will also delete all attached disk. This
                                        action
                                        cannot be reversed.<br/>Are you sure?</p>
                                    <StateButton runClick={ this.deleteVm }
                                                 texts={ [ 'Delete', 'Deleting', 'Deleted', 'Failed' ] }
                                                 color="danger"/>
                                </div>
                                :

                                <span onClick={ () => {
                                    this.setState( { deletingVm: true } )
                                } } style={ { cursor: 'pointer', color: "#1b72e2" } }>Delete</span>
                            }
                        </div>
                    }

                </td>
            </tr>
        );
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( {
        powerOnVm,
        powerOffVm,
        detachDiskFromVm,
        getDisks,
        attachDiskToVm,
        deleteVm,
        createImageFromVm,
        removeForwardFromIp,
        addForwardToIp
    }, dispatch )
};

export default connect( null, mapDispatchToProps )( VmsOverviewRow );
