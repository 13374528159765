import React from 'react';
import { withRouter } from 'react-router';
import RocketLaunch from "./RocketLaunch";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";

class NotFound extends React.Component {

    render() {

        return (
            <div style={ { display: 'block', margin: '0', width: '100%', height: '450px' } }>

                <RocketLaunch />
                <Card style={{position:'absolute', top: '20%', right: '15%'}}>
                    <CardBody><h2>Oops, we could not find that.</h2>
                        <p>We are on our way to another galaxy to continue the search. Hang on!</p><br />
                    <p><small>This might actually be going to take a while...</small></p></CardBody>

                </Card>
            </div>);
    }
}

export default withRouter( NotFound );
