import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getStats} from "../../actions/UserInformation";
import {withRouter} from "react-router";
import {Card, CardBody, CardHeader} from "reactstrap";
import {visibleTitle} from "../Common/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons/faGlobe";
import Timestamp from "react-timestamp";
import {faBrain} from "@fortawesome/free-solid-svg-icons/faBrain";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faClock} from "@fortawesome/free-solid-svg-icons/faClock";
import {faDatabase} from "@fortawesome/free-solid-svg-icons/faDatabase";
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";

class ServerStatusPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stats: [],
            refresh: true
        };
        this.statusRef = React.createRef();
        this.tick = this.tick.bind(this);
    }

    getConst(icon) {
        return {
            'faGlobe': faGlobe,
            'faBrain': faBrain,
            'faEnvelope': faEnvelope,
            'faClock': faClock,
            'faDatabase': faDatabase,
            'faHome': faHome
        }[icon]
    }

    componentDidMount() {
        this.tick(0);
    }
    componentWillUnmount() {
        this.setState({ refresh: false });
    }

    tick(count) {
        if (!this.state.refresh) return;
        
        if (count === 0) {
            this.props.getStats().then(stats => {
                this.setState({stats});
            });
        }

        if ( this.statusRef.current !== null) {
            this.statusRef.current.style.width = (count/2) + "%";
        }

        setTimeout(() => {
            if (count + 1 === 200) {
                this.tick(0)
            } else {
                this.tick(count + 1)
            }
        }, 50);
    }

    render() {
        const {stats} = this.state;
        if (stats == null) return null;
        return (
            <Card className="card-default">
                <CardHeader>
                    <h3>{visibleTitle(stats, ["problems", "problem"])}</h3>
                </CardHeader>
                <CardBody style={{ marginTop: '-20px'}}>

                    {(stats != null && stats.length > 0) ?
                        stats.map((stat, index) => {
                                return ( <div style={{ marginTop: '20px'}} key={index}>
                                    <FontAwesomeIcon style={ { marginRight: '30px', fontSize: '40px', float:'left', display: 'inline-block' } } icon={ this.getConst(stat.icon) }/>
                                    <h4 style={{marginBottom:0}}>{stat.name}</h4>
                                    <p>Since <Timestamp time={stat.status.since} format='relative'/></p>
                                </div>);
                            }
                        )
                        :
                        <p className="text-center">No problems!</p>
                    }

                </CardBody>
                <div ref={this.statusRef} style={{borderRadius:'1px', width: '0%',background: '#4e4e4e', height: '2px', display: 'block'}} />
            </Card>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({getStats}, dispatch)
};

export default connect(null, mapDispatchToProps)(withRouter(ServerStatusPanel));
