import { LOGOUT } from '../actions/Auth';
import { GET_DATABASES } from "../actions/Databases";

export default function Databases( state = {}, action ) {
    switch ( action.type ) {
        case GET_DATABASES.SUCCESS:
            let store1 = {};
            action.payload.map( database => {
                store1[ database.id ] = database;
                return true;
            } );
            return store1;

        case LOGOUT.SUCCESS:
            return {};

        default:
            return state;
    }
}
