import React from 'react';
import Loader from "../Common/Loader.jsx";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { Button } from 'reactstrap';
import { getDmarcReports, enableDmarc, disableDmarc } from "../../actions/Domains";
import { bindActionCreators } from "redux";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";


class MailDomainDmarcPanel extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            reports: undefined
        };
        this.fetchDmarcReports = this.fetchDmarcReports.bind( this );
    }

    fetchDmarcReports( props ) {
        if ( props.domain === undefined || props.domain.dmarc === null || this.state.reports !== undefined ) return false;

        this.props.getDmarcReports( props.domain.id ).then( data => {
            this.setState( { reports: data.reports } )
        } );

    }

    componentDidMount() {
        this.fetchDmarcReports( this.props );
    }

    componentWillReceiveProps( nextProps ) {
        this.fetchDmarcReports( nextProps );
    }

    render() {
        const { domain } = this.props;
        const { reports } = this.state;
        if ( domain === undefined ) return ("");

        return (
            <Card className="card-default">
                <CardHeader>
                    <span className="pull-right">
                    { domain.dmarc ?
                        <span onClick={ () => {
                            this.props.disableDmarc( domain.id ).then( () => {
                                this.setState( { reports: undefined } );
                            } )
                        } } className="pull-right" style={ { color: 'red', cursor: 'pointer' } }>
                            <FontAwesomeIcon icon={ faTimes } style={ { marginRight: '5px' } }/>
                            Disable
                        </span>
                        :
                        <Button onClick={ () => {
                            this.props.enableDmarc( domain.id )
                        } } color="success">
                            <FontAwesomeIcon icon={ faPlusCircle } style={ { marginRight: '5px' } }/>
                            Enable
                        </Button>
                    }
                    </span>
                    <h3>Dmarc { domain.dmarc ?
                        <FontAwesomeIcon icon={ faCheckCircle } style={ { marginLeft: '10px', color: 'green' } }/>
                        :
                        <FontAwesomeIcon icon={ faExclamationCircle } style={ { marginLeft: '10px', color: 'orange' } }/>
                    }</h3>


                </CardHeader>
                <CardBody>
                    { !domain.dmarc &&
                    <Row>
                        <Col sm={ 12 } className="text-center">
                            <h3>Dmarc is disabled, please enable it first</h3>
                        </Col>
                    </Row>
                    }
                    { domain.dmarc && reports !== undefined && reports.length === 0 &&
                    <Row>
                        <Col sm={ 12 } className="text-center">
                            <h3>No reports yet</h3>
                            <small>Reports are updated daily, hang on.</small>
                        </Col>
                    </Row>
                    }
                    { domain.dmarc && reports !== undefined && reports.length > 0 &&
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <td>
                                <strong>Source</strong>
                            </td>
                            <td>
                                <strong>Count</strong>
                            </td>
                            <td>
                                <strong>Spf</strong>
                            </td>
                            <td>
                                <strong>Dkim</strong>
                            </td>
                        </tr>

                        </thead>

                        <tbody>
                        {
                            reports.map( report => {
                                return (<tr>
                                    <td>
                                        { report.sent_by.host } <br/><small>{ report.sent_by.ip }</small>
                                    </td>
                                    <td>
                                        { report.count }
                                    </td>
                                    <td>
                                        { report.evaluations.spf === "pass" ?
                                            <div
                                                className="inline wd-xxs badge badge-success">
                                                Pass</div>
                                            :
                                            <div
                                                className="inline wd-xxs badge badge-danger">
                                                Fail</div>

                                        }
                                    </td>
                                    <td>
                                        { report.evaluations.dkim === "pass" ?
                                            <div
                                                className="inline wd-xxs badge badge-success">
                                                Pass</div>
                                            :
                                            <div
                                                className="inline wd-xxs badge badge-danger">
                                                Fail</div>

                                        }
                                    </td>
                                </tr>);
                            } )
                        }

                        </tbody>

                    </Table>
                    }
                    { domain.dmarc && reports === undefined &&
                    <Loader/>
                    }
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        domains: state.Domains
    }
};
const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { getDmarcReports, enableDmarc, disableDmarc }, dispatch )
};


export default connect( mapStateToProps, mapDispatchToProps )( MailDomainDmarcPanel );
