import React from 'react';
import { Col } from 'reactstrap';
import Loader from "../Common/Loader.jsx";
import { updateMailBoxPassword } from "../../actions/Domains";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import Input from "reactstrap/lib/Input";
import StateButton from "../Common/StateButton";
import Row from "reactstrap/lib/Row";


class MailBoxPasswordPanel extends React.Component {

    constructor( props ) {
        super( props );
        this.generatePassword = this.generatePassword.bind( this );
        this.changePassword = this.changePassword.bind( this );
    }

    generatePassword() {
        return new Promise( resolve => {
            let text = "";
            let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for ( let i = 0; i < 12; i++ )
                text += possible.charAt( Math.floor( Math.random() * possible.length ) );

            resolve( text );
        } ).then( result => {
            this.passwordInput.type = "text";
            this.passwordInput.value = result;
        } );
    }

    changePassword() {
        return this.props.updateMailBoxPassword( this.props.mailbox.domainId, this.props.mailbox.id, this.passwordInput.value ).then( () => {
            let mailbox = this.props.mailbox;
            mailbox.password = "SET";
            this.passwordInput.type = "password";
            this.passwordInput.value = "";
            this.props.onChange( mailbox );
        } );
    }

    render() {
        const { mailbox } = this.props;

        return (
            <Card className="card-default">
                <CardHeader>
                    Update password
                </CardHeader>
                <CardBody>
                    { (mailbox !== undefined && mailbox.address !== undefined) ?
                        <div>
                            <Row>
                                <Col lg={ 12 }>
                                    <Input
                                        innerRef={ input => this.passwordInput = input }
                                        type="password" placeholder="Password"/>
                                </Col>
                            </Row>
                            <Row style={ { marginTop: '5px' } }>
                                <Col lg={ 12 }>
                                    <StateButton runClick={ this.generatePassword }
                                                 texts={ [ 'Generate', 'Generating', 'Generated', 'Error' ] }
                                                 initialColor={ 'warning' }
                                                 className={ 'pull-left' }/>
                                    <StateButton runClick={ this.changePassword }
                                                 texts={ [ 'Update', 'Updating', 'Done', 'Error' ] }
                                                 className={ 'pull-right' }/>
                                </Col>
                            </Row>
                        </div>

                        :
                        <Loader/>
                    }
                </CardBody>
            </Card>
        );
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { updateMailBoxPassword }, dispatch )
};

export default connect( null, mapDispatchToProps )( MailBoxPasswordPanel );
