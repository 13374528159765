import { GET_USERS, CREATE_NEW_USER } from '../actions/Users';
import { LOGOUT } from '../actions/Auth';

export default function Users(state = {}, action) {
    switch (action.type) {
        case GET_USERS.SUCCESS:
            return action.payload;
        case CREATE_NEW_USER.SUCCESS:
            state.push(action.payload);
            return state;
        case LOGOUT.SUCCESS:
            return {};
        default:
            return state;
    }
}