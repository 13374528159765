import { LOGOUT } from '../actions/Auth';
import { GET_CRONS } from "../actions/Crons";

export default function Crons( state = {}, action ) {
    switch ( action.type ) {
        case GET_CRONS.SUCCESS:
            let store1 = {};
            action.payload.map( cron => {
                store1[ cron.id ] = cron;
                return true;
            } );
            return store1;

        case LOGOUT.SUCCESS:
            return {};

        default:
            return state;
    }
}
