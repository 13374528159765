import { GET_TAX_EXPENSES, CREATE_TAX_EXPENSE } from '../actions/Taxes';
import { LOGOUT } from '../actions/Auth';

export default function Taxes( state = {}, action ) {
  switch ( action.type ) {
    case GET_TAX_EXPENSES.SUCCESS:
      state.expenses = action.payload;
      state.targets = {};
      action.payload.map(expense => {
        state.targets[expense.target.id] = expense.target;
        return true;
      });
      return state;

    case CREATE_TAX_EXPENSE.SUCCESS:
      state.expenses.push(action.payload);
      state.targets[action.payload.target.id] = action.payload.target;
      return state;

    case LOGOUT.SUCCESS:
      return {};

    default:
      return state;
  }
}
