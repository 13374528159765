import { combineReducers } from 'redux';

import UserInfo from './UserInfo';
import Authentication from './Authentication';
import Invoices from './Invoices';
import Users from './Users';
import Domains from './Domains';
import Mandates from "./Mandates";
import Databases from "./Databases";
import Wallet from "./Wallet";
import Volumes from "./Volumes";
import Tickets from "./Tickets";
import Taxes from "./Taxes";
import Layout from "./Layout";
import Crons from "./Crons";
import Vms from "./Vms";

export default combineReducers( {
  Authentication,
  UserInfo,
  Crons,
  Databases,
  Invoices,
  Users,
  Vms,
  Mandates,
  Domains,
  Wallet,
  Volumes,
  Tickets,
  Taxes,
  Layout
} );
