import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createNewMailboxAlias, deleteMailboxAlias, getMailBox } from "../../actions/Domains";
import MailBoxDetailsView from "./MailBoxDetailsPanel";
import MailBoxPasswordPanel from "./MailBoxPasswordPanel";
import MailBoxAliasesPanel from "./MailBoxAliasesPanel";
import MailBoxAutoReplyPanel from "./MailBoxAutoReplyPanel";


class MailBoxView extends React.Component {

  constructor() {
    super();
    this.state = {
      newAliasButton: { text: "New Alias", disabled: false, style: "primary" },
      changeMailboxPasswordButton: { text: "Change", disabled: false, style: "primary" },
      generateMailboxPasswordButton: { text: "Generate", disabled: false, style: "warning" },
      addingAlias: false,
      mailbox: { aliases: [] }
    };
  }

  componentDidMount() {
    this.props.getMailBox( this.props.match.params.dId, this.props.match.params.bId ).then( mailbox => {
      mailbox.domainId = this.props.match.params.dId;
      this.setState( { mailbox } );
      if ( mailbox.aliases.length === 0 ) {
        this.setState( {
          addingAlias: true,
          newAliasButton: { text: "Cancel", disabled: false, style: "danger" }
        } );
      }
    } );
  }

  handleChange( mailbox ) {
    this.setState( { mailbox } );
  }

  handleOooChange( ooo ) {
    let mailbox = this.state.mailbox;
    mailbox.ooo = ooo;
    this.setState( { mailbox } );
  }


  render() {
    const { mailbox } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          Mailbox #{ this.props.match.params.bId }
        </div>
        <Row>
          <Col md={ 6 }>

            <MailBoxDetailsView mailbox={ mailbox }/>

            <MailBoxPasswordPanel mailbox={ mailbox } onChange={ b => this.handleChange( b ) }/>

          </Col>
          <Col md={ 6 }>

            <MailBoxAliasesPanel mailbox={ mailbox }/>
            <MailBoxAutoReplyPanel mailbox={ mailbox } onOooChange={ b => this.handleOooChange( b ) }/>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = ( state ) => {
  return {
    user: state.UserInfo
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { getMailBox, createNewMailboxAlias, deleteMailboxAlias }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( MailBoxView );
