import React from 'react';
import { Table } from 'reactstrap';
import Timestamp from "react-timestamp";
import Loader from "../Common/Loader.jsx";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";


class TicketDetailsPanel extends React.Component {

  constructor() {
    super();
    this.state = {
      closeTicketButton: { text: "Close", style: "btn-primary" },
      ticket: undefined
    };
    this.handleTicketClosed = this.handleTicketClosed.bind( this );
  }

  componentDidMount() {
    this.setState( { ticket: this.props.ticket } );
  }

  componentWillReceiveProps( props ) {
    this.setState( { ticket: props.ticket } );
  }

  handleTicketClosed() {
    this.setState( { closeTicketButton: { text: "Closing...", style: "btn-warning" } } );
    this.props.updateTicketStatus( this.state.ticket.id, 1 ).then( (data) => {
      this.props.callback( 1, data.message );
      this.setState( { message: "", closeTicketButton: { text: "Closed", style: "btn-success" } } );
    } );
  }

  render() {
    const { ticket } = this.state;
    return (

      <Card className="card-default">
        <CardHeader>
          <h3>Details</h3>
        </CardHeader>
        <CardBody>

        { ( ticket !== undefined && ticket.name !== undefined ) ?
          <Table responsive hover>
            <tbody>
            <tr>
              <td>
                <strong>Created date</strong>
              </td>
              <td><Timestamp time={ ticket.date } format='date'/></td>
            </tr>
            <tr>
              <td>
                <strong>User</strong>
              </td>
              <td><Link to={ `/console/user/${ticket.user.id}` }>{ ticket.user.name }</Link></td>
            </tr>
            <tr>
              <td>
                <strong>Status</strong>
              </td>
              <td>{
                ( ticket.status === 1 ) ?
                  <div
                    className="inline wd-xxs label label-danger">
                    Closed</div>
                  :
                  <div
                    className="inline wd-xxs label label-success">
                    Open
                  </div>
              }</td>
            </tr>
            </tbody>

          </Table>
          :
          <Loader/>
        }
        {
          ticket !== undefined && ticket.name !== undefined && ticket.status !== 1 &&
            <button onClick={ () => {
              this.handleTicketClosed();
            } } type="button"
                    className={ "btn btn-sm " + this.state.closeTicketButton.style }>{ this.state.closeTicketButton.text }</button>
        }
        </CardBody>
      </Card>
    );
  }
}

export default TicketDetailsPanel;

