import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Card, CardBody, CardHeader } from "reactstrap";
import { bindActionCreators } from "redux";
import { checkAvailabilty } from "../../actions/Domains";
import 'loaders.css/loaders.css';
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";

class DomainCheckPanel extends React.Component {

  constructor() {
    super();
    this.state = { pricing: {}, available: undefined };

    this.handleChange = this.handleChange.bind( this );
    this.triggerChange = this.triggerChange.bind( this );
    this.handleKeyDown = this.handleKeyDown.bind( this );
    this.checkDomain = this.checkDomain.bind( this );
  }

  componentWillMount() {
    this.timer = null;
  }

  handleChange( event ) {
    clearTimeout( this.timer );

    this.setState( { domain: event.target.value } );

    this.timer = setTimeout( this.triggerChange, 1000 );
  }

  handleKeyDown( e ) {
    if ( e.keyCode === 13 ) {
      this.triggerChange();
    }
  }

  triggerChange() {
    this.checkDomain();
  }

  checkDomain() {
    if ( this.state.domain === "" ) {
      this.setState( { available: undefined } );
    } else {
      this.setState( { available: null } );
      let re = new RegExp( /^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/ );
      if ( this.state.domain.match( re ) ) {
        this.props.checkAvailabilty( this.state.domain ).then( response => {
          this.setState( { available: response.available, pricing: response.pricing } );
        } ).catch( err => {
          this.setState( { available: "malformed" } );
        } );
      } else {
        this.setState( { available: "malformed" } );
      }
    }
  }

  render() {
    return (
      <Card className="card-default">
        <CardHeader>
          <h3 style={ { display: 'inline' } }>Register domain</h3>
          <input placeholder="mydomian.com" type="text" autoComplete="off"
                 className="form-control" style={ { width: '100%', marginTop: '20px' } }
                 onChange={ this.handleChange }
                 onKeyDown={ this.handleKeyDown }/>
        </CardHeader>
        <CardBody style={{textAlign:'center'}}>
          { this.state.available === null &&
          <div className="ball-clip-rotate-multiple" style={{marginLeft:'48%', marginTop:'20px', marginBottom:'40px'}}>
            <div style={{borderTopColor:"#23b7e5"}}/>
            <div style={{borderTopColor:"#23b7e5"}}/>
          </div>
          }
          { this.state.available === "malformed" &&
          <div>
            <h3><FontAwesomeIcon icon={ faExclamationCircle } style={ { marginRight: '10px', color: 'orange' } }/> Oops</h3><p>That doesn't seem to be a valid domain name</p>
          </div>
          }
          { this.state.available === false &&
          <div>
            <h3><FontAwesomeIcon icon={ faExclamationCircle } style={ { marginRight: '10px', color: 'orange' } }/> Too bad</h3><p>That domain is already taken by somebody else</p>
          </div>
          }
          { this.state.available === true &&
          <div>
            <h3><FontAwesomeIcon icon={ faCheckCircle } style={ { marginRight: '10px', color: 'green' } }/> Yeah!</h3><p>That domain is available! Make it yours for &euro;{this.state.pricing.register}/year</p>
          </div>
          }
        </CardBody>
      </Card>
    );
  }
}

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { checkAvailabilty }, dispatch )
};

const mapStateToProps = ( state ) => {
  return {
    user: state.UserInfo,
    domains: state.Domains
  }
};


export default connect( mapStateToProps, mapDispatchToProps )( withRouter( DomainCheckPanel ) );
