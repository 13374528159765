import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import Input from "reactstrap/es/Input";
import { signupBeta } from '../../actions/Users'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import StateButton from "../Common/StateButton";

class BetaSignupPanel extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            email: ""
        };
        this.signup = this.signup.bind( this );
    }

    signup() {
        return this.props.signupBeta( this.state.email ).then( () => {
            this.setState( {
                name: "",
                email: ""
            } )
        } )
    }

    render() {
        return (
                <div style={{marginBottom: '-10px'}}>
                    <InputGroup className="input-group-alternative" style={{marginBottom: '5px'}}>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <FontAwesomeIcon style={ { width: '20px' } }
                                                 icon={ faEnvelope }/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            value={ this.state.email }
                            onChange={ e => this.setState( { email: e.target.value } ) }

                            placeholder="Email address"
                            type="email"
                            onFocus={ e => this.setState( { emailFocused: true } ) }
                            onBlur={ e => this.setState( { emailFocused: false } ) }
                        />
                    </InputGroup>
                    <StateButton block runClick={ this.signup }
                                 texts={ [ 'Sign up', 'Signing up', 'Done, check your inbox', 'Invalid email' ] }
                                 className="btn-round btn-block"
                                 color="primary"/>
                </div>
        );
    }

}


const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { signupBeta }, dispatch )
};

export default connect( null, mapDispatchToProps )( BetaSignupPanel );
