import React from 'react';
import Loader from "../Common/Loader.jsx";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Timestamp from "react-timestamp";
import {Card, CardBody, CardHeader, Table} from "reactstrap";
import { containsRule, isInitialized, NewSearch } from "../Common/Utils";
import { Link } from "react-router-dom";
import SearchBar from "../Common/SearchBar";

class DomainsPanel extends React.Component {

    constructor(props) {
        super(props);

        this.search = NewSearch( [
            ( n, c ) => {
                return containsRule( c.id.toLowerCase(), n )
            },
            (n, c) => {
                return containsRule((c.name + "." + c.tld.name).toLowerCase(), n)
            },
            (n, c) => {
                return containsRule(c.user.name.toLowerCase(), n)
            }
        ], () => Object.values( this.props.domains ));
        this.search.onUpdate( () => this.forceUpdate() )
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.search.refresh();
    }
    componentDidMount() {
        this.search.refresh();
    }

    render() {
        const {user} = this.props;
        return (
            <Card className="card-default">
                <CardHeader>
                    <h3 style={{display: 'inline'}}>{this.search.printVisibles(["domains", "domain"])}</h3>

                    <SearchBar search={this.search} />
                </CardHeader>
                <CardBody>
                    { isInitialized(this.search.getVisibles()) ?
                        <Table responsive hover>
                            <thead>
                            <tr>
                                <th>ID</th>
                                {user.admin &&
                                <th>User</th>
                                }
                                <th>Domain</th>
                                <th>Expires</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.search.getVisibles()
                                    .sort((a, b) => a.expires - b.expires)
                                    .map(domain => {
                                            return <tr key={domain.id}>
                                                <td><Link to={ `/console/domain/${ domain.id }` }>{ domain.id  }</Link></td>
                                                {(user.admin) &&
                                                <td><Link to={ `/console/user/${ domain.user.id }` }>{ domain.user.name  }</Link></td>
                                                }
                                                <td>{domain.name}.{domain.tld.name}</td>
                                                <td>
                                                    {
                                                        (domain.autorenew) ?
                                                            <div
                                                                className="inline wd-xxs badge badge-success">
                                                                Auto</div>
                                                            :
                                                            (domain.expires) ?
                                                                <Timestamp time={domain.expires} format='date'/>
                                                                :
                                                                <div
                                                                    className="inline wd-xxs badge badge-warning">
                                                                    Inactive</div>
                                                    }</td>

                                            </tr>
                                        }
                                    )
                            }
                            </tbody>
                        </Table>
                        :
                        <Loader/>
                    }
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserInfo,
        domains: state.Domains
    }
};


export default connect(mapStateToProps)(withRouter(DomainsPanel));
