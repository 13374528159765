import React from 'react';
import { connect } from "react-redux";

class UserGreetingOrLogin extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {name:""};
    }

    componentDidMount() {
        if ( this.props.user.name != null ) {
            this.setState( { name: this.props.user.name.split( " " )[ 0 ] } );
        }
    }

    componentWillReceiveProps( props ) {
        if ( this.props.user.name != null ) {
            this.setState( { name: this.props.user.name.split( " " )[ 0 ] } );
        }
    }

    render() {
        if (this.state.name === undefined || this.state.name === "") {
            return `Login`;
        }
        return `Hi, ${ this.state.name }`;
    }

}

const mapStateToProps = ( state ) => {
    return {
        user: state.UserInfo
    }
};

export default connect( mapStateToProps )( UserGreetingOrLogin );
