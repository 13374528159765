import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class KeyFeatureBlock extends React.Component {

    render() {
        return (
            <div>
                <div
                    className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <FontAwesomeIcon className={`color-${this.props.color}`} icon={ this.props.icon }/>
                </div>
                <h4 className="mt-3">{ this.props.title }</h4>
                <p className="mt-3">{ this.props.content }</p>
            </div>
        );
    }

}

export default KeyFeatureBlock;
