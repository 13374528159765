import React from 'react';
import { Table } from 'reactstrap';
import Loader from "../Common/Loader.jsx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import prettyBytes from "../Common/PrettyBytes";
import Timestamp from "react-timestamp";


class VolumeDetailsPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            volume: undefined
        };
    }

    componentDidMount() {
        this.setState( { volume: this.props.volume } );
    }

    componentWillReceiveProps( props ) {
        this.setState( { volume: props.volume } );
    }

    render() {
        const { volume } = this.state;
        return (
            <Card className="card-default">
                <CardHeader>
                    Details
                </CardHeader>
                <CardBody>
                    { (volume !== undefined && volume.name !== undefined) ?
                        <Table responsive hover>
                            <tbody>
                            <tr>
                                <td>
                                    <strong>Name</strong>
                                </td>
                                <td>{ volume.name }</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Hash</strong>
                                </td>
                                <td>{ volume.hash }</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Auto snapshot</strong>
                                </td>
                                <td>{ {
                                    "1": (
                                        "Once a month"
                                    ),
                                    "5": (
                                        "Once a week"
                                    ),
                                    "10": (
                                        "Twice a week"
                                    ),
                                    "20": (
                                        "Daily"
                                    ),
                                    "0": (
                                        "Disabled"
                                    )
                                }[ volume.backup.policy ] }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Last snapshot</strong>
                                </td>
                                { volume.backup.last ?
                                    <td><Timestamp date={ volume.backup.last.time } format='date'/><br/>
                                        { prettyBytes( volume.backup.last.size ? volume.backup.last.size : 0 ) } ({ prettyBytes( volume.backup.last.referenced ? volume.backup.last.referenced : 0 ) } referenced)
                                    </td>
                                    :
                                    <td>None</td>
                                }
                            </tr>
                            </tbody>

                        </Table>
                        :
                        <Loader/>
                    }
                </CardBody>
            </Card>
        );
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( {}, dispatch )
};

export default connect( null, mapDispatchToProps )( VolumeDetailsPanel );

