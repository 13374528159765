import React from 'react';
import { withRouter } from 'react-router';
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

class Ftp extends React.Component {

  render() {

    return (
      <div
        style={ { display: 'block', margin: '0', marginTop: '40px', width: '100%', height: '450px' } }>
        <Row>
          <Col sm={ 1 }/>
          <Col sm={ 5 }>

            <h1 className={ 'triangle-title' }>Ftp configuration</h1>
            <p style={ { color: 'white', marginTop: '20px', width: '60%', marginLeft: '5%', fontSize: '20px' } }><b>Please note</b> that you need to create a FTP-account first. You can do this in <Link
              style={ { cursor: "pointer" } } to={`/console/volumes`}>the console</Link> by going into the preferred volume.</p>
          </Col>
          <Col sm={ 1 }/>
        </Row>
        <div className="container container-lg" style={ { marginTop: '50px' } }>
          <Row>
            <Col md={ 4 }/>
            <Col md={ 4 }>
              <div className="plan">
                <h3 className="plan-header">FTP Settings</h3>
                <ul className="plan-features" style={ { textAlign: 'center' } }>
                  <li><em className="fa fa-check"/>Services running</li>
                  <hr/>
                  <li>
                    <small>Server:</small>
                    <br/>Ftp.pushto.space
                  </li>
                  <hr/>
                  <li>
                    <small>Protocol:</small>
                    <br/>FTP + TLS
                  </li>
                  <hr/>
                  <li>
                    <small>Port:</small>
                    <br/>21 (Default)
                  </li>
                  <hr/>
                  <li>
                    <small>Mode:</small>
                    <br/>Passive mode preferred
                  </li>
                  <hr/>
                  <li>
                    <small>Authentication:</small>
                    <br/>Username & password
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={ 4 }/>
          </Row>
        </div>
      </div>

    );
  }

}

export default withRouter( Ftp );
