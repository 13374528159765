import React from 'react';
import { withRouter } from 'react-router';
import { Col, Row } from "reactstrap";
import CardBody from "reactstrap/es/CardBody";
import Card from "reactstrap/es/Card";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faBrain } from "@fortawesome/free-solid-svg-icons/faBrain";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faDatabase } from "@fortawesome/free-solid-svg-icons/faDatabase";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import Container from "reactstrap/es/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators } from "redux";
import { getStats } from "../../actions/UserInformation";
import { connect } from "react-redux";
import Timestamp from "react-timestamp";

class StatusPanel extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            stats: [],
            refresh: true,
            lastChecked: "never"
        };
        this.tick = this.tick.bind( this );
    }

    getConst( icon ) {
        return {
            'faGlobe': faGlobe,
            'faBrain': faBrain,
            'faEnvelope': faEnvelope,
            'faClock': faClock,
            'faDatabase': faDatabase,
            'faHome': faHome
        }[ icon ]
    }

    componentDidMount() {
        this.setState( { refresh: true } );
        this.tick();
    }

    componentWillUnmount() {
        this.setState( { refresh: false } );
    }

    tick() {
        if ( !this.state.refresh ) return;

        this.props.getStats().then( stats => {
            let lastChecked = "";
            if ( (new Date()).getHours() < 10 ) {
                lastChecked = lastChecked + "0" + (new Date()).getHours() + ":";
            } else {
                lastChecked = lastChecked + (new Date()).getHours() + ":";
            }
            if ( (new Date()).getMinutes() < 10 ) {
                lastChecked = lastChecked + "0" + (new Date()).getMinutes() + ":";
            } else {
                lastChecked = lastChecked + (new Date()).getMinutes() + ":";
            }
            if ( (new Date()).getSeconds() < 10 ) {
                lastChecked = lastChecked + "0" + (new Date()).getSeconds();
            } else {
                lastChecked = lastChecked + (new Date()).getSeconds();
            }
            this.setState( { stats, lastChecked } );
        } );

        setTimeout( () => {
            this.tick()
        }, 10000 );
    }

    render() {
        const { stats } = this.state;
        const { small } = this.props;
        if ( stats == null ) return null;

        if ( stats.length > 0 ) {

            return (
                <section className="section pb-0 bg-gradient-warning"
                         style={ small ? {} : { marginTop: '100px', marginBottom: '100px', paddingTop: '30px' } }>
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-lg-1" lg={ small ? "12" : "6" }>
                                <div className="d-flex px-3">
                                    <div style={small && {marginTop: '40px'}}>
                                        <div
                                            className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-white">
                                            <FontAwesomeIcon icon={ faExclamation } style={ { color: 'white' } }/>
                                        </div>
                                    </div>
                                    <div className="pl-4">
                                        <h4 className="display-3 text-white">Oops</h4>
                                        <p className="text-white">
                                            Currently we seem to have some issues<br/>
                                            <i>Last checked: { this.state.lastChecked }</i>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            { !small &&
                            <Col className="order-lg-1" lg="6">
                                { (stats != null && stats.length > 0) &&
                                stats.map( ( stat, index ) => {
                                        return (<Card className="shadow shadow-lg--hover mt-5">
                                            <CardBody>
                                                <div className="d-flex px-3">
                                                    <div>
                                                        <div
                                                            className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                            <FontAwesomeIcon style={ {
                                                                marginRight: '30px',
                                                                fontSize: '40px',
                                                                float: 'left',
                                                                display: 'inline-block'
                                                            } } icon={ this.getConst( stat.icon ) }/>
                                                        </div>
                                                    </div>
                                                    <div className="pl-4">
                                                        <h5 className="title text-success">
                                                            { stat.name }
                                                        </h5>
                                                        <p>
                                                            Since <Timestamp time={ stat.status.since } format='relative'/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>);
                                    }
                                )
                                }

                            </Col>
                            }
                        </Row>
                    </Container>
                    { !small &&
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                            style={ { marginBottom: '-6px' } }
                        >
                            <polygon
                                style={ { fill: '#f5f7fa' } }
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                    }
                </section>
            );
        }
        return (
            <section className="section pb-0 bg-gradient-success"
                     style={ small ? {} : { marginTop: '100px', marginBottom: '100px', paddingTop: '30px' } }>
                <Container>
                    <Row className="row-grid align-items-center">
                        <Col className="order-lg-1" lg={ small ? "12" : "6" }>
                            <div className="d-flex px-3">
                                <div style={small && {marginTop: '40px'}}>
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-white">
                                        <FontAwesomeIcon icon={ faCheck } style={ { color: 'white' } }/>
                                    </div>
                                </div>
                                <div className="pl-4">
                                    <h4 className="display-3 text-white">All ok</h4>
                                    <p className="text-white">
                                        Currently we have no service interruptions<br/>
                                        <i>Last checked: { this.state.lastChecked }</i>
                                    </p>
                                </div>
                            </div>
                        </Col>
                        { !small &&
                        <Col className="order-lg-1" lg="6">
                            <Card className="shadow shadow-lg--hover mt-5">
                                <CardBody>
                                    <div className="d-flex px-3">
                                        <div>
                                            <div
                                                className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                                <img src="/img/logo-single.png"
                                                     style={ {
                                                         height: '40px',
                                                         display: 'inline',
                                                         marginRight: '10px',
                                                         marginTop: '0px',
                                                         marginLeft: '0',
                                                         position: 'absolute'
                                                     } } alt="Logo"/>
                                            </div>
                                        </div>
                                        <div className="pl-4">
                                            <h5 className="title text-success">
                                                Status page
                                            </h5>
                                            <p>
                                                Our service status can always be checked at our status-page.
                                            </p>
                                            <a
                                                className="text-success"
                                                href="https://status.pushto.space">
                                                > Status.PushTo.Space
                                            </a>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        }
                    </Row>
                </Container>
                {/* SVG separator */ }
                { !small &&
                <div className="separator separator-bottom separator-skew zindex-100">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                        style={ { marginBottom: '-6px' } }
                    >
                        <polygon
                            style={ { fill: '#f5f7fa' } }
                            points="2560 0 2560 100 0 100"
                        />
                    </svg>
                </div>
                }
            </section>
        );
    }

}

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { getStats }, dispatch )
};

export default connect( null, mapDispatchToProps )( withRouter( StatusPanel ) );

