import React from 'react';

class ConsoleRedirect extends React.Component {

  componentDidMount() {
    this.props.history.push("/console/dashboard");
  }

  render() {
    return (
      <div />
    );
  }
}


export default ConsoleRedirect;

