import { COINHIVE_RUN, COINHIVE_STATS } from '../actions/Wallet';
import { LOGOUT } from '../actions/Auth';

export default function Wallet(state = {}, action) {
    switch (action.type) {
        case COINHIVE_RUN:
            return {
                ...state,
                running: action.payload
            };
        case COINHIVE_STATS:
            return {
                ...state,
                stats: action.payload
            };
        case LOGOUT.SUCCESS:
            return {};
        default:
            return state;
    }
}