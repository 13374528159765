import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { checkMx, fixMx } from "../../actions/Domains";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";


class MailDomainDetailsPanelMxRow extends React.Component {

    constructor() {
        super();
        this.state = {
            valid: undefined
        };
    }

    componentDidMount() {
        if ( this.props.domainId !== undefined ) {
            this.props.checkMx( this.props.domainId ).then( result => {
                this.setState( { valid: result.valid } );
            } );
        }
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        if ( this.props.domainId !== undefined ) {
            this.props.checkMx( this.props.domainId ).then( result => {
                this.setState( { valid: result.valid } );
            } );
        }
    }

    render() {
        if ( this.state.valid === undefined ) {
            return (
                <td>Fetching...</td>
            );
        }

        if ( this.state.valid === false ) {
            return (
                <td><FontAwesomeIcon icon={ faExclamationCircle } style={ { marginRight: '10px', color: 'orange' } }/>
                    <b>Invalid</b>
                    <span onClick={ () => {
                        this.props.fixMx( this.props.domainId ).then( () => {
                            this.setState( { valid: true } );
                        } )
                    } } className="pull-right" style={ { color: 'green', cursor: 'pointer' } }>
                        <FontAwesomeIcon icon={ faPlusCircle } style={ { marginRight: '5px' } }/>
                        Fix
                    </span>
                </td>
            );
        }
        if ( this.state.valid === true ) {
            return (
                <td><FontAwesomeIcon icon={ faCheckCircle } style={ { marginRight: '10px', color: 'green' } }/>
                    <i>Valid</i>
                </td>
            );
        }
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { checkMx, fixMx }, dispatch )
};


export default connect( null, mapDispatchToProps )( withRouter( MailDomainDetailsPanelMxRow ) );
