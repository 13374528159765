import { LOGOUT } from '../actions/Auth';
import {CREATE_VOLUME, GET_VOLUMES} from "../actions/Volumes";

export default function Volumes( state = {}, action ) {
  switch ( action.type ) {
    case GET_VOLUMES.SUCCESS:
      let store = {};
      action.payload.map( volume => {
        store[ volume.id ] = volume;
        return true;
      } );
      return store;


    case CREATE_VOLUME.SUCCESS:
      state[ action.payload.id ] = action.payload;
      return state;


    case LOGOUT.SUCCESS:
      return {};

    default:
      return state;
  }
}
