import {createRequestTypes, action} from './Util';
import * as api from '../services/Api';

export const CREATE_TICKET = createRequestTypes('CREATE_TICKET');
export const CREATE_TICKET_MESSAGE = createRequestTypes('CREATE_TICKET_MESSAGE');
export const GET_TICKETS = createRequestTypes('GET_TICKETS');
export const GET_TICKET = createRequestTypes('GET_TICKET');
export const UPDATE_TICKET_STATUS = createRequestTypes('UPDATE_TICKET_STATUS');

export const createTicketDispatchers = {
    request: () => action(CREATE_TICKET.REQUEST),
    success: (ticket) => action(CREATE_TICKET.SUCCESS, ticket),
    failure: (error) => action(CREATE_TICKET.FAILURE, error),
};
export const createTicketMessageDispatchers = {
    request: () => action(CREATE_TICKET_MESSAGE.REQUEST),
    success: (message) => action(CREATE_TICKET_MESSAGE.SUCCESS, message),
    failure: (error) => action(CREATE_TICKET_MESSAGE.FAILURE, error),
};
export const getTicketsDispatchers = {
    request: () => action(GET_TICKETS.REQUEST),
    success: (tickets) => action(GET_TICKETS.SUCCESS, tickets),
    failure: (error) => action(GET_TICKETS.FAILURE, error),
};
export const getTicketDispatchers = {
    request: () => action(GET_TICKET.REQUEST),
    success: (ticket) => action(GET_TICKET.SUCCESS, ticket),
    failure: (error) => action(GET_TICKET.FAILURE, error),
};
export const updateTicketStatusDispatchers = {
    request: () => action(UPDATE_TICKET_STATUS.REQUEST),
    success: (ticket) => action(UPDATE_TICKET_STATUS.SUCCESS, ticket),
    failure: (error) => action(UPDATE_TICKET_STATUS.FAILURE, error),
};

export function getTickets() {
  return dispatch => {
    dispatch(getTicketsDispatchers.request());
    return api.getTickets()
      .then(tickets => {
        dispatch(getTicketsDispatchers.success(tickets));
        return tickets;
      })
      .catch(resp => {
        const msg = resp.message;
        dispatch(getTicketsDispatchers.failure(msg));
        return Promise.reject(msg);
      });
  };
}

export function getTicket(ticketId) {
  return dispatch => {
    dispatch(getTicketDispatchers.request());
    return api.getTicket(ticketId)
      .then(ticket => {
        dispatch(getTicketDispatchers.success(ticket));
        return ticket;
      })
      .catch(resp => {
        const msg = resp.message;
        dispatch(getTicketDispatchers.failure(msg));
        return Promise.reject(msg);
      });
  };
}

export function createTicket(userId, name) {
  return dispatch => {
    dispatch(createTicketDispatchers.request());
    return api.createTicket(userId, name)
      .then(ticket => {
        dispatch(createTicketDispatchers.success(ticket));
        return ticket;
      })
      .catch(resp => {
        const msg = resp.message;
        dispatch(createTicketDispatchers.failure(msg));
        return Promise.reject(msg);
      });
  };
}

export function createTicketMessage(ticket_id, message, send_mail, as_user) {
  return dispatch => {
    dispatch(createTicketMessageDispatchers.request());
    return api.createTicketMessage(ticket_id, message, send_mail, as_user)
      .then(message => {
        dispatch(createTicketMessageDispatchers.success(message));
        return message;
      })
      .catch(resp => {
        const msg = resp.message;
        dispatch(createTicketMessageDispatchers.failure(msg));
        return Promise.reject(msg);
      });
  };
}

export function updateTicketStatus(ticket_id, status) {
  return dispatch => {
    dispatch(updateTicketStatusDispatchers.request());
    return api.setTicketStatus(ticket_id, status)
      .then(ticket => {
        dispatch(updateTicketStatusDispatchers.success(ticket));
        return ticket;
      })
      .catch(resp => {
        const msg = resp.message;
        dispatch(updateTicketStatusDispatchers.failure(msg));
        return Promise.reject(msg);
      });
  };
}
