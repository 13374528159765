import React, { Component } from 'react';
import { connect } from 'react-redux';
import Timestamp from "react-timestamp";
import { bindActionCreators } from 'redux';
import { getInvoices } from "../../actions/Invoices";
import Table from "reactstrap/es/Table";
import { withRouter } from "react-router";
import { Card, CardBody, CardHeader } from "reactstrap";
import Loader from "../Common/Loader";
import { equalsZeroRule, isInitialized, meIsAdmin, visibleTitle } from "../Common/Utils";
import { Link } from "react-router-dom";

class PendingInvoicesCard extends Component {

  componentDidMount() {
    this.props.getInvoices();
  }

  render() {
    const { userId } = this.props;
    const unpaidInvoices = Object.values( this.props.invoices ).filter( a => {
      return !equalsZeroRule( a.grand_total - a.payments_total ) && a.due_date !== "DRAFT";
    } ).filter( a => {
      if ( userId === undefined ) return true;
      return userId === a.user.id
    } );
    return (
      <Card className="card-default">
        <CardHeader>
          <h3>{ visibleTitle( unpaidInvoices, ["pending invoices","pending invoice"] ) }</h3>
        </CardHeader>
        <CardBody>
          { isInitialized( unpaidInvoices ) ?
            <Table hover responsive>
              <thead>
              <tr>
                <th>ID</th>
                { meIsAdmin() &&
                <th>User</th>
                }
                <th>Due date</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody>
              {
                unpaidInvoices
                  .sort( ( a, b ) => a.due_date - b.due_date )
                  .map( function( invoice ) {
                      return <tr key={ invoice.id }>
                        <td><Link to={ `/console/invoice/${invoice.id}` }>{ invoice.id }</Link></td>
                        { meIsAdmin() &&
                        <td><Link to={ `/console/user/${invoice.user.id}` }>{ invoice.user.name }</Link></td>
                        }
                        <td>{
                          ( invoice.due_date === "DRAFT" ) ?
                            <div className="inline wd-xxs badge badge-info">
                              DRAFT</div>
                            :
                            ( invoice.due_date > ( new Date() ).getTime() / 1000 ) ?
                                <Timestamp date={invoice.due_date} options={{format:"date"}} />
                              :
                              <div
                                className="inline label badge badge-danger">
                                <Timestamp date={invoice.due_date} options={{format:"date"}} />
                              </div>
                        }</td>
                        <td>&euro; { parseFloat( Math.round( invoice.grand_total * 100 ) / 100 ).toFixed( 2 ) }</td>
                      </tr>
                    }
                  )
              }
              </tbody>
            </Table>
            :
            <Loader/>
          }
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ( state ) => {
  return {
    invoices: state.Invoices
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { getInvoices }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( PendingInvoicesCard ) );
