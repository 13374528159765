import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Col, Row } from 'reactstrap';
import { connect } from "react-redux";
import MailDomainDetailsPanel from "./MailDomainDetailsPanel";
import MailDomainBoxesPanel from "./MailDomainBoxesPanel";
import MailDomainDmarcPanel from "./MailDomainDmarcPanel";
import NewMailBoxPanel from "./NewMailBoxPanel";
import {  Card, CardBody, CardHeader } from "reactstrap";


class MailDomainView extends React.Component {

    constructor() {
        super();
        this.state = {
            domain: undefined
        };
        this.handleChange = this.handleChange.bind( this );
    }

    componentDidMount() {
        if ( this.props.domains !== undefined ) {
            this.setState( { domain: this.props.domains[ this.props.match.params.dId ] } );
        }
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.setState( { domain: nextProps.domains[ nextProps.match.params.dId ] } );
    }

    handleChange( mailbox ) {
        let domain = this.state.domain;
        domain.boxes.push( mailbox );
        this.setState( { domain } );

        this.forceUpdate();
    }

    render() {
        const { domain } = this.state;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    Domain #{ this.props.match.params.dId }
                </div>
                <Row>
                    <Col md={ 6 }>

                        <MailDomainDetailsPanel domain={ domain }/>
                        <MailDomainDmarcPanel domain={ domain }/>

                    </Col>
                    { domain !== undefined && domain.mail === "local" &&
                    <Col md={ 6 }>
                        <MailDomainBoxesPanel domain={ domain }/>
                        <NewMailBoxPanel domain={ domain } onChange={ b => this.handleChange( b ) }/>
                    </Col>
                    }

                    { domain !== undefined && domain.mail !== "local" &&
                    <Col md={ 6 }>
                        <Card className="card-default">
                            <CardHeader>
                                <h3 style={ { display: 'inline' } }>Notice</h3>
                            </CardHeader>
                            <CardBody>
                                <p>Mailbox management is not available for this email-provider. If you want to change mail-settings, please login to your provider console.</p>
                            </CardBody>
                        </Card>
                    </Col>
                    }
                </Row>
            </ContentWrapper>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        domains: state.Domains
    }
};
export default connect( mapStateToProps )( MailDomainView );
