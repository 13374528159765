import React from 'react';
import { Table } from 'reactstrap';
import Loader from "../Common/Loader.jsx";
import { connect } from "react-redux";
import prettyBytes from "../Common/PrettyBytes";
import { getDomainCfStats } from "../../actions/Domains";
import { bindActionCreators } from "redux";
import initSparkLine from '../Common/sparkline';
import $ from 'jquery';
import { Card, CardBody, CardHeader } from "reactstrap";

class DomainStatsPanel extends React.Component {

  constructor() {
    super();
    this.state = { stats: {}, cloudflare: false };
  }

  componentWillReceiveProps( props ) {
    if ( props.domain.backer === "cloudflare" ) {
      this.setState( { cloudflare: true } );
      this.props.getDomainCfStats( props.domain.id ).then( stats => {
        this.setState( { stats } );
      } );
    }


    $( '[data-sparkline]' ).each( initSparkLine );
  }

  render() {
    const { stats, cloudflare } = this.state;
    if ( cloudflare ) {
      return (
        <Card className="card-default">
          <CardHeader>
            Last week
          </CardHeader>
          <CardBody>
            { ( stats !== undefined && stats.until !== undefined ) ?

              <Table responsive hover>
                <tbody>
                <tr>
                  <td>
                    <strong>Secure requests</strong>
                  </td>
                  <td>{ stats.requests.ssl.encrypted } out of { stats.requests.all }</td>
                </tr>
                <tr>
                  <td>
                    <strong>Threats stopped</strong>
                  </td>
                  <td>{ stats.threats.all } out of { stats.threats.all }</td>
                </tr>
                <tr>
                  <td>
                    <strong>Bandwidth used</strong>
                  </td>
                  <td>{ prettyBytes( stats.bandwidth.all ) }</td>
                </tr>
                </tbody>

              </Table>
              :
              <Loader/>
            }
          </CardBody>
        </Card>

      );
    } else {
      return <span/>
    }
  }
}

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { getDomainCfStats }, dispatch )
};

export default connect( null, mapDispatchToProps )( DomainStatsPanel );

