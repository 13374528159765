import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faUserAlt
} from "@fortawesome/free-solid-svg-icons";
import Container from "reactstrap/es/Container";
import { Col, Row } from "reactstrap";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import FormGroup from "reactstrap/es/FormGroup";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import Input from "reactstrap/es/Input";
import { sendContactMessage } from '../../actions/Users'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import StateButton from "../Common/StateButton";
import { Link } from "react-router-dom";

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            error: ""
        };
        this.sendMessage = this.sendMessage.bind(this);
    }

    sendMessage() {
        return this.props.sendContactMessage(this.state.name, this.state.email, this.state.message).then(() => {
            this.setState({
                name: "",
                email: "",
                message: "",
                error: ""
            })
        })
    }

    render() {
        return (
            <div style={{
                margin: '0',
                marginTop: '80px',
                paddingTop: '60px',
                width: '100%',
                background: "#23b7e5"
            }}>
                <Container>
                    <Row className="justify-content-center mt--300">
                        <Col lg="8">
                            <Card className="bg-gradient-secondary shadow">
                                <CardBody className="p-lg-5">
                                    <h4 className="mb-1">Still have questions?</h4>
                                    <p className="mt-0">
                                        Get in touch so we can answer all of them.
                                    </p>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <FontAwesomeIcon style={{ width: '20px' }}
                                                        icon={faUserAlt} />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                value={this.state.name}
                                                onChange={e => this.setState({ name: e.target.value })}
                                                placeholder="Full name"
                                                type="text"
                                                onFocus={e => this.setState({ nameFocused: true })}
                                                onBlur={e => this.setState({ nameFocused: false })}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <FontAwesomeIcon style={{ width: '20px' }}
                                                        icon={faEnvelope} />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                value={this.state.email}
                                                onChange={e => this.setState({ email: e.target.value })}

                                                placeholder="Email address"
                                                type="email"
                                                onFocus={e => this.setState({ emailFocused: true })}
                                                onBlur={e => this.setState({ emailFocused: false })}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup className="mb-4">
                                        <Input
                                            value={this.state.message}
                                            onChange={e => this.setState({ message: e.target.value })}
                                            className="form-control-alternative"
                                            cols="80"
                                            name="name"
                                            placeholder="Type a message..."
                                            rows="4"
                                            type="textarea"
                                        />
                                    </FormGroup>
                                    <div>
                                        <StateButton block runClick={this.sendMessage}
                                            texts={['Send message', 'Sending message', 'Sent, thank you!', 'Invalid email']}
                                            className="btn-round btn-block btn btn-primary btn-lg btn-block text-md"
                                            color="primary" />
                                        <br />
                                        <span style={{ color: 'red' }}>{this.state.error}</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>


                <Container style={{ marginTop: '40px', paddingBottom: '10px', color: 'white' }}>
                    <Row className="align-items-center justify-content-md-between">
                        <Col md="2" />
                        <Col md="8">
                            <div className="copyright">
                                &copy; {new Date().getFullYear()}{" "}
                                PushTo.Space - Partner of <a style={{ color: 'white' }}
                                    href="https://aorta.space"
                                >
                                    Aorta.Space
                                </a>
                                <div style={{ float: 'right' }}>
                                    <Link style={{ marginLeft: '30px', color: 'white' }} to={'/legal/disclaimer'}>Disclaimer</Link>
                                    <Link style={{ marginLeft: '5px', color: 'white' }} to={'/legal/cookie-policy'}>Cookie policy</Link>
                                    <Link style={{ marginLeft: '5px', color: 'white' }} to={'/legal/privacy-notice'}>Privacy notice</Link>
                                    <Link style={{ marginLeft: '5px', color: 'white' }} to={'/legal/terms-of-use'}>Terms of use</Link>
                                </div>
                            </div>
                        </Col>
                        <Col md="2" />
                    </Row>

                    <Row className="align-items-center justify-content-center row">
                        <Col md="2" />
                        <Col md="8">
                            <div className="copyright" style={{ textAlign: "center" }}>
                                KVK: 59532742 BREDA - VAT: NL002336037B05
                            </div>
                        </Col>
                        <Col md="2" />
                    </Row>

                </Container>
            </div>
        );
    }

}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ sendContactMessage }, dispatch)
};

export default connect(null, mapDispatchToProps)(ContactForm);
