import React from 'react';
import { Table } from 'reactstrap';
import Timestamp from "react-timestamp";
import Loader from "../Common/Loader.jsx";
import prettyBytes from "../Common/PrettyBytes";
import { Card, CardBody, CardHeader } from "reactstrap";


class MailBoxDetailsPanel extends React.Component {

  render() {
    const { mailbox } = this.props;
    return (
      <Card className="card-default">
        <CardHeader>
          Details
        </CardHeader>
        <CardBody>
          { ( mailbox !== undefined && mailbox.address !== undefined ) ?
            <Table responsive hover>
              <tbody>
              <tr>
                <td>
                  <strong>Address</strong>
                </td>
                <td>{ mailbox.address }</td>
              </tr>
              <tr>
                <td>
                  <strong>Domain</strong>
                </td>
                <td>{ mailbox.address.split( "@" )[ 1 ] }</td>
              </tr>
              <tr>
                <td>
                  <strong>Login</strong>
                </td>
                <td>{ ( mailbox.password !== "SET" ) ?
                  <div
                    className="inline wd-xxs badge badge-warning">
                    Disabled</div>
                  :
                  <div
                    className="inline wd-xxs badge badge-success">
                    Enabled</div>
                }
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Size</strong>
                </td>
                <td>
                  { ( mailbox.size === 0 ) ?
                    <div
                      className="inline wd-xxs badge badge-info">
                      N/A
                    </div>
                    :
                    prettyBytes( mailbox.size )
                  }
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Created date</strong>
                </td>
                <td>
                  <Timestamp time={ mailbox.created } format='date'/>
                </td>
              </tr>
              </tbody>
            </Table>
            :
            <Loader/>
          }
        </CardBody>
      </Card> );
  }
}

export default MailBoxDetailsPanel;
