import { USER_INFO } from '../actions/UserInformation';
import { LOGOUT } from '../actions/Auth';

export default function UserInfo(state = {}, action) {

    switch (action.type) {
        case USER_INFO.SUCCESS:
            return action.payload;
        case LOGOUT.SUCCESS:
            return {};
        default:
            return state;
    }
}
