import React from 'react';
import { Table } from 'reactstrap';
import Loader from "../Common/Loader.jsx";
import { connect } from "react-redux";
import { getDomainDnsRecords } from "../../actions/Domains";
import { bindActionCreators } from "redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import NoResults from "../Common/NoResults";


class DomainDnsPanel extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            records: undefined,
            error: "",
        };
    }


    componentWillReceiveProps( props ) {
        console.log( props.domain.endpoints );
        if ( props.domain.name !== undefined ) {
            this.props.getDomainDnsRecords( props.domain.id ).then( records => {
                this.setState( { records } );
            } ).catch( data => {
                this.setState( { error: "no-results", records: [] } )
            } );
        }
    }

    extraRecordInfo( record ) {
        if ( this.props.domain !== undefined ) {
            if ( record.type === "CAA" ) {
                return (<i>Automatic SSL record</i>);
            }
            if ( record.type === "TXT" ) {
                if (record.content.substring(0, 6) === "v=spf1") {
                    return (<i>Email SPF-record. <Link to={ `/console/mail/${this.props.domain.id}` }>manage</Link></i>);
                }
                if (record.content.substring(0, 9) === "v=DMARC1;") {
                    return (<i>Email DMARC-record. <Link to={ `/console/mail/${this.props.domain.id}` }>manage</Link></i>);
                }
                if (record.content.substring(0, 8) === "v=DKIM1;") {
                    return (<i>Email DKIM-record. <Link to={ `/console/mail/${this.props.domain.id}` }>manage</Link></i>);
                }
            }
            if ( record.type === "MX" ) {
                return (<i>Email exchange. <Link to={ `/console/mail/${this.props.domain.id}` }>manage</Link></i>);
            }
            if ( record.type === "CNAME" ) {
                for ( let i = 0; i < this.props.domain.endpoints.length; i++ ) {
                    let endpoint = this.props.domain.endpoints[ i ];
                    if ( endpoint.name === record.name ) {
                        if (endpoint.volume !== null) {
                            return (<i>Serving volume <Link to={ `/console/volume/${endpoint.volume.id}` }>{ endpoint.volume.hash }</Link></i>);
                        }
                        if (endpoint.redirect !== null) {
                            return (<i>Redirects to {endpoint.redirect}</i>);
                        }
                        if ( JSON.parse(endpoint.meta).proxies ) {
                            return (
                                <i>Proxies { JSON.parse( endpoint.meta ).proxies && JSON.parse( endpoint.meta ).proxies[ 0 ].target }</i>);
                        }
                    }
                }
            }
        }
    }


    render() {
        const { records, error } = this.state;
        return (
            <Card className="card-default">
                <CardHeader>
                    Dns records
                </CardHeader>
                <CardBody>
                    { (error !== "") &&
                    <NoResults/>
                    }
                    { (records !== undefined) ?
                        <Table responsive hover>
                            <tbody>

                            {
                                records
                                    .map( record => {
                                            return <tr key={ record.id }>
                                                { (record.name === "") ?
                                                    <td><i>root</i></td>
                                                    :
                                                    <td>{ record.name }</td>
                                                }
                                                <td><b>{ record.type }</b></td>
                                                <td><b>{ record.content }</b><br/>{ this.extraRecordInfo( record ) }</td>

                                            </tr>
                                        }
                                    )
                            }
                            </tbody>

                        </Table>
                        :
                        <Loader/>
                    }
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        domains: state.Domains
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { getDomainDnsRecords }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( DomainDnsPanel ) );

