import {createRequestTypes, action} from './Util';
import * as api from '../services/Api';

export const GET_VMS = createRequestTypes('GET_VMS');
export const CREATE_IMAGE_FROM_VM = createRequestTypes('CREATE_IMAGE_FROM_VM');
export const DELETE_VM = createRequestTypes('DELETE_VM');
export const GET_DISKS = createRequestTypes('GET_DISKS');
export const DETACH_DISK_FROM_VM = createRequestTypes('DETACH_DISK_FROM_VM');
export const ATTACH_DISK_TO_VM = createRequestTypes('ATTACH_DISK_TO_VM');
export const ADD_FORWARD_TO_IP = createRequestTypes('ADD_FORWARD_TO_IP');
export const REMOVE_FORWARD_TO_IP = createRequestTypes('REMOVE_FORWARD_TO_IP');
export const GET_IMAGES = createRequestTypes('GET_IMAGES');
export const POWER_ON_VM = createRequestTypes('POWER_ON_VM');
export const POWER_OFF_VM = createRequestTypes('POWER_OFF_VM');
export const CREATE_VM = createRequestTypes('CREATE_VM');

export const addForwardToIpDispatchers = {
  request: () => action(ADD_FORWARD_TO_IP.REQUEST),
  success: (forward) => action(ADD_FORWARD_TO_IP.SUCCESS, forward),
  failure: (error) => action(ADD_FORWARD_TO_IP.FAILURE, error),
};
export const removeForwardToIpDispatchers = {
  request: () => action(REMOVE_FORWARD_TO_IP.REQUEST),
  success: (result) => action(REMOVE_FORWARD_TO_IP.SUCCESS, result),
  failure: (error) => action(REMOVE_FORWARD_TO_IP.FAILURE, error),
};
export const getVmsDispatchers = {
  request: () => action(GET_VMS.REQUEST),
  success: (vms) => action(GET_VMS.SUCCESS, vms),
  failure: (error) => action(GET_VMS.FAILURE, error),
};
export const createImageFromVmDispatchers = {
  request: () => action(CREATE_IMAGE_FROM_VM.REQUEST),
  success: (image) => action(CREATE_IMAGE_FROM_VM.SUCCESS, image),
  failure: (error) => action(CREATE_IMAGE_FROM_VM.FAILURE, error),
};
export const deleteVmDispatchers = {
  request: () => action(DELETE_VM.REQUEST),
  success: (result) => action(DELETE_VM.SUCCESS, result),
  failure: (error) => action(DELETE_VM.FAILURE, error),
};
export const getDisksDispatchers = {
  request: () => action(GET_DISKS.REQUEST),
  success: (disks) => action(GET_DISKS.SUCCESS, disks),
  failure: (error) => action(GET_DISKS.FAILURE, error),
};
export const detachDiskFromVmDispatchers = {
  request: () => action(DETACH_DISK_FROM_VM.REQUEST),
  success: (result) => action(DETACH_DISK_FROM_VM.SUCCESS, result),
  failure: (error) => action(DETACH_DISK_FROM_VM.FAILURE, error),
};
export const attachDiskToVmDispatchers = {
  request: () => action(ATTACH_DISK_TO_VM.REQUEST),
  success: (result) => action(ATTACH_DISK_TO_VM.SUCCESS, result),
  failure: (error) => action(ATTACH_DISK_TO_VM.FAILURE, error),
};
export const getImagesDispatchers = {
  request: () => action(GET_IMAGES.REQUEST),
  success: (images) => action(GET_IMAGES.SUCCESS, images),
  failure: (error) => action(GET_IMAGES.FAILURE, error),
};
export const powerOnVmDispatchers = {
    request: () => action(POWER_ON_VM.REQUEST),
    success: (result) => action(POWER_ON_VM.SUCCESS, result),
    failure: (error) => action(POWER_ON_VM.FAILURE, error),
};
export const powerOffVmDispatchers = {
    request: () => action(POWER_OFF_VM.REQUEST),
    success: (result) => action(POWER_OFF_VM.SUCCESS, result),
    failure: (error) => action(POWER_OFF_VM.FAILURE, error),
};
export const createVmDispatchers = {
  request: () => action(CREATE_VM.REQUEST),
  success: (volume) => action(CREATE_VM.SUCCESS, volume),
  failure: (error) => action(CREATE_VM.FAILURE, error),
};

export function getVms() {
    return dispatch => {
        dispatch(getVmsDispatchers.request());
        return api.getVms()
            .then(vms => {
                dispatch(getVmsDispatchers.success(vms));
                return vms;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(getVmsDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}
export function getDisks() {
    return dispatch => {
        dispatch(getDisksDispatchers.request());
        return api.getDisks()
            .then(vms => {
                dispatch(getDisksDispatchers.success(vms));
                return vms;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(getDisksDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function getImages() {
    return dispatch => {
        dispatch(getImagesDispatchers.request());
        return api.getImages()
            .then(images => {
                dispatch(getImagesDispatchers.success(images));
                return images;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(getImagesDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}
export function detachDiskFromVm(vm, disk) {
    return dispatch => {
        dispatch(detachDiskFromVmDispatchers.request());
        return api.detachDiskFromVm(vm, disk)
            .then(images => {
                dispatch(detachDiskFromVmDispatchers.success(images));
                return images;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(detachDiskFromVmDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}
export function addForwardToIp(vm, ip, port) {
    return dispatch => {
        dispatch(addForwardToIpDispatchers.request());
        return api.addForwardToIp(vm, ip, port)
            .then(result => {
                dispatch(addForwardToIpDispatchers.success(result));
                return result;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(addForwardToIpDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}
export function removeForwardFromIp(vm, ip, forward) {
    return dispatch => {
        dispatch(removeForwardToIpDispatchers.request());
        return api.removeForwardFromIp(vm, ip, forward)
            .then(result => {
                dispatch(removeForwardToIpDispatchers.success(result));
                return result;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(removeForwardToIpDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}
export function attachDiskToVm(vm, disk) {
    return dispatch => {
        dispatch(attachDiskToVmDispatchers.request());
        return api.attachDiskToVm(vm, disk)
            .then(result => {
                dispatch(attachDiskToVmDispatchers.success(result));
                return result;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(attachDiskToVmDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function powerOnVm(vm) {
    return dispatch => {
        dispatch(powerOnVmDispatchers.request());
        return api.powerOnVm(vm)
            .then(result => {
                dispatch(powerOnVmDispatchers.success(vm));
                return result;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(powerOnVmDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}
export function deleteVm(vm) {
    return dispatch => {
        dispatch(deleteVmDispatchers.request());
        return api.deleteVm(vm)
            .then(result => {
                dispatch(deleteVmDispatchers.success(result));
                return result;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(deleteVmDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}
export function createImageFromVm(vm) {
    return dispatch => {
        dispatch(createImageFromVmDispatchers.request());
        return api.createImageFromVm(vm)
            .then(image => {
                dispatch(createImageFromVmDispatchers.success(image));
                return image;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(createImageFromVmDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}
export function powerOffVm(vm) {
    return dispatch => {
        dispatch(powerOffVmDispatchers.request());
        return api.powerOffVm(vm)
            .then(result => {
                dispatch(powerOffVmDispatchers.success(vm));
                return result;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(powerOffVmDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function createVm(name, image, public_ip, cpus, mem, disks,ports, public_key, user_id) {
    return dispatch => {
        dispatch(createVmDispatchers.request());
        return api.createVm(name, image, public_ip, cpus, mem, disks,ports, public_key, user_id)
            .then(vm => {
                dispatch(createVmDispatchers.success(vm));
                return vm;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(createVmDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}
