import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Col, Row } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { callUserPhone, getUser } from "../../actions/UserInformation";
import { checkPostalCode } from '../../actions/Users';
import PendingInvoicesCard from "../Invoices/PendingInvoicesCard";
import UserPhones from "./UserPhones";
import { Card, CardBody, CardHeader } from "reactstrap";

class User extends React.Component {

  constructor() {
    super();

    this.state = {
      user: {
        id: 0,
        avatar: "/img/loading.gif",
        name: "",
        email: "",
        address: {
          postalCode: "",
          number: ""
        },
        phones: {}
      }
    };

    this.handleUserNameChange = this.handleUserNameChange.bind( this );
    this.handleUserEmailChange = this.handleUserEmailChange.bind( this );
    this.handleUserAddressTitleChange = this.handleUserAddressTitleChange.bind( this );
    this.handleUserAddressPostalChange = this.handleUserAddressPostalChange.bind( this );
    this.handleUserAddressNumberChange = this.handleUserAddressNumberChange.bind( this );
    this.saveUser = this.saveUser.bind( this );
    this.callUserPhone = this.callUserPhone.bind( this );
  }

  componentDidMount() {
    this.props.getUser( this.props.match.params.userId ).then( user => this.setState( { user } ) );
  }


  handleUserNameChange( event ) {
    let user = this.state.user;
    user.name = event.target.value;
    this.setState( { user } );
  }

  handleUserEmailChange( event ) {
    let user = this.state.user;
    user.email = event.target.value;
    this.setState( { user } );
  }

  handleUserAddressTitleChange( event ) {
    let user = this.state.user;
    user.address.title = event.target.value;
    this.setState( { user } );
  }

  handleUserAddressPostalChange( event ) {
    let postalCode = event.target.value.trim().toUpperCase().replace( / /g, '' );
    let user = this.state.user;
    user.address.postalCode = postalCode;
    user.address.street = "";
    user.address.city = "";
    user.address.number = "";
    this.setState( { user } );
    if ( postalCode.match( /^[1-9][0-9]{3}([a-zA-Z]{2})$/ ) ) {
      this.props.checkPostalCode( "NL", postalCode ).then( details => {
        let user = this.state.user;
        user.address.street = details.street;
        user.address.city = details.city;
        this.setState( { user } );
      } );
    }
  }

  handleUserAddressNumberChange( event ) {
    let user = this.state.user;
    user.address.number = event.target.value;
    this.setState( { user } );
  }

  callUserPhone( phoneId ) {
    this.props.callUserPhone( this.props.match.params.userId, phoneId );

  }

  saveUser() {
    console.log( this.state.user );
  }

  render() {
    const { user } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          User #{ this.props.match.params.userId }
        </div>
        <Row>
          <Col md={ 4 }>
            <Card className="card-default">
              <CardBody>
                <div className="text-center">
                  <div className="pv-lg">
                    { ( user.avatar !== "" ) ?
                      <img src={ `${user.avatar}` } alt="Avatar"
                           className="center-block img-responsive img-circle img-thumbnail thumb96"/>
                      :
                      <img src="/img/dummy.png" alt="Avatar"
                           className="center-block img-responsive img-circle img-thumbnail thumb96"/>

                    }
                  </div>
                  <h3 className="m0 text-bold">{ user.name }</h3>
                </div>
              </CardBody>
            </Card>

            <UserPhones call={ this.callUserPhone } phones={ this.state.user.phones }/>
            {/*<MandatesOverview userId={ this.props.match.params.userId }/>*/}

            <PendingInvoicesCard userId={ this.props.match.params.userId }/>
          </Col>
          <Col md={ 8 }>
            <Card className="card-default">
              <CardHeader>Contact Information</CardHeader>
              <CardBody>
                <div className="form-horizontal">
                  <div className="form-group">
                    <label htmlFor="inputContact1"
                           className="col-sm-2 control-label">Name</label>
                    <div className="col-sm-10">
                      <input id="inputContact1" type="text" placeholder=""
                             value={ user.name } onChange={ this.handleUserNameChange }
                             className="form-control"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputContact2"
                           className="col-sm-2 control-label">Email</label>
                    <div className="col-sm-10">
                      <input id="inputContact2" type="email" value={ user.email }
                             onChange={ this.handleUserEmailChange }
                             className="form-control"/>
                    </div>
                  </div>
                  <br/>
                  <div className="form-group">
                    <label htmlFor="inputContact3"
                           className="col-sm-2 control-label">Title</label>
                    <div className="col-sm-10">
                      <input id="inputContact3" type="text" value={ user.address.title }
                             onChange={ this.handleUserAddressTitleChange }
                             className="form-control"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputContact4"
                           className="col-sm-2 control-label">Postcode</label>
                    <div className="col-sm-10">
                      <input id="inputContact4" type="text"
                             value={ user.address.postalCode }
                             onChange={ this.handleUserAddressPostalChange }
                             className="form-control"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputContact5"
                           className="col-sm-2 control-label">Number</label>
                    <div className="col-sm-10">
                      <input id="inputContact5" type="text" value={ user.address.number }
                             onChange={ this.handleUserAddressNumberChange }
                             className="form-control"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputContact4"
                           className="col-sm-2 control-label">Street</label>
                    <div className="col-sm-10">
                      <input id="inputContact4" disabled type="text"
                             value={ user.address.street } className="form-control"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputContact5"
                           className="col-sm-2 control-label">City</label>
                    <div className="col-sm-10">
                      <input id="inputContact5" disabled type="text"
                             value={ user.address.city } className="form-control"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputContact5"
                           className="col-sm-2 control-label">Country</label>
                    <div className="col-sm-10">
                      <input id="inputContact5" disabled type="text"
                             value="The Netherlands" className="form-control"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" disabled onClick={ this.saveUser }
                              className="btn btn-info">Update
                      </button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { getUser, checkPostalCode, callUserPhone }, dispatch )
};

export default connect( null, mapDispatchToProps )( User );
