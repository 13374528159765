import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPtsClientToken, loginPTS } from '../../actions/Auth';
import { Button } from "reactstrap";
import { meIsId } from "../Common/Utils";
import { Link } from "react-router-dom";

class Login extends React.Component {

    constructor() {
        super();

        this.state = {
            username: "",
            password: "",
            remember: false,
            loginButton: { text: "Login", disabled: true, style: "primary" }
        };
        this.handleUsernameInput = this.handleUsernameInput.bind( this );
        this.handlePasswordInput = this.handlePasswordInput.bind( this );
        this.login = this.login.bind( this );

    }

    componentDidMount() {
        this.getClientToken();
    }

    getClientToken() {
        this.props.fetchPtsClientToken()
            .then( token => {
                this.token = token;
                this.setState( { loginButton: { text: "Login", disabled: false, style: "primary" } } );
                this.refs.loginButton.disabled = false;
            } );
    }

    login( e ) {
        e.preventDefault();
        const { username, password } = this.state;
        if ( username !== "" && password !== "" ) {
            this.setState( { loginButton: { text: "Logging in...", disabled: true, style: "default" } } );
            this.props.loginPTS( username, password, this.token )
                .then( () => {
                    if ( window.location.hostname === "dashboard.wolkairbag.com" || meIsId( "32XN2" ) || meIsId( "9MX98" ) ) {
                        this.props.history.push( '/console/customers/wolk' );
                    } else {
                        this.props.history.push( '/console/dashboard' );
                    }
                } )
                .catch( () => {
                    this.getClientToken();

                    this.setState( { loginButton: { text: "Login", disabled: false, style: "primary" } } );
                    this.refs.username.style.borderColor = "red";
                    this.refs.usernameoverlay.style.borderColor = "red";
                    this.refs.password.style.borderColor = "red";
                    this.refs.passwordoverlay.style.borderColor = "red";
                } );
        }
    }

    static validateInputField( target, overlay ) {
        if ( target.value === "" || target.value.length < 3 || target.value.length > 100 ) {
            target.style.borderColor = "red";
            overlay.style.borderColor = "red";
            return false;
        } else {
            target.style.borderColor = "#dde6e9";
            overlay.style.borderColor = "#dde6e9";
            return true;
        }
    }

    handleUsernameInput( event ) {
        const username = event.target.value;
        if ( Login.validateInputField( event.target, this.refs.usernameoverlay ) ) {
            this.setState( { username } );
        }
    }

    handlePasswordInput( event ) {
        const password = event.target.value;
        if ( Login.validateInputField( event.target, this.refs.passwordoverlay ) ) {
            this.setState( { password } );
        }
    }

    render() {
        return (
            <div className="block-center mt-4 wd-xl" style={ { paddingTop: '100px' } }>
                <div className="card card-flat">
                    <div className="card-header text-center" style={ { background: "#23b7e5" } }>
                        <img className="block-center rounded" src="/img/logo.png" alt="Logo"/>
                    </div>
                    <div className="card-body">
                        <p className="text-center py-2">SIGN IN TO CONTINUE.</p>
                        <form className="mb-3" id="loginForm" onSubmit={ this.login } noValidate>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" ref="username"
                                           id="exampleInputEmail1" type="text"
                                           placeholder="Username" autoComplete="off" required
                                           onChange={ this.handleUsernameInput }/>
                                    <div className="input-group-append">
                    <span ref="usernameoverlay"
                          className="input-group-text fa fa-envelope text-muted bg-transparent border-left-0"/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" ref="password"
                                           id="exampleInputPassword1"
                                           type="password" placeholder="Password" required
                                           onChange={ this.handlePasswordInput }/>
                                    <div className="input-group-append">
                    <span ref="passwordoverlay"
                          className="input-group-text fa fa-lock text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>
                            <Button type="submit" ref="loginButton" onClick={ this.login } className={ 'btn-block' }
                                    disabled={ this.state.loginButton.disabled } color={ this.state.loginButton.style }>
                                { this.state.loginButton.text }
                            </Button>
                        </form>
                    </div>
                </div>
                <p style={ { marginTop: "30px", textAlign: "center", fontSize: '10px' } }>By logging in, you agree to
                    our <Link to={ '/legal/cookie-policy' }>cookie policy</Link>.</p>
            </div>
        );
    }

}

function mapDispatchToProps( dispatch ) {
    return bindActionCreators( { loginPTS, fetchPtsClientToken }, dispatch )
}

export default connect( null, mapDispatchToProps )( withRouter( Login ) );
