import React from 'react';
import { withRouter } from 'react-router';
import { CardBody, CardHeader, Col, Row } from "reactstrap";
import Hero from "../hero";
import Card from "reactstrap/lib/Card";
import { Link } from "react-router-dom";

class Spacer extends React.Component {

    render() {
        return <span style={ { display: "inline-block", width: "15px" } }/>;
    }
}

class Docs extends React.Component {

    render() {

        return (
            <div style={ { display: 'block', margin: '0', width: '100%', height: '450px' } }>

                <Hero content={
                    <Row>
                        <Col sm={ 1 }/>
                        <Col sm={ 4 }>
                            <h1 className={ 'triangle-title' }>Documentation</h1>
                        </Col>
                        <Col sm={ 3 }/>
                        <Col sm={ 4 }>
                            <div className=" card card-flat signupPanel not-mobile"
                                 style={ { borderRadius: '20px', marginLeft: '20%', width: '100%' } }>
                                <div className="card-body" style={ { padding: '30px 40px' } }>
                                    <h1 className="text-center">Need support?</h1>
                                    <p>Just open a ticket or
                                        give us a call in case of an emergency.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }/>


                <div className="container container-lg" style={ { marginBottom: '80px', marginTop: '30px' } }>
                    <Row>
                        <Col sm={ 2 } lg={ 3 }>
                            <div>
                                <h4>Authentication</h4>
                                <h6>Api-Token</h6>

                                <hr style={ { marginTop: '50px' } }/>
                                <h4>Virtual machines</h4>
                                <h6>List vms</h6>
                                <h6>Get vm</h6>
                                <h6>Create vm</h6>
                                <h6>Power management</h6>
                            </div>
                        </Col>
                        <Col sm={ 4 } lg={ 9 }>
                            <Card>
                                <CardHeader>
                                    <h3 style={ { display: 'inline' } }>Authentication</h3>
                                </CardHeader>
                                <CardBody>
                                    <h4>Api-Token</h4>
                                    <p>To be able to make request to our api, you have to provide an Authorization
                                        header on every request.
                                        This can either be a <code>pts:v1</code> or <code>pts:v2</code> token.<br/><br/>
                                        <b>Note:</b> for automate use of the API we advise you to use a pts:v2
                                        token.<br/><br/>
                                        A <code>pts:v2</code> token can be obtained via the <Link to={ `/console` }>console</Link>. Once you have it, you can make
                                        your first request.</p>

                                    <code>
                                        GET https://api.pushto.space/v1/users/me<br/>
                                        Header: Authorization: _TOKEN_
                                    </code>
                                    <br/>
                                    <br/>
                                    You should see your own user details.<br/><br/>In the documentation, the
                                    authorization header is omitted.
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <h3 style={ { display: 'inline' } }>Virtual machines</h3>
                                </CardHeader>
                                <CardBody>
                                    <p>Virtual machines allow you to quickly spin up and down custom compute engines
                                        that may meet additional requirements which the managed hosting cluster does
                                        not.</p>
                                    <h4>List vms</h4>
                                    <p>This will give an overview of all your current virtual machines.</p>

                                    <code>
                                        GET https://api.pushto.space/v1/vms
                                    </code>
                                    <br/>
                                    <br/>
                                    <h4>Get vm</h4>
                                    <p>This will return 1 instance of the requested vm.</p>

                                    <code>
                                        GET https://api.pushto.space/v1/vms/_ID_
                                    </code>
                                    <br/>
                                    <br/>
                                    <h4>Get images</h4>
                                    <p>This will return a list of all available images (public + private).</p>

                                    <code>
                                        GET https://api.pushto.space/v1/vms/images
                                    </code>
                                    <br/>
                                    <br/>
                                    <h4>Create image from vm</h4>
                                    <p>This allows you to create a image from a vm to use it for future deployments.</p>

                                    <code>
                                        POST https://api.pushto.space/v1/vms/_VM_ID_/image
                                    </code>
                                    <br/>
                                    <br/>
                                    <h4>Create vm</h4>
                                    <p>This allows you to create a new vm.</p>

                                    <code>
                                        POST https://api.pushto.space/v1/vms<br/>
                                        Payload:<br/>
                                        { '{' }<br/>
                                        <Spacer/>"name": "My first server",<br/>
                                        <Spacer/>"image": "_IMAGE_ID_", // Obtained from 'Get images'<br/>
                                        <Spacer/>"public_ip": false,<br/>
                                        <Spacer/>"cpus": 4, // Range 1 - 8<br/>
                                        <Spacer/>"mem": 4, // Range 1 - 16<br/>
                                        <Spacer/>"disks": [<br/>
                                        <Spacer/><Spacer/>{ '{' }<br/>
                                        <Spacer/><Spacer/><Spacer/>"name": "disk for my first vm",<br/>
                                        <Spacer/><Spacer/><Spacer/>"size": 50, // Range 1 - 50<br/>
                                        <Spacer/><Spacer/>{ '}' }, { '{' }<br/>
                                        <Spacer/><Spacer/><Spacer/>"id": _DISK_ID_, // attach existing disk<br/>
                                        <Spacer/><Spacer/>{ '}' }<br/>
                                        <Spacer/>],<br/>
                                        <Spacer/>"public_ports": [<br/>
                                        <Spacer/><Spacer/>"TCP/22" // TCP & UDP support<br/>
                                        <Spacer/>],<br/>
                                        <Spacer/>"public_key": "ssh-rsa bla bla bla", // Your ssh-key<br/>
                                        <Spacer/>"env_data": { '{' }<br/>
                                        <Spacer/><Spacer/>"MY_KEY": "my_value"<br/>
                                        <Spacer/>{ '}' }<br/>
                                        { '}' }
                                    </code>
                                    <br/>
                                    <br/>
                                    <h4>State</h4>
                                    <p>After successful creation, the vm is scheduled for creation.
                                        The <code>state</code> field in the response can have the following values:<br/>
                                        <table>
                                            <tr style={{borderTop: '1px solid lightgrey'}}>
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    <code>created</code>
                                                </td>
                                                <td>
                                                    The VM-creation is pending and will be picked up soon by
                                                    one of our processes.
                                                </td>
                                            </tr>
                                            <tr style={{borderTop: '1px solid lightgrey'}}>
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    <code>creating</code>
                                                </td>
                                                <td>
                                                    The VM is being created. Depending on the size of the
                                                    image, this can take up to a few minutes.
                                                </td>
                                            </tr>
                                            <tr style={{borderTop: '1px solid lightgrey'}}>
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    <code>pending</code>
                                                </td>
                                                <td>
                                                    The VM-creation has finished and the VM is ready to boot.
                                                </td>
                                            </tr>
                                            <tr style={{borderTop: '1px solid lightgrey'}}>
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    <code>failed</code>
                                                </td>
                                                <td>
                                                    The VM-creation has failed.
                                                </td>
                                            </tr>
                                            <tr style={{borderTop: '1px solid lightgrey'}}>
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    <code>up</code>
                                                </td>
                                                <td>
                                                    The VM is powered on
                                                </td>
                                            </tr>
                                            <tr style={{borderTop: '1px solid lightgrey',borderBottom: '1px solid lightgrey'}}>
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    <code>down</code>
                                                </td>
                                                <td>
                                                    The Vm is powered off
                                                </td>
                                            </tr>
                                        </table>
                                    </p>
                                    <br/>
                                    <h4>Delete a vm</h4>

                                    <code>
                                        DELETE https://api.pushto.space/v1/vms/_VM_ID_
                                    </code>
                                    <br/>
                                    <br/>
                                    <h4>Get power state of vm</h4>

                                    <code>
                                        GET https://api.pushto.space/v1/vms/_VM_ID_/power
                                    </code>
                                    <br/>
                                    <br/>
                                    <h4>Power on vm</h4>

                                    <code>
                                        POST https://api.pushto.space/v1/vms/_VM_ID_/power/on
                                    </code>
                                    <br/>
                                    <br/>
                                    <h4>Power off vm</h4>

                                    <code>
                                        POST https://api.pushto.space/v1/vms/_VM_ID_/power/off
                                    </code>
                                    <br/>
                                    <br/>
                                    <h4>Disks</h4>
                                    Adding a new disk to a VM can be done by supplying the following payload in the disk-array.<br />
                                    <code>
                                        <Spacer/>{ '{' }<br/>
                                        <Spacer/><Spacer/>"name": "unique disk name",<br/>
                                        <Spacer/><Spacer/>"size": 50<br/>
                                        <Spacer/>{ '}' }
                                    </code>
                                    <br />
                                    <br />
                                    You are able to add existing disks to the VM by supplying an <code>ID</code> field.<br />
                                    <code>
                                        <Spacer/>{ '{' }<br/>
                                        <Spacer/><Spacer/>"id": "_DISK_ID_"<br/>
                                        <Spacer/>{ '}' }
                                    </code>
                                    <p>Please be aware that element 0 of the disk array is used as boot device and cannot be an existing disk, as all data would be overwritten.</p>
                                    <br/>

                                    <h4>Get all disks</h4>
                                    <p>This allows you to get a list of all your disks and see if they're attach or not.</p>

                                    <code>
                                        GET https://api.pushto.space/v1/vms/_VM_ID_/disks
                                    </code>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <h4>Attach an existing disk to a vm</h4>
                                    <i>This action cannot be performed when the VM is being created.</i>
                                    <p>This allows you to attach an existing disk to a virtual-machine.</p>

                                    <code>
                                        POST https://api.pushto.space/v1/vms/_VM_ID_/disks/_DISK_ID_
                                    </code>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <h4>Attach a new disk to a vm</h4>
                                    <i>This action cannot be performed when the VM is being created.</i>
                                    <p>This allows you to attach a new disk to a virtual-machine.</p>

                                    <code>
                                        POST https://api.pushto.space/v1/vms/_VM_ID_/disks<br />
                                        Payload:<br />
                                        <code>
                                            <Spacer/>{ '{' }<br/>
                                            <Spacer/><Spacer/>"name": "extra_disk",<br/>
                                            <Spacer/><Spacer/>"size": 25<br />
                                            <Spacer/>{ '}' }
                                        </code>
                                    </code>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <h4>Detach a disk from a vm</h4>
                                    <i>This action cannot be performed when the VM is being created or powered on.</i>
                                    <p>This allows you to detach a disk from a virtual-machine. The disk will not be destroyed and can be used in another vm.</p>

                                    <code>
                                        DELETE https://api.pushto.space/v1/vms/_VM_ID_/disks/_DISK_ID_
                                    </code>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <h4>Delete a detached disk</h4>
                                    <p>This allows you to delete a disk which had been detached from a virtual-machine. The disk will be destroyed!</p>

                                    <code>
                                        DELETE https://api.pushto.space/v1/vms/disks/_DISK_ID_
                                    </code>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <h4>Port forwarding</h4>
                                    When having at least one internal IP, you are able to use our NAT-enabled gateway. Port notation is <code>PROTOCOL/PORT-NUMBER</code>, where currently only the protocols TCP and UDP are supported.<br />
                                    <code><Spacer/>"public_ports": [<br/>
                                        <Spacer/><Spacer/>"TCP/22",<br/>
                                        <Spacer/><Spacer/>"UDP/3478"<br/>
                                        <Spacer/>]<br/></code>
                                    <br/>
                                    <p>If you want to restrict access to the port, you can supply an IP-address</p>
                                    <code><Spacer/>"public_ports": {'{'}<br/>
                                        <Spacer/><Spacer/>"TCP/22" => "1.2.3.4",<br/>
                                        <Spacer/><Spacer/>"UDP/3478" => "1.2.3.4"<br/>
                                        <Spacer/> {'}'}<br/></code>
                                    <p>After creation, you will get a random IP and port assigned for your port-forward, which can be found in the response.</p>
                                    <br/>

                                    <h4>Add a new port-forward to an ip</h4>
                                    <p>This allows you to create a new port-forward to a port on your virtual-machine.</p>

                                    <code>
                                        POST https://api.pushto.space/v1/vms/_VM_ID_/ip/_IP_ID_/forwards<br/>
                                        Payload:<br/>
                                        <Spacer/>{ '{' }<br/>
                                        <Spacer/><Spacer/>"port": "TCP/22",<br/>
                                        <Spacer/><Spacer/>"allowed_src": "1.2.3.4"<br/>
                                        <Spacer/>{ '}' }<br/>
                                    </code>
                                    <br />
                                    <h4>Delete a port-forward to an ip</h4>
                                    <p>This allows you to delete an existing port-forward to a port on your virtual-machine.</p>

                                    <code>
                                        DELETE https://api.pushto.space/v1/vms/_VM_ID_/ip/_IP_ID_/forwards/_FORWARD_ID_
                                    </code>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>);
    }
}

export default withRouter( Docs );
