import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

class UserAvatar extends Component {

    render() {
        const { history, user} = this.props;
        return (
            <img onClick={() => { history.push(`/console/user/${user.id}`); }} src={`${user.avatar}`} alt="Avatar" width="60" height="60" className="img-thumbnail img-circle"/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserInfo
    }
};

export default connect(mapStateToProps)(withRouter(UserAvatar));
