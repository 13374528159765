import {createRequestTypes, action} from './Util';
import * as api from '../services/Api';

export const GET_INVOICES = createRequestTypes('GET_INVOICES');
export const UPDATE_INVOICE = createRequestTypes('UPDATE_INVOICE');
export const CREATE_PAYMENT = createRequestTypes('CREATE_PAYMENT');
export const CREATE_INVOICE_MANDATE_PAYMENT = createRequestTypes('CREATE_INVOICE_MANDATE_PAYMENT');
export const CREATE_INVOICE_CONTENT = createRequestTypes('CREATE_INVOICE_CONTENT');
export const GET_INVOICE = createRequestTypes('GET_INVOICE');
export const GET_INVOICE_COMPONENTS = createRequestTypes('GET_INVOICE_COMPONENTS');
export const CREATE_INVOICE = createRequestTypes('CREATE_INVOICE');
export const CREATE_INVOICE_PAYMENT = createRequestTypes('CREATE_INVOICE_PAYMENT');
export const SEND_INVOICE_REMINDER = createRequestTypes('SEND_INVOICE_REMINDER');

export const getInvoicesDispatchers = {
    request: () => action(GET_INVOICES.REQUEST),
    success: (invoices) => action(GET_INVOICES.SUCCESS, invoices),
    failure: (error) => action(GET_INVOICES.FAILURE, error),
};
export const updateInvoiceDispatchers = {
    request: () => action(UPDATE_INVOICE.REQUEST),
    success: (invoice) => action(UPDATE_INVOICE.SUCCESS, invoice),
    failure: (error) => action(UPDATE_INVOICE.FAILURE, error),
};
export const createPaymentDispatchers = {
    request: () => action(CREATE_PAYMENT.REQUEST),
    success: (payment) => action(CREATE_PAYMENT.SUCCESS, payment),
    failure: (error) => action(CREATE_PAYMENT.FAILURE, error),
};
export const createInvoiceMandatePaymentDispatchers = {
    request: () => action(CREATE_INVOICE_MANDATE_PAYMENT.REQUEST),
    success: (payment) => action(CREATE_INVOICE_MANDATE_PAYMENT.SUCCESS, payment),
    failure: (error) => action(CREATE_INVOICE_MANDATE_PAYMENT.FAILURE, error),
};
export const getInvoiceDispatchers = {
    request: () => action(GET_INVOICE.REQUEST),
    success: (invoice) => action(GET_INVOICE.SUCCESS, invoice),
    failure: (error) => action(GET_INVOICE.FAILURE, error),
};
export const createInvoiceDispatchers = {
    request: () => action(CREATE_INVOICE.REQUEST),
    success: (invoice) => action(CREATE_INVOICE.SUCCESS, invoice),
    failure: (error) => action(CREATE_INVOICE.FAILURE, error),
};
export const createInvoicePaymentDispatchers = {
    request: () => action(CREATE_INVOICE_PAYMENT.REQUEST),
    success: (invoice) => action(CREATE_INVOICE_PAYMENT.SUCCESS, invoice),
    failure: (error) => action(CREATE_INVOICE_PAYMENT.FAILURE, error),
};
export const createInvoiceContentDispatchers = {
    request: () => action(CREATE_INVOICE_CONTENT.REQUEST),
    success: (content) => action(CREATE_INVOICE_CONTENT.SUCCESS, content),
    failure: (error) => action(CREATE_INVOICE_CONTENT.FAILURE, error),
};
export const getInvoicesComponentsDispatchers = {
    request: () => action(GET_INVOICE_COMPONENTS.REQUEST),
    success: (components) => action(GET_INVOICE_COMPONENTS.SUCCESS, components),
    failure: (error) => action(GET_INVOICE_COMPONENTS.FAILURE, error),
};
export const sendInvoiceReminderDispatchers = {
    request: () => action(SEND_INVOICE_REMINDER.REQUEST),
    success: (result) => action(SEND_INVOICE_REMINDER.SUCCESS, result),
    failure: (error) => action(SEND_INVOICE_REMINDER.FAILURE, error),
};

export function getInvoices() {
    return dispatch => {
        dispatch(getInvoicesDispatchers.request());
        return api.getInvoices()
            .then(invoices => {
                dispatch(getInvoicesDispatchers.success(invoices));
                return invoices;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(getInvoicesDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function getInvoiceComponents() {
    return dispatch => {
        dispatch(getInvoicesComponentsDispatchers.request());
        return api.getInvoiceComponents()
            .then(components => {
                dispatch(getInvoicesComponentsDispatchers.success(components));
                return components;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(getInvoicesComponentsDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function getInvoice(invoiceId) {
    return dispatch => {
        dispatch(getInvoiceDispatchers.request());
        return api.getInvoice(invoiceId)
            .then(invoices => {
                dispatch(getInvoiceDispatchers.success(invoices));
                return invoices;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(getInvoiceDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function updateInvoice(invoice) {
    return dispatch => {
        dispatch(updateInvoiceDispatchers.request());
        return api.updateInvoice(invoice)
            .then(invoice => {
                dispatch(updateInvoiceDispatchers.success(invoice));
                return invoice;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(updateInvoiceDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function createPayment(invoiceId, redirect_url) {
    return dispatch => {
        dispatch(createPaymentDispatchers.request());
        return api.createPayment(invoiceId, redirect_url)
            .then(payment => {
                dispatch(createPaymentDispatchers.success(payment));
                return payment;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(createPaymentDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function createInvoiceMandatePayment(invoiceId, userId) {
    return dispatch => {
        dispatch(createInvoiceMandatePaymentDispatchers.request());
        return api.createInvoiceMandatePayment(invoiceId, userId)
            .then(payment => {
                dispatch(createInvoiceMandatePaymentDispatchers.success(payment));
                return payment;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(createInvoiceMandatePaymentDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function sendInvoiceReminder(invoiceId, reason) {
    return dispatch => {
        dispatch(sendInvoiceReminderDispatchers.request());
        return api.sendInvoiceReminder(invoiceId, reason)
            .then(result => {
                dispatch(sendInvoiceReminderDispatchers.success(result));
                return result;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(sendInvoiceReminderDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function createInvoiceContent(invoiceId, component_id, comment, price, quantity) {
    return dispatch => {
        dispatch(createInvoiceContentDispatchers.request());
        return api.createInvoiceContent(invoiceId, component_id, comment, price, quantity)
            .then(contents => {
                dispatch(createInvoiceContentDispatchers.success(contents));
                return contents;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(createInvoiceContentDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function createInvoicePayment(invoiceId, account, amount) {
    return dispatch => {
        dispatch(createInvoicePaymentDispatchers.request());
        return api.createInvoicePayment(invoiceId, account, amount)
            .then(invoice => {
                dispatch(createInvoicePaymentDispatchers.success(invoice));
                return invoice;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(createInvoicePaymentDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function createNewInvoice(userId, date, dueDate) {
    return dispatch => {
        dispatch(createInvoiceDispatchers.request());
        return api.createInvoice(userId, date, dueDate)
            .then(invoice => {
                dispatch(createInvoiceDispatchers.success(invoice));
                return invoice;
            })
            .catch(resp => {
                const msg = resp.error;
                dispatch(createInvoiceDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

