import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Col, Row } from 'reactstrap';
import { getTicket, updateTicketStatus } from "../../actions/Tickets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TicketDetailsPanel from "./TicketDetailsPanel";
import TicketNewMessagePanel from "./TicketNewMessagePanel";
import TicketMessagePanel from "./TicketMessagePanel";
import TicketFirstMessagePanel from "./TicketFirstMessagePanel";


class Ticket extends React.Component {

  constructor() {
    super();
    this.state = {
      ticket: undefined
    };
    this.handleNewMessagePull = this.handleNewMessagePull.bind( this );
  }

  componentDidMount() {
    this.setState( { ticket: this.props.tickets[ this.props.match.params.ticketId ] } );
    this.props.getTicket( this.props.match.params.ticketId ).then( ticket => {
      this.setState( { ticket } );
    } );
  }

  handleNewMessagePull( message ) {
    console.log( message );
    let ticket = this.state.ticket;
    // ticket.messages.push( message );
    this.setState( { ticket } );
  }

  // componentWillReceiveProps( props ) {
  //   console.log(props.tickets[ this.props.match.params.ticketId ]);
  //   this.setState( { ticket: props.tickets[ this.props.match.params.ticketId ] } );
  // }

  render() {
    const { ticket } = this.state;
    const { history, match, user } = this.props;
    return (
      <ContentWrapper>
        <div className="content-heading">
          #{ match.params.ticketId }: { ( ticket !== undefined ) && ticket.name }
        </div>

        <Row>
          <Col lg={ 8 }>

            <TicketNewMessagePanel ticket={ ticket } user={ user } callback={ this.handleNewMessagePull }/>

            { ( ticket !== undefined && ticket.messages !== undefined ) &&
            ticket.messages.sort( ( a, b ) => b.date - a.date )
              .map( function( message ) {
                  if ( message !== undefined && message !== null ) {
                    return <TicketMessagePanel key={ message.id } message={ message }/>
                  }
                  return true;
                }
              )
            }
            <TicketFirstMessagePanel ticket={ ticket }/>
          </Col>
          <Col lg={ 4 }>
            <TicketDetailsPanel updateTicketStatus={ this.props.updateTicketStatus } ticket={ ticket }
                                history={ history } callback={ ( status, message ) => {
              ticket.status = status;
              ticket.messages.push( message );
              this.setState( { ticket } );
            } }/>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = ( state ) => {
  return {
    user: state.UserInfo,
    tickets: state.Tickets
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( {
    getTicket,
    updateTicketStatus
  }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( Ticket );
