import React from 'react';
import { withRouter } from 'react-router';
import { Col, Row } from "reactstrap";
import { getNtpScores } from "../../../../services/Api";

class Ntp extends React.Component {

  constructor() {
    super();
    this.state = {
      ipv4: { score: "Checking" },
      ipv6: { score: "Checking" }
    };
  }
  componentDidMount() {
    getNtpScores(`ipv4`).then(data => {
      this.setState({ipv4:{score:data.history[data.history.length -1].score}})
    });
    getNtpScores(`ipv6`).then(data => {
      this.setState({ipv6:{score:data.history[data.history.length -1].score}})
    });
  }

  render() {

    return (
      <div
           style={ { display: 'block', margin: '0', marginTop: '40px', width: '100%', height: '450px' } }>
        <Row>
          <Col sm={ 1 }/>
          <Col sm={ 5 }>

            <h1 className={'triangle-title'}>Ntp.PushTo.Space, we can tell the time</h1>
            {/*<p style={{color:'white', marginTop: '20px', width: '60%', marginLeft:'5%', fontSize: '20px'}}></p>*/ }
          </Col>
          <Col sm={ 2 }></Col>
          <Col sm={ 2 }>
            <div className=" card card-flat signupPanel"
                 style={ { borderRadius: '20px', marginLeft: '20%', width: '100%' } }>
              <div className="card-body" style={ { padding: '30px 40px' } }>
                <h1 className="text-center">All ok!</h1>
              </div>
            </div>
          </Col>
          <Col sm={ 1 }/>
        </Row>

        <em className="fa fa-5x fa-clock-o mb-lg"
            style={ { marginTop: '150px', marginBottom:'80px', fontSize: '150px', marginLeft: 'calc(50% - 75px)' } }/>

        <div className="container container-lg" style={ { marginBottom:'80px',marginTop: '50px' } }><Row>
          <Col md={ 6 }>
            <div className="plan">
              <h3 className="plan-header">IPv4<br/>185.40.95.3</h3>
              <ul className="plan-features" style={ { textAlign: 'center' } }>
                <li><b>DNS</b><br/>Ntp.PushTo.Space</li>
                <hr/>
                <li><em className="fa fa-check"/>Online</li>
                <hr/>
                <li>
                  <small>Score:</small>
                  <br/><h1 style={ { color: "#23b7e5" } }><a href="https://www.ntppool.org/scores/185.40.95.3">{ this.state.ipv4.score}</a>
                </h1>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={ 6 }>
            <div className="plan">
              <h3 className="plan-header">IPv6<br/>2a03:10c3:296a:ac00::ac43</h3>
              <ul className="plan-features" style={ { textAlign: 'center' } }>
                <li><b>DNS</b><br/>Ntp.PushTo.Space</li>
                <hr/>
                <li><em className="fa fa-check"/>Online</li>
                <hr/>
                <li>
                  <small>Score:</small>
                  <br/><h1 style={ { color: "#23b7e5" } }><a
                  href="https://www.ntppool.org/scores/2a03:10c3:296a:ac00::ac43">{ this.state.ipv6.score}</a></h1>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        </div>
      </div>

    );
  }

}

export default withRouter( Ntp );
