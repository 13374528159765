import { createRequestTypes, action } from './Util';
import * as api from '../services/Api';

export const GET_CRONS = createRequestTypes( 'GET_CRONS' );
export const CREATE_CRON = createRequestTypes( 'CREATE_CRON' );
export const DELETE_CRON = createRequestTypes( 'DELETE_CRON' );

export const getCronsDispatchers = {
    request: () => action( GET_CRONS.REQUEST ),
    success: ( crons ) => action( GET_CRONS.SUCCESS, crons ),
    failure: ( error ) => action( GET_CRONS.FAILURE, error ),
};
export const createCronDispatchers = {
    request: () => action( CREATE_CRON.REQUEST ),
    success: ( cron ) => action( CREATE_CRON.SUCCESS, cron ),
    failure: ( error ) => action( CREATE_CRON.FAILURE, error ),
};
export const deleteCronDispatchers = {
    request: () => action( DELETE_CRON.REQUEST ),
    success: ( result ) => action( DELETE_CRON.SUCCESS, result ),
    failure: ( error ) => action( DELETE_CRON.FAILURE, error ),
};

export function getCrons() {
    return dispatch => {
        dispatch( getCronsDispatchers.request() );
        return api.getCrons()
            .then( crons => {
                dispatch( getCronsDispatchers.success( crons ) );
                return crons;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( getCronsDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function createCron(url, interval, user_id) {
    return dispatch => {
        dispatch( createCronDispatchers.request() );
        return api.createCron(url, interval, user_id)
            .then( cron => {
                dispatch( createCronDispatchers.success( cron ) );
                return cron;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( createCronDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function deleteCron(id) {
    return dispatch => {
        dispatch( deleteCronDispatchers.request() );
        return api.deleteCron(id)
            .then( result => {
                dispatch( deleteCronDispatchers.success( result ) );
                return result;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( deleteCronDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}
