import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkPostalCode, createNewUser } from '../../actions/Users';
import $ from 'jquery';
import { Card, CardBody, CardHeader, Input } from "reactstrap";

class NewUserForm extends Component {

  constructor() {
    super();
    this.state = {
      username: "",
      addressTitle: "",
      streetName: "",
      streetNumber: "",
      postalCode: "",
      city: ""
    };

    this.validateUsernameInput = this.validateUsernameInput.bind( this );
    this.validateAddressTitleInput = this.validateAddressTitleInput.bind( this );
    this.validatePostalCodeInput = this.validatePostalCodeInput.bind( this );
    this.validateStreetNumberInput = this.validateStreetNumberInput.bind( this );
    this.checkIfUsernameExists = this.checkIfUsernameExists.bind( this );
    this.validateForm = this.validateForm.bind( this );
    this.saveUser = this.saveUser.bind( this );
  }

  validateForm() {
    if ( this.state.username !== "" &&
      this.state.addressTitle !== "" &&
      this.state.streetName !== "" &&
      this.state.streetNumber !== "" &&
      this.state.postalCode !== "" &&
      this.state.city !== "" ) {
      this.setState( {
        valid: true
      } );
    } else {
      this.setState( {
        valid: false
      } );
    }
  }

  saveUser( event ) {
    event.preventDefault();
    if ( !this.state.valid ) {
      return false;
    }
    this.props.createNewUser( this.state.username,
      this.state.addressTitle,
      this.state.streetName,
      this.state.streetNumber,
      this.state.postalCode,
      this.state.city )
      .then( user => {
        this.props.callback( user );
      } );

  }


  validateUsernameInput( event ) {
    let username = event.target.value.trim().toLowerCase();
    if ( username.length > 4 && username.length < 50 && !this.checkIfUsernameExists( username ) ) {
      event.target.value = username;
      $( event.target ).removeClass( "input-error" );
      this.setState( {
        username: username
      }, this.validateForm );
    } else {
      $( event.target ).addClass( "input-error" );
      this.setState( {
        username: ""
      }, this.validateForm );
    }
  }

  validateAddressTitleInput( event ) {
    let addressTitle = event.target.value.trim();
    if ( addressTitle.length > 4 && addressTitle.length < 80 ) {
      $( event.target ).removeClass( "input-error" );
      this.setState( {
        addressTitle
      }, this.validateForm );
    } else {
      $( event.target ).addClass( "input-error" );
      this.setState( {
        addressTitle: ""
      }, this.validateForm );
    }
  }

  validatePostalCodeInput( event ) {
    let postalCode = event.target.value.trim().toUpperCase().replace( / /g, '' );
    if ( postalCode.match( /^[1-9][0-9]{3}([a-zA-Z]{2})$/ ) ) {
      $( event.target ).removeClass( "input-error" );
      event.target.value = postalCode;
      this.setState( {
        postalCode
      } );
      this.props.checkPostalCode( "NL", postalCode, this.state.streetNumber ).then( details => {
        this.setState( {
          streetName: details.street,
          city: details.city
        }, this.validateForm );
      } );
    } else {
      $( event.target ).addClass( "input-error" );
      this.setState( {
        postalCode: "",
        streetName: "",
        city: ""
      }, this.validateForm );
    }
  }

  validateStreetNumberInput( event ) {
    let streetNumber = event.target.value.trim().toUpperCase().replace( / /g, '' );
    if ( streetNumber.match( /^[1-9][0-9]([a-zA-Z])?$/ ) ) {
      $( event.target ).removeClass( "input-error" );
      event.target.value = streetNumber;
      this.setState( {
        streetNumber
      }, this.validateForm );
      this.props.checkPostalCode( "NL", this.state.postalCode, streetNumber ).then( details => {
        this.setState( {
          streetName: details.street,
          city: details.city
        }, this.validateForm );
      } );
    } else {
      $( event.target ).addClass( "input-error" );
      this.setState( {
        streetNumber: ""
      }, this.validateForm );
    }
  }

  checkIfUsernameExists( username ) {
    for ( let i = 0; i < this.props.users.length; i++ ) {
      if ( this.props.users[ i ].name === username ) {
        return true;
      }
    }
    return false;
  }

  render() {
    return (
      <Card className="card-default" style={{ display: this.props.display }}>
        <CardHeader>Create new user</CardHeader>
        <CardBody>
          <form className="form-horizontal">
            <FormGroup>
              <label className="col-xl-2 col-form-label">Username</label>
              <div className="col-xl-10">
                <Input type="text" placeholder="Username" onChange={ this.validateUsernameInput } />
              </div>
            </FormGroup>
            <FormGroup>
              <label className="col-xl-2 col-form-label">Address title</label>
              <div className="col-xl-10">
                <Input type="text" placeholder="Address" onChange={ this.validateAddressTitleInput } />
              </div>
            </FormGroup>
            <FormGroup>
              <label className="col-xl-2 col-form-label">Postal code</label>
              <div className="col-xl-10">
                <Input type="text" placeholder="Postal code" onChange={ this.validatePostalCodeInput } />
              </div>
            </FormGroup>
            <FormGroup>
              <label className="col-xl-2 col-form-label">Street Number</label>
              <div className="col-xl-10">
                <Input type="text" placeholder="Street Number" onChange={ this.validateStreetNumberInput }/>
              </div>
            </FormGroup>
            <FormGroup>
              <label className="col-xl-2 col-form-label">Street name</label>
              <div className="col-xl-10">
                <Input type="text" placeholder="Street name" disabled value={ this.state.streetName } />
              </div>
            </FormGroup>
            <FormGroup>
              <label className="col-xl-2 col-form-label">City</label>
              <div className="col-xl-10">
                <Input type="text" placeholder="City" disabled value={ this.state.city } />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xl-10">
                <button disabled={ !this.state.valid } onClick={ this.saveUser } className="btn btn-primary">
                  Save user
                </button>
              </div>
            </FormGroup>
          </form>
        </CardBody>
      </Card>);
  }
}


const mapStateToProps = ( state ) => {
  return {
    users: state.Users
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { checkPostalCode, createNewUser }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( NewUserForm );
