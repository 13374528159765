import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Row } from "reactstrap";

class DomainInternetScore extends Component {

    render() {
        const {domain} = this.props;
        return (
          <Card className="card-default">
            <CardBody>
              <Row className="row-table">
                <Col xs={4} className="text-center pv-lg">
                  <img src={'/img/internetnl.png'} alt="internet.nl logo" style={{height: '60px'}} />
                </Col>
                <Col xs={8} className="pv-lg">
                  <div>
                    <div className="h3 mt0">Internet.nl score</div>
                    <Button onClick={() => {
                      window.open(`https://internet.nl/site/${domain.name}.${domain.tld.name}`,'_blank');
                    }} color="success" className="pull-right"
                            style={{marginBottom: "-15px", marginRight: "10px"}}>
                      <em className="fa fa-angle-right fa-fw mr-sm" />Check</Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        );
    }
}

export default DomainInternetScore;