import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getDomainMailBoxes, getDomains } from "../../actions/Domains";
import { withRouter } from "react-router";
import MailDomainsPanel from "./MailDomainsPanel";


class MailOverview extends React.Component {

    constructor() {
        super();
        this.state = { domain: { boxes: [] } };
    }

    componentDidMount() {
        this.props.getDomains();

    }

  componentWillReceiveProps(props) {
    if ( props.match.params.dId !== undefined ) {
      let domain = props.domains[ props.match.params.dId ];
      domain.loading = true;
      this.setState( { domain: domain } );
      this.props.getDomainMailBoxes( props.match.params.dId ).then( boxes => {
        boxes.map( box => {
          box.dId = props.match.params.dId;
          return true;
        } );
        domain.loading = false;
        domain.boxes = boxes;
        this.setState( { domain: domain } );
      } );
    }
  }



    render() {
        const domains = Object.values( this.props.domains );
        return (
            <ContentWrapper>
                <div className="content-heading">
                    Mail
                </div>
                <Row>

                    <Col md={ 10 }>
                        <MailDomainsPanel domains={ domains }/>
                    </Col>

                </Row>
            </ContentWrapper>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        domains: state.Domains
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { getDomains, getDomainMailBoxes }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( MailOverview ) );
