import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import { Card, CardBody, CardHeader, Input } from "reactstrap";

class NewTargetForm extends Component {

  constructor() {
    super();
    this.state = {
      name: "",
      valid: false
    };

    this.validateNameInput = this.validateNameInput.bind( this );
    this.saveTarget = this.saveTarget.bind( this );
  }

  saveTarget( event ) {
    event.preventDefault();
    if ( !this.state.valid ) {
      return false;
    }
    // this.props.createNewUser( this.state.username,
    //   this.state.addressTitle,
    //   this.state.streetName,
    //   this.state.streetNumber,
    //   this.state.postalCode,
    //   this.state.city )
    //   .then( user => {
    //     this.props.callback( user );
    //   } );
  }


  validateNameInput( event ) {
    let name = event.target.value;
    this.props.callback(name);
    if ( name.length > 4 && name.length < 50 ) {
      event.target.value = name;
      $( event.target ).removeClass( "input-error" );
      this.setState( {
        name: name,
        valid: true
      } );
    } else {
      $( event.target ).addClass( "input-error" );
      this.setState( {
        name: "",
        valid: false
      } );
    }
  }

  render() {
    return (
      <Card className="card-default" style={ { display: this.props.display } }>
        <CardHeader>Create new target</CardHeader>
        <CardBody>
          <form className="form-horizontal">
            <FormGroup>
              <label className="col-xl-2 col-form-label">Name</label>
              <div className="col-xl-10">
                <Input type="text" placeholder="Name" onChange={ this.validateNameInput }/>
              </div>
            </FormGroup>
          </form>
        </CardBody>
      </Card> );
  }
}

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { }, dispatch )
};

export default connect( null, mapDispatchToProps )( NewTargetForm );
