import React, { Component } from 'react';
import prettyBytes from "../Common/PrettyBytes";
import { Link } from "react-router-dom";


class DatabasesOverviewRow extends Component {

    render() {
        const { database } = this.props;
        return (
            <tr key={ database.id }>
                <td>{ database.id }</td>
                <td><Link
                    to={ `/console/user/${ database.user.id }` }>{ database.user.name }</Link>
                </td>
                <td>{ database.name }</td>
                <td>{ database.description }</td>
                <td>{ prettyBytes( parseInt(database.size) ) }</td>
            </tr>
        );
    }
}

export default DatabasesOverviewRow;

