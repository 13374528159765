import React, { Component } from 'react';
import './loader.scss';

class Loader extends Component {

  render() {
    return (
      <div style={ { position: 'static', height: '115px' } }>
                                    <span style={ {
                                      position: 'absolute',
                                      left: '50%',
                                      marginLeft: '-100px',
                                      width: '200px',
                                      marginTop: '0px',
                                      height: '20px',
                                      display: 'block'
                                    } }
                                          className="animatedBackground"/>
        <span style={ {
          position: 'absolute',
          left: '50%',
          marginLeft: '-100px',
          width: '130px',
          marginTop: '35px',
          height: '20px',
          display: 'block'
        } }
              className="animatedBackground"/>
        <span style={ {
          position: 'absolute',
          left: '50%',
          marginLeft: '-100px',
          width: '130px',
          marginTop: '70px',
          height: '20px',
          display: 'block'
        } }
              className="animatedBackground"/>
        <span style={ {
          position: 'absolute',
          left: '50%',
          marginLeft: '50px',
          width: '50px',
          marginTop: '35px',
          height: '55px',
          display: 'block'
        } }
              className="animatedBackground"/>
      </div>

    );
  }
}

export default Loader;


