import { LOGOUT } from '../actions/Auth';
import {GET_USER_MANDATES} from "../actions/Users";

export default function Mandates(state = {}, action) {
    switch (action.type) {
        case GET_USER_MANDATES.SUCCESS:
            return action.payload;

        case LOGOUT.SUCCESS:
            return {};

        default:
            return state;
    }
}