import {DISABLE_DMARC, ENABLE_DMARC, GET_DOMAINS, REFRESH_DOMAIN_ENDPOINTS} from '../actions/Domains';
import {LOGOUT} from '../actions/Auth';

export default function Domains(state = {}, action) {
    switch (action.type) {
        case GET_DOMAINS.SUCCESS:
            let store = {};
            action.payload.map(domain => {
                store[domain.id] = domain;
                return true;
            });
            return store;

        case ENABLE_DMARC.SUCCESS:
            let store1 = {};
            Object.values(state).map(domain => {
                if (domain.id === action.payload) {
                    domain.dmarc = {active: true};
                }
                store1[domain.id] = domain;
                return true;
            });
            return store1;

        case DISABLE_DMARC.SUCCESS:
            let store2 = {};
            Object.values(state).map(domain => {
                if (domain.id === action.payload) {
                    domain.dmarc = null;
                }
                store2[domain.id] = domain;
                return true;
            });
            return store2;

        case REFRESH_DOMAIN_ENDPOINTS.SUCCESS:
            state[action.payload.id] = action.payload;
            return state;

        case LOGOUT.SUCCESS:
            return {};

        default:
            return state;
    }
}
