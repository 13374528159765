import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faHeart, faMoneyBill, faTachometerAlt, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import Container from "reactstrap/es/Container";
import { Col, Row } from "reactstrap";
import HighlightLine from "./HighlightLine";

class HighlightBlock extends React.Component {

    render() {
        return (
            <section className="section section-lg">
                <Container>
                    <Row className="row-grid align-items-center">
                        <Col className="order-md-2" md="6">
                            <img
                                alt="..."
                                className="img-fluid floating"
                                src="img/2794208.png"
                            />
                        </Col>
                        <Col className="order-md-1" md="6">
                            <div className="pr-md-5">
                                <div
                                    className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                                    <FontAwesomeIcon icon={ faCogs }/>
                                </div>
                                <h3>Custom built with <FontAwesomeIcon style={ { marginLeft: '5px' } }
                                                                       icon={ faHeart }/></h3>
                                <p>
                                    Reinventing the wheel isn't always a bad thing. That is why we use custom-build
                                    software to keep our servers running. We know what we're doing.
                                </p>
                                <ul className="list-unstyled mt-5">
                                    <HighlightLine
                                        icon={ faTachometerAlt }
                                        color={ "success" }
                                        content={ "All systems are optimized for our workloads" }/>
                                    <HighlightLine
                                        icon={ faMoneyBill }
                                        color={ "success" }
                                        content={ "Low pricing because we're license-free" }/>
                                    <HighlightLine
                                        icon={ faUserAlt }
                                        color={ "success" }
                                        content={ "Kind support employees, you're not a number to us" }/>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

}

export default HighlightBlock;
