import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { enableSpf, disableSpf, getSpf } from "../../actions/Domains";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";


class MailDomainDetailsPanelSpfRow extends React.Component {

    constructor() {
        super();
        this.state = {
            enabled: undefined
        };
    }

    componentDidMount() {
        if ( this.props.domainId !== undefined ) {
            this.props.getSpf( this.props.domainId ).then( result => {
                if ( result.enabled === "unsupported" ) {
                    this.setState( { unsupported: true, enabled: false } );
                    return;
                }
                this.setState( { enabled: result.enabled } );
            } )
        }
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        if ( this.props.domainId !== undefined ) {
            this.props.getSpf( this.props.domainId ).then( result => {
                if ( result.enabled === "unsupported" ) {
                    this.setState( { unsupported: true, enabled: false } );
                    return;
                }
                this.setState( { enabled: result.enabled } );
            } )
        }
    }

    render() {
        if ( this.state.enabled === undefined ) {
            return (
                <td>Fetching...</td>
            );
        }
        if ( this.state.enabled === false ) {
            return (
                <td><FontAwesomeIcon icon={ faExclamationCircle } style={ { marginRight: '10px', color: 'orange' } }/>
                    <b>Disabled</b>
                    <span onClick={ () => {
                        this.props.enableSpf( this.props.domainId ).then( () => {
                            this.setState( { enabled: true } );
                        } )
                    } } className="pull-right" style={ { color: 'green', cursor: 'pointer' } }>
                        <FontAwesomeIcon icon={ faPlusCircle } style={ { marginRight: '5px' } }/>
                        Enable
                    </span>
                </td>
            );
        }
        if ( this.state.enabled === true ) {
            return (
                <td><FontAwesomeIcon icon={ faCheckCircle } style={ { marginRight: '10px', color: 'green' } }/>
                    <i>Enabled</i>
                    <span onClick={ () => {
                        this.props.disableSpf( this.props.domainId ).then( () => {
                            this.setState( { enabled: false } );
                        } )
                    } } className="pull-right" style={ { color: 'red', cursor: 'pointer' } }>
                        <FontAwesomeIcon icon={ faTimes } style={ { marginRight: '5px' } }/>
                        Disable
                    </span>
                </td>
            );
        }
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { enableSpf, disableSpf, getSpf }, dispatch )
};


export default connect( null, mapDispatchToProps )( withRouter( MailDomainDetailsPanelSpfRow ) );
