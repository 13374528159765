import React from 'react';
import Loader from "../Common/Loader.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import prettyBytes from "../Common/PrettyBytes";
import { Table, Card, CardBody, CardHeader } from "reactstrap";
import {
    containsRule,
    countVisibles,
    isInitialized,
    meIsAdmin,
    NewSearch
} from "../Common/Utils";
import NoResults from "../Common/NoResults";
import { Link } from "react-router-dom";
import SearchBar from "../Common/SearchBar";


class VolumesPanel extends React.Component {

    constructor( props ) {
        super( props );

        this.search = NewSearch( [
            ( n, c ) => {
                return containsRule( c.id.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.hash.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.name.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.user.name.toLowerCase(), n )
            }
        ], () => Object.values( this.props.volumes ) );
        this.search.onUpdate( () => this.forceUpdate() )
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.search.refresh();
    }
    componentDidMount() {
        this.search.refresh();
    }

    render() {
        return (
            <Card className="card-default">
                <CardHeader>
                    <h3 style={ { display: 'inline' } }>{ this.search.printVisibles( [ "volumes", "volume" ] ) }</h3>
                    <SearchBar search={this.search} />
                </CardHeader>
                <CardBody>
                    { isInitialized(this.search.getVisibles()) ?
                        <div>
                            <Table responsive hover>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Hash</th>
                                    <th>Size</th>
                                    { meIsAdmin() &&
                                    <th>User</th>
                                    }

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.search.getVisibles()
                                        .map( volume => {
                                                return <tr key={ volume.id }>
                                                    <td><Link to={ `/console/volume/${ volume.id }` }>{ volume.id }</Link>
                                                    </td>
                                                    <td>{ volume.name }</td>
                                                    <td>{ volume.hash }</td>
                                                    <td>{ prettyBytes( volume.size ? volume.size : 0 ) }</td>
                                                    { meIsAdmin() &&
                                                    <td><Link
                                                        to={ `/console/user/${ volume.user.id }` }>{ volume.user.name }</Link>
                                                    </td>
                                                    }
                                                </tr>
                                            }
                                        )
                                }
                                </tbody>
                            </Table>
                            { countVisibles( this.search.getVisibles() ) === 0 &&
                            <NoResults/>
                            }
                        </div>
                        :
                        <Loader/>
                    }
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        volumes: state.Volumes
    }
};


export default connect( mapStateToProps )( withRouter( VolumesPanel ) );
