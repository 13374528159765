import React from 'react';
import {  Col, Form, FormGroup } from 'reactstrap';
import { createVolume } from "../../actions/Volumes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import {
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import UserSelect from "../User/UserSelect";
import StateButton from "../Common/StateButton";
import { meIsAdmin } from "../Common/Utils";

class NewDatabasePanel extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            description: "",
            user_id: null
        };
        this.createDatabase = this.createDatabase.bind( this );
    }

    createDatabase( e ) {
        e.preventDefault();
        this.setState( { createVolumeButton: { text: "Creating", disabled: true, style: "warning" } } );
        //name, engine, template, account, backup_policy, user_id

        if ( this.name.value === "" ) {
            this.setState( { createVolumeButton: { disabled: true, text: "Error", style: "danger" } } );
            setTimeout( function () {
                this.setState( { createVolumeButton: { text: "Create", disabled: false, style: "primary" } } );
            }.bind( this ), 5000 );
            return;
        }

        this.props.createVolume( this.name.value, this.state.engine, this.state.template, this.state.account, this.convertBackupPolicy( this.state.backup ), this.state.user_id ).then( volume => {
            this.setState( { createVolumeButton: { text: "Created", disabled: true, style: "success" } } );
            setTimeout( function () {
                this.props.history.push( `/console/volume/${ volume.id }` );
            }.bind( this ), 100 );
        } ).catch( () => {
            this.setState( { createVolumeButton: { disabled: true, text: "Error", style: "danger" } } );
            setTimeout( function () {
                this.setState( { createVolumeButton: { text: "Create", disabled: false, style: "primary" } } );
            }.bind( this ), 5000 );
        } );
    }

    render() {
        const { users } = this.props;
        return (
            <Card className="card-default">
                <CardHeader>
                    <h3 style={ { display: 'inline' } }>Create database</h3>
                </CardHeader>
                <CardBody>
                    <Form className="form-horizontal">
                        <FormGroup>
                            <Col lg={ 2 }>
                                <Label>Description</Label>
                            </Col>
                            <Col lg={ 10 }>
                                <Input
                                    type="text" className="form-control" placeholder={ 'Description' }
                                    onChange={ e => this.setState( { description: e.target.value } ) }/>
                            </Col>
                        </FormGroup>
                        { meIsAdmin() &&
                        <FormGroup>
                            <Col sm={ 6 }>
                                <Label>User</Label>
                            </Col>
                            <Col sm={ 6 }>
                                <UserSelect users={ users } callback={ user_id => {
                                    this.setState( { user_id } )
                                } }/>
                            </Col>
                        </FormGroup>
                        }
                        <Col lg={ 7 }>
                        </Col>
                        <Col lg={ 5 }>
                            <StateButton runClick={ this.sendMessage }
                                         texts={ [ 'Create', 'Creating', 'Created', 'Failed' ] }
                                         color="primary" />
                        </Col>
                    </Form>
                </CardBody>
            </Card>);
    }
}

const mapStateToProps = ( state ) => {
    return {
        user: state.UserInfo,
        users: state.Users
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { createVolume }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( NewDatabasePanel ) );
