import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Col, Row } from "reactstrap";
import ExpensesOverview from "./ExpensesOverview";
import QuarterOverview from "./QuarterOverview";


class TaxesOverview extends React.Component {

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          Expenses
        </div>
        <Row>
          <Col lg={ 9 }>
            <ExpensesOverview />
          </Col>
          <Col lg={ 3 }>
            <QuarterOverview />
          </Col>
        </Row>
      </ContentWrapper> );
  }
}

export default TaxesOverview;
