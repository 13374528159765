import React, { Component } from 'react';
import ToggleState from "../../Common/ToggleState";
import { Link, withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";


class Header extends Component {

  render() {
    return (
      <header className="topnavbar-wrapper">
        <nav
          className={ this.props.layout.color !== undefined ? "navbar topnavbar header" + this.props.layout.color : "navbar topnavbar" }>
          <div className="navbar-header">
            <Link className="navbar-brand" to={`/console/dashboard`} style={ { cursor: "pointer" } }>
              <div className="brand-logo">
                <img className="img-fluid"
                     src={ this.props.layout.logo !== undefined ? this.props.layout.logo : "/img/logo.png" }
                     alt="App Logo"/>
              </div>
              <div className="brand-logo-collapsed">
                <img className="img-fluid"
                     src={ this.props.layout.logo !== undefined ? this.props.layout.logo : "/img/logo-single.png" }
                     alt="App Logo"/>
              </div>
            </Link>
            <ToggleState state="aside-toggled" nopersist={ true }>
              <span className="nav-link sidebar-toggle d-md-none" style={ { top: '8px' } }>
                <FontAwesomeIcon icon={faBars} style={{color:'white',fontSize: '20px', marginTop: '3px'}} />
              </span>
            </ToggleState>
          </div>
          { this.props.layout.title &&
          <h2 style={ {
            display: 'inline',
            float: 'left',
            marginTop: '8px',
            marginRight: '30px'
          } }>{ this.props.layout.title }</h2>
          }
        </nav>
      </header>
    );
  }

}

const mapStateToProps = ( state ) => {
  return {
    layout: state.Layout
  }
};

export default connect( mapStateToProps )( withRouter( Header ) );
