import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentWrapper from '../Layout/ContentWrapper';
import { checkPostalCode, getUsers } from '../../actions/Users';
import { createNewInvoice } from '../../actions/Invoices';
import NewUserForm from './NewUserForm';
import moment from "../../../node_modules/moment/moment";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import UserSelect from "../User/UserSelect";

class NewInvoice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      creatingNewUser: 'none',
      user_id: undefined,
      contents: []
    };

    this.handleCreateInvoice = this.handleCreateInvoice.bind( this );
    this.userCreatedCallback = this.userCreatedCallback.bind( this );
    this.addNewContentRow = this.addNewContentRow.bind( this );
  }

  userCreatedCallback( user ) {
    this.setState( { creatingNewUser: "none" } );
    this.props.getUsers();
  }

  componentDidMount() {
    this.props.getUsers();
  }

  addNewContentRow() {
    let contents = this.state.contents;
    contents.push( {
      id: contents.length + 1,
      quantity: 1,
      price: "0.00",
      total: "0.00"
    } );
    this.setState( {
      contents
    } );
  }

  handleCreateInvoice( e ) {
    e.preventDefault();
    this.props.createNewInvoice(
      this.state.user_id,
      moment( this.refs.invoiceDate.state.inputValue ).unix(),
      0
    ).then( invoice => {
      this.props.history.push( '/console/invoice/' + invoice.id );
    } );
  }

  render() {
    const { users } = this.props;
    return (
      <ContentWrapper>
        <div className="content-heading">
          Create invoice
        </div>
        <div className="form-horizontal">
          <fieldset>
            <div className="form-group">
              <label className="col-sm-2 control-label">User name</label>
              <Col sm={ 10 }>
                <UserSelect users={ users } callback={ user_id => {
                  this.setState( { user_id } )
                } }/>

              </Col>
            </div>
          </fieldset>

          <NewUserForm ref="newUserForm" display={this.state.creatingNewUser} callback={ this.userCreatedCallback }/>

          <fieldset>
            <div className="form-group">
              <label className="col-sm-2 control-label">Date</label>
              <Col sm={ 4 }>
                <Datetime timeFormat={ false } ref="invoiceDate" inputProps={ { className: 'form-control' } }/>
              </Col>
            </div>
          </fieldset>

          <fieldset>
            <div className="form-group">
              <div className="col-sm-4 col-sm-offset-2">
                <button onClick={ this.handleCreateInvoice } className="btn btn-primary">Create invoice</button>
              </div>
            </div>
          </fieldset>
        </div>
      </ContentWrapper>
    );
  }
}


const mapStateToProps = ( state ) => {
  return {
    users: state.Users
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { getUsers, checkPostalCode, createNewInvoice }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( NewInvoice );
