import React from 'react';

import Header from './Header'
import Sidebar from '../Sidebar'
import Footer from './Footer'
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";

const Base = props => (
  <div className="wrapper">
    <Header />

    <Sidebar />

    <section className={props.layout.sidebar !== undefined ? "section-container nosidebarcontainer" : "section-container"}>
      { props.children }
    </section>

    <Footer />
  </div>
);

const mapStateToProps = ( state ) => {
  return {
    layout: state.Layout
  }
};

export default connect( mapStateToProps )( withRouter( Base ) );

