import { createRequestTypes, action } from './Util';
import { authenticateUser, fetchClientToken, logoutUser, refreshAccessToken } from '../services/Api';
import { getUserInformation } from './UserInformation';

export const CLIENT_TOKEN = createRequestTypes('CLIENT_TOKEN');
export const AUTH = createRequestTypes('AUTH');
export const AUTH_REFRESH = createRequestTypes('AUTH_REFRESH');
export const LOGOUT = createRequestTypes('LOGOUT');

export const auth = {
    request: () => action(AUTH.REQUEST),
    success: (tokens) => action(AUTH.SUCCESS, tokens),
    failure: (error) => action(AUTH.FAILURE, error),
};

export const authRefresh = {
    request: () => action(AUTH_REFRESH.REQUEST),
    success: (token) => action(AUTH_REFRESH.SUCCESS, token),
    failure: (error) => action(AUTH_REFRESH.FAILURE, error),
};

export const clientToken = {
    request: () => action(CLIENT_TOKEN.REQUEST),
    success: (token) => action(CLIENT_TOKEN.SUCCESS, token),
    failure: (error) => action(CLIENT_TOKEN.FAILURE, error),
};

export const logout = {
    request: () => action(LOGOUT.REQUEST),
    success: () => action(LOGOUT.SUCCESS),
    failure: (error) => action(LOGOUT.FAILURE, error),
};

export function fetchPtsClientToken() {
    return dispatch => {
        dispatch(clientToken.request());
        return fetchClientToken()
            .then(token => {
                dispatch(clientToken.success(token));
                return token;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(clientToken.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function loginPTS(username, password, client_token) {
    return dispatch => {
        dispatch(auth.request());
        return authenticateUser(username, password, client_token)
            .then(tokens => {
                dispatch(auth.success(tokens));
                return tokens;
            })
            .then(() => dispatch(getUserInformation()))
            .catch(resp => {
                const msg = resp.message;
                dispatch(auth.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function logoutPTS() {
    return dispatch => {
        dispatch(logout.request());
        return logoutUser()
            .then(dispatch(logout.success()))
            .catch(dispatch(logout.failure()));
    };
}

export function refreshAuthToken(access_token, refresh_token, client_token) {
    return dispatch => {
        dispatch(authRefresh.request());
        return refreshAccessToken(
            client_token,
            refresh_token,
            access_token
        )
            .then(token => {
                dispatch(authRefresh.success(token));
                return token;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(authRefresh.failure(msg));
                return Promise.reject(msg);
            });


    };
}
