import { AUTH, LOGOUT, AUTH_REFRESH } from '../actions/Auth';

export default function Authentication(state = {}, action) {
    switch (action.type) {
        case AUTH.SUCCESS:
            const { access_token, refresh_token } = action.payload;
            return {
                accessToken: access_token.token,
                refreshToken: refresh_token.token
            };
        case AUTH_REFRESH.SUCCESS:
            state.accessToken = action.payload;
            return state;
        case LOGOUT.SUCCESS:
            return {
                accessToken: undefined,
                refreshToken: undefined
            };
        default:
            return state;
    }
}
