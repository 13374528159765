import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import $ from 'jquery';

import Routes from './Routes';

import './modernizr.js';
import './styles/bootstrap.scss';
import './styles/app.scss'
import Store from "./services/Store";
import Provider from "react-redux/es/components/Provider";

$.ajaxPrefilter(o => o.async = true);


export default class App extends Component {

  render() {
    return (
      <Provider store={ Store }>
        <BrowserRouter basename={'/'}>
            <Routes />
        </BrowserRouter>
      </Provider>
    );
  }
}
