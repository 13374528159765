import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "reactstrap/es/Badge";

class HighlightLine extends React.Component {

    render() {
        return (
            <li className="py-2">
                <div className="d-flex align-items-center">
                    <div>
                        <Badge className="badge-circle mr-3" color={this.props.color}>
                            <FontAwesomeIcon icon={ this.props.icon }/>
                        </Badge>
                    </div>
                    <div>
                        <h6 className="mb-0">
                            { this.props.content }
                        </h6>
                    </div>
                </div>
            </li>
        );
    }

}

export default HighlightLine;
