import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ContentWrapper from '../Layout/ContentWrapper';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from "redux";
import { getVolumes } from "../../actions/Volumes";
import VolumesPanel from "./VolumesPanel";
import NewVolumePanel from "./NewVolumePanel";

class Volumes extends React.Component {

  componentDidMount() {
    this.props.getVolumes();
  }


  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          Volumes
        </div>
        <Row>
            <Col md={ 8 }>
                <VolumesPanel/>
            </Col>

            <Col md={ 4 }>
                <NewVolumePanel/>
            </Col>

        </Row>
      </ContentWrapper>
    );
  }
}

function mapDispatchToProps( dispatch ) {
  return bindActionCreators( { getVolumes }, dispatch )
}

const mapStateToProps = ( state ) => {
  return {
    volumes: state.Volumes
  }
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( Volumes ) );
