import React from 'react';
import { Table } from 'reactstrap';
import Timestamp from "react-timestamp";
import Loader from "../Common/Loader.jsx";
import { connect } from "react-redux";
import { getDomainCfStats, refreshDomainEndpoints } from "../../actions/Domains";
import { bindActionCreators } from "redux";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { meIsAdmin } from "../Common/Utils";


class DomainDetailsPanel extends React.Component {


    render() {
        const { domain } = this.props;
        return (
            <Card className="card-default">
                <CardHeader>
                    Details
                </CardHeader>
                <CardBody>
                    { (domain !== undefined && domain.name !== undefined) ?
                        <Table responsive hover>
                            <tbody>
                            <tr>
                                <td>
                                    <strong>Domain</strong>
                                </td>
                                <td><Link
                                    to={ `/console/domain/${ domain.id }` }>{ domain.name }.{ domain.tld.name }</Link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Expires</strong>
                                </td>
                                <td>{ (domain.expires) ?
                                    <Timestamp time={ domain.expires } format='date'/>
                                    :
                                    <div
                                        className="inline wd-xxs badge badge-warning">
                                        Inactive</div>
                                }</td>

                            </tr>
                            <tr>
                                <td>
                                    <strong>Mail-type</strong>
                                </td>
                                <td>{ (domain.mail === "local") ?
                                    <Link to={ `/console/mail/${ domain.id }` }>PushTo.Space</Link>
                                    :
                                    <div
                                        className="inline wd-xxs label label-warning">
                                        External</div>
                                }</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Backer</strong>
                                </td>
                                { domain.backer === "cloudflare" ?
                                    <td><img src="/img/cloudflare.png" alt="cloudflare-logo"
                                             style={ { height: "20px" } }/></td>
                                    :
                                    <td>{ domain.backer || "Unknown" }</td>
                                }

                            </tr>
                            { (domain.backer === "cloudflare" && meIsAdmin()) &&
                            <tr>
                                <td>
                                    <strong>Endpoints</strong>
                                </td>
                                <td>
                                    <Button onClick={ () => {
                                        this.props.refreshDomainEndpoints( domain.id );
                                    } } color="success">
                                        <em className="fa fa-angle-right fa-fw mr-sm"/>Refresh</Button></td>
                            </tr>
                            }

                            </tbody>

                        </Table>
                        :
                        <Loader/>
                    }
                </CardBody>
            </Card>
        );
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { getDomainCfStats, refreshDomainEndpoints }, dispatch )
};

export default connect( null, mapDispatchToProps )( withRouter( DomainDetailsPanel ) );

