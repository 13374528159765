import React from 'react';

class Banner extends React.Component {

    render() {
        return (
            <div className={"typingh1"} >
              <h1 className={ "typingh1text" }>{this.props.title}</h1>
            </div>
            );
    }

}

export default Banner;
