import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import UserGreetingOrLogin from "../../Homepage/UserGreetingOrLogin";

class Header extends React.Component {

    render() {
        return (

            <header className="navbar navbar-default navbar-fixed-top "
                    style={ { position: 'absolute', zIndex: '300' } }>
                <div className="container">
                    <div className="navbar-header">
                        <Link
                            to={ `/home` } className="navbar-brand">
                            <img src={ require( "../../../assets/logo_colored.png" ) }
                                 style={ {
                                     height: '40px',
                                     display: 'inline',
                                     marginRight: '10px',
                                     marginTop: '-5px',
                                     marginLeft: '0',
                                     position: 'absolute'
                                 } } alt="Logo"/>
                            <span style={ { marginLeft: '70px', color: 'white' } }>PUSHTO.SPACE</span>
                        </Link>
                    </div>

                    <div id='cssmenu'>
                        <ul>
                            <li><a style={ { cursor: 'pointer' } } href={ "https://status.pushto.space" }>Status</a></li>

                            <li className={ 'not-mobile' }><Link className={ 'signupButton' } style={ {
                                marginLeft: '20px',
                                cursor: 'pointer',
                                border: '1px solid white',
                                borderRadius: '20px'
                            } } to={ `/console/dashboard` }><UserGreetingOrLogin/></Link></li>

                        </ul>
                    </div>

                </div>
            </header>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        user: state.UserInfo
    }
};

export default connect( mapStateToProps )( withRouter( Header ) );
