import AsyncSelect from "react-select/async/dist/react-select.esm";
import Label from "reactstrap/lib/Label";
import React from "react";


const UserSelect = (props) => {
    if (props.users === undefined || !Array.isArray(props.users)) {
        return (<Label>Loading users...</Label>);
    }
    return (
        <AsyncSelect
                loadOptions={ ( input, update ) => {
                    update( props.users.filter(user => {
                        return user.name.toLowerCase().includes(input.toLowerCase());
                    }).map( user => {
                        return {
                            value: user.id,
                            label: user.name
                        }
                    } ) );
                } }
                defaultOptions
                onChange={ ( newValue ) => {
                    props.callback(newValue.value);
                    return newValue;
                } }
            />

    );
}
export default UserSelect
