import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ContentWrapper from '../Layout/ContentWrapper';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from "redux";
import { startMining, stopMining } from "../../actions/Wallet";
import DomainDetailsPanel from "./DomainDetailsPanel";
import DomainStatsPanel from "./DomainStatsPanel";
import DomainInternetScore from "./DomainInternetScore";
import DomainDnsPanel from "./DomainDnsPanel";

class Domain extends React.Component {

    constructor( props ) {
        super( props );
        this.state = { domain: {} };

    }

    componentDidMount() {
        this.setState( {
            domain: this.props.domains[ this.props.match.params.domainId ]
        } );
    }


    render() {
        const { domain } = this.state;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Domain #{ this.props.match.params.domainId }</div>
                </div>
                <Row>
                    <Col md={ 4 }>
                        <DomainDetailsPanel domain={ domain }/>
                        <DomainInternetScore domain={ domain }/>
                        <DomainStatsPanel domain={ domain }/>
                    </Col>
                    <Col md={ 8 }>
                        <DomainDnsPanel domain={ domain }/>
                    </Col>
                </Row>
            </ContentWrapper>
        );
    }
}

function mapDispatchToProps( dispatch ) {
    return bindActionCreators( { startMining, stopMining }, dispatch )
}

const mapStateToProps = ( state ) => {
    return {
        domains: state.Domains
    }
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( Domain ) );
