import React from 'react';
import { getExpenses, getExpense } from "../../actions/Taxes";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { Table, Card, CardBody, CardHeader } from "reactstrap";
import { containsRule, resetVisibles, searchArray } from "../Common/Utils";
import { Link, withRouter } from "react-router-dom";


class QuarterOverview extends React.Component {

  constructor() {
    super();
    this.state = {
      thisQ: true,
      summary: true
    };
    this.handleSearch = this.handleSearch.bind( this );
    this.openAttachment = this.openAttachment.bind( this );
  }

  componentDidMount() {
    this.props.getExpenses();
  }

  componentWillUnmount() {
    resetVisibles( this.props.expenses );
  }

  handleSearch( event ) {
    searchArray( event.target.value, this.props.expenses, [
      ( n, c ) => {
        return containsRule( c.id.toLowerCase(), n )
      },
      ( n, c ) => {
        return containsRule( c.name.toLowerCase(), n )
      },
      ( n, c ) => {
        return containsRule( c.target.name.toLowerCase(), n )
      }
    ] );
    this.forceUpdate();
  }

  openAttachment = expenseId => {
    let tab = window.open( '', '_blank' );
    this.props.getExpense( expenseId ).then( expense => {
      tab.location.href = expense.attachment.data;
    } );
  };

  render() {
    const invoices = Object.values( this.props.invoices || [] );
    const expenses = Object.values( this.props.expenses || [] );


    let today = new Date();
    today.setDate(today.getDate()-5);
    let quarter = Math.floor( ( today.getMonth() + 3 ) / 3 );
    let lastQuarter = Math.floor( ( today.getMonth() ) / 3 );
    let lastYear = today.getUTCFullYear();
    if (lastQuarter === 0) {
      lastQuarter = 4;
      lastYear = today.getUTCFullYear() - 1;
    }
    let totalTaxInvoices = 0;
    let totalTaxExpenses = 0;
    return (
      <Card className="card-default">
        <CardHeader>
          <h3 style={ { display: 'inline' } }>
            {
              ( this.state.thisQ ) ?
                <span>Q{ quarter } { today.getUTCFullYear() } / <span style={ { cursor: "pointer", color:'#5d9cec' } }
                                                                   onClick={ () => {this.setState( { thisQ: false } )} }>
                  Q{ lastQuarter } { lastYear }
                </span>
                </span>
                :
                <span>
                  <span style={ { cursor: "pointer", color:'#5d9cec'  } } onClick={ () => {this.setState( { thisQ: true } )} }>
                    Q{ quarter } { today.getUTCFullYear() } </span> / Q{ lastQuarter } { lastYear }
                </span>
            }
          </h3><br/>
          <h5 style={ { display: 'inline' } }>
            {
              ( this.state.summary ) ?
                <span>Summary / <span style={ { cursor: "pointer", color:'#5d9cec'  } }
                                   onClick={ () => {this.setState( { summary: false } )} }>
                  Detailed
                </span>
                </span>
                :
                <span>
                  <span style={ { cursor: "pointer", color:'#5d9cec'  } } onClick={ () => {this.setState( { summary: true } )} }>
                    Summary </span> / Detailed
                </span>
            }
          </h5>
        </CardHeader>
        <CardBody>
          <Table responsive hover>
            <tbody>
            {
              invoices
                .filter( a => {
                  return a.due_date !== "DRAFT";
                } ).filter( a => {
                if ( this.state.thisQ ) {
                  return Math.floor( ( ( new Date( a.date * 1000 ) ).getMonth() + 3 ) / 3 ) === quarter && ( new Date( a.date * 1000 ) ).getUTCFullYear() === ( new Date() ).getUTCFullYear();
                } else {
                  return true;
                }
              } ).filter( a => {
                if ( !this.state.thisQ ) {
                  return Math.floor( ( ( new Date( a.date * 1000 ) ).getMonth() + 3 ) / 3 ) === lastQuarter && ( new Date( a.date * 1000 ) ).getUTCFullYear() === lastYear;
                } else {
                  return true;
                }
              } ).sort( ( a, b ) => b.date - a.date )
                .map( invoice => {
                  totalTaxInvoices = totalTaxInvoices + parseFloat( ( invoice.grand_total / 121 ) * 21 );
                  if ( this.state.summary ) {
                    return false;
                  } else {
                    return <tr key={ invoice.id }>
                      <td><Link to={ `/console/invoice/${ invoice.id }` }>{ invoice.id }</Link><br />
                      <small>{invoice.user.name}</small></td>
                      <td>&euro; { parseFloat( ( invoice.grand_total / 121 ) * 21 ).toFixed( 2 ) }<br/>
                        <small>&euro; { parseFloat( invoice.grand_total ).toFixed( 2 ) }</small>
                      </td>
                    </tr>
                  }
                } )
            }
            <tr>
              <td><b>Invoices</b></td>
              <td>&euro; { parseFloat( totalTaxInvoices ).toFixed( 2 ) }<br /><small>&euro; { parseFloat( (totalTaxInvoices / 21) * 121 ).toFixed( 2 ) } </small></td>
             </tr>
            {
              expenses
                .filter( a => {
                  if ( this.state.thisQ ) {
                    return Math.floor( ( ( new Date( a.date * 1000 ) ).getMonth() + 3 ) / 3 ) === quarter && ( new Date( a.date * 1000 ) ).getUTCFullYear() === ( new Date() ).getUTCFullYear();
                  } else {
                    return true;
                  }
                } ).filter( a => {
                if ( !this.state.thisQ ) {
                  return Math.floor( ( ( new Date( a.date * 1000 ) ).getMonth() + 3 ) / 3 ) === lastQuarter && ( new Date( a.date * 1000 ) ).getUTCFullYear() === lastYear;
                } else {
                  return true;
                }
              } ).sort( ( a, b ) => b.date - a.date )
                .map( expense => {
                  totalTaxExpenses = totalTaxExpenses + parseFloat( ( expense.price / 121 ) * 21 );
                  if ( this.state.summary ) {
                    return false;
                  } else {
                    return <tr key={ expense.id }>
                      <td>{ expense.name }<br/>
                        <small>{ expense.target.name }</small>
                      </td>
                      <td>&euro; { parseFloat( ( expense.price / 121 ) * 21 ).toFixed( 2 ) }<br/>
                        <small>&euro; { parseFloat( expense.price ).toFixed( 2 ) }</small>
                      </td>
                    </tr>
                  }
                } )
            }
            <tr>
              <td><b>Expenses</b></td>
              <td>&euro; { parseFloat( totalTaxExpenses ).toFixed( 2 ) } <br /><small>&euro; { parseFloat( (totalTaxExpenses / 21) * 121 ).toFixed( 2 ) } </small></td>
            </tr>
            <tr>
              <td><b>Total</b></td>
              <td>&euro; { parseFloat( totalTaxInvoices - totalTaxExpenses ).toFixed( 2 ) } </td>
            </tr>
            </tbody>
          </Table>


        </CardBody>
      </Card> );
  }
}

const mapStateToProps = ( state ) => {
  return {
    expenses: state.Taxes.expenses,
    invoices: state.Invoices
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { getExpenses, getExpense }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( QuarterOverview ) );
