import { LOGOUT } from '../actions/Auth';
import { CREATE_VM, GET_VMS, POWER_OFF_VM, POWER_ON_VM } from "../actions/Vms";

export default function Vms( state = {}, action ) {
  switch ( action.type ) {
    case GET_VMS.SUCCESS:
      let store = {};
      action.payload.map( vm => {
        store[ vm.id ] = vm;
        return true;
      } );
      return store;


    case CREATE_VM.SUCCESS:
      state[ action.payload.id ] = action.payload;
      return state;

    case POWER_ON_VM.SUCCESS:
      let vm1 = state[ action.payload ];
      vm1.state = 'up';
      state[ action.payload ] = vm1;
      return state;

    case POWER_OFF_VM.SUCCESS:
      let vm2 = state[ action.payload ];
      vm2.state = 'down';
      state[ action.payload ] = vm2;
      return state;


    case LOGOUT.SUCCESS:
      return {};

    default:
      return state;
  }
}
