import React from 'react';
import { Button, Col, Form, FormGroup } from 'reactstrap';
import { createNewMailbox } from "../../actions/Domains";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Card, CardBody, CardHeader } from "reactstrap";
import Input from "reactstrap/lib/Input";


class NewMailBoxPanel extends React.Component {

    constructor() {
        super();
        this.state = {
            address: "",
            createMailboxButton: { text: "Create", disabled: false, style: "primary" }
        };
        this.createMailbox = this.createMailbox.bind( this );
    }

    createMailbox(e) {
        e.preventDefault();
        this.setState( { createMailboxButton: { text: "Creating", disabled: true, style: "warning" } } );
        this.props.createNewMailbox( this.props.domain.id, this.state.address ).then( box => {
            this.setState( { address: "", createMailboxButton: { text: "Created", disabled: true, style: "success" } } );
            this.props.onChange(box);
            setTimeout( function() {
                this.props.history.push(`/console/mail/${this.props.domain.id}/box/${box.id}`);
            }.bind( this ), 100 );
        } ).catch( () => {
            this.setState( { createMailboxButton: { disabled: true, text: "Error", style: "danger" } } );
            setTimeout( function() {
                this.setState( { createMailboxButton: { text: "Create", disabled: false, style: "primary" } } );
            }.bind( this ), 5000 );
        } );
    }

    render() {
        const { createMailboxButton } = this.state;
        const { domain } = this.props;
        return (
            ( domain !== undefined ) &&
          <Card className="card-default">
            <CardHeader>
              <h3 style={ { display: 'inline' } }>Create mailbox</h3>
            </CardHeader>
            <CardBody>
              <Form className="form-horizontal" onSubmit={this.createMailbox}>
                <FormGroup>
                  <Col lg={ 2 }>
                    <label>Email</label>
                  </Col>
                  <Col lg={ 10 }>
                    <Input
                      onChange={ e => this.setState({address: e.target.value}) }
                      type="text" placeholder={`mail@${domain.name}.${domain.tld.name}`} />
                  </Col>
                </FormGroup>
                <Col lg={ 7 }>
                </Col>
                <Col lg={ 5 }>
                  <Button ref="createMailboxButton" onClick={ this.createMailbox }
                          className={ 'pull-right' }
                          disabled={ createMailboxButton.disabled }
                          color={ createMailboxButton.style }>
                    { createMailboxButton.text }
                  </Button>
                </Col>
              </Form>
            </CardBody>
          </Card>);
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { createNewMailbox }, dispatch )
};

export default connect( null, mapDispatchToProps )( withRouter( NewMailBoxPanel ) );
