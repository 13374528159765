import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Form, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUsers } from '../../actions/Users';
import { createTicket } from "../../actions/Tickets";

import $ from 'jquery';
import { meIsAdmin } from "../Common/Utils";
import Input from "reactstrap/lib/Input";
import UserSelect from "../User/UserSelect";
import Label from "reactstrap/lib/Label";
import StateButton from "../Common/StateButton";
import { withRouter } from "react-router";

class NewTicket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user_id: undefined,
            name: ""
        };

        this.handleCreateTicket = this.handleCreateTicket.bind( this );
        this.handleCustomerInput = this.handleCustomerInput.bind( this );
        this.userCreatedCallback = this.userCreatedCallback.bind( this );
        this.addNewContentRow = this.addNewContentRow.bind( this );

    }

    userCreatedCallback( user ) {
        this.setState( { user_id: user.id } );
        this.props.getUsers();
    }

    componentDidMount() {
        this.props.getUsers();

    }

    handleCustomerInput( event ) {
        const customer_id = event.target.value.trim();
        if ( customer_id === "new" ) {
            $( "#new_customer_invoice_form" ).slideDown( 200 );
        } else {
            $( "#new_customer_invoice_form" ).slideUp( 200 );
            this.setState( { user_id: customer_id } );
        }
    }

    addNewContentRow() {
        let contents = this.state.contents;
        contents.push( {
            id: contents.length + 1,
            quantity: 1,
            price: "0.00",
            total: "0.00"
        } );
        this.setState( {
            contents
        } );
    }

    handleCreateTicket() {
        return this.props.createTicket(
            this.state.user_id,
            this.state.name
        ).then( ticket => {
            this.props.history.push( '/console/ticket/' + ticket.id );
        } );
    }

    render() {
        const { users } = this.props;
        return (
            <Card className="card-default">
                <CardHeader>
                    <h3>Create ticket</h3>
                </CardHeader>
                <CardBody>
                    <Form className="form-horizontal">
                        <FormGroup>
                            <Col lg={ 2 }>
                                <Label>Subject</Label>
                            </Col>
                            <Col lg={ 12 }>
                                <Input
                                    type="text" className="form-control" placeholder={ 'Subject' }
                                    onChange={ e => this.setState( { name: e.target.value } ) }/>
                            </Col>
                        </FormGroup>
                        { meIsAdmin() &&
                        <FormGroup>
                            <Col sm={ 6 }>
                                <Label>User</Label>
                            </Col>
                            <Col sm={ 12 }>
                                <UserSelect users={ users } callback={ user_id => {
                                    this.setState( { user_id } )
                                } }/>
                            </Col>
                        </FormGroup>
                        }
                        <Col lg={ 7 }>
                        </Col>
                        <Col lg={ 5 }>
                            <StateButton runClick={ this.handleCreateTicket }
                                         texts={ [ 'Create', 'Creating', 'Created', 'Failed' ] }
                                         color="primary" />
                        </Col>
                    </Form>
                </CardBody>
            </Card>
        );
    }
}


const mapStateToProps = ( state ) => {
    return {
        users: state.Users
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { getUsers, createTicket }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter(NewTicket) );
