import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Col, Row, Table } from 'reactstrap';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUsers } from "../../actions/Users";
import Loader from "../Common/Loader.jsx";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { containsRule, meIsAdmin, searchArray, visibleTitle } from "../Common/Utils";
import { Link } from "react-router-dom";


class UserOverview extends React.Component {

  constructor() {
    super();
    this.state = { users: [] };
    this.handleSearch = this.handleSearch.bind( this );
  }

  handleSearch( event ) {
    searchArray( event.target.value, this.props.users, [
      ( n, c ) => {
        return containsRule( c.id.toLowerCase(), n )
      },
      ( n, c ) => {
        return containsRule( c.name.toLowerCase(), n )
      }
    ] );
    this.forceUpdate();
  }

  componentDidMount() {
    this.props.getUsers().then( users => {
      this.setState( { users } );
    } );
  }

  render() {
    const { history } = this.props;
    const { users } = this.state;

    const domains = Object.values( this.props.domains );
    return (
      <ContentWrapper>
        <div className="content-heading">
          Users
        </div>
        <Row>

          <Col md={ 8 }>
            { ( domains !== undefined ) ?
              <div>
                <Card className="card-default">
                  <CardHeader>
                    <h3 style={ { display: 'inline' } }>{ visibleTitle( users, [ "users", "user" ] ) }</h3>
                    { meIsAdmin() &&
                    <Button disabled onClick={ () => {
                      history.push( '/console/users/new' );
                    } } color="primary" className="pull-right">
                      <em className="fa fa-plus-circle fa-fw mr-sm"/>New user</Button>
                    }
                    <input placeholder="Search" type="text" autoComplete="off"
                           className="form-control pull-right" onChange={ this.handleSearch }/>
                  </CardHeader>

                  <CardBody>

                    <div style={ { marginTop: "0px" } } className="table-responsive">
                      { ( users.length !== 0 ) ?

                        <Table id="table-ext-1" responsive hover>
                          <thead>
                          <tr>
                            <th>ID</th>
                            <th>User</th>
                            <th>Email</th>
                            <th>Login</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            users.map( function( user ) {
                                if ( user.visible !== undefined && !user.visible ) {
                                  return true;
                                }
                                return <tr key={ user.id }>
                                  <td><Link to={ `/console/user/${ user.id }` }>{ user.id  }</Link></td>
                                  <td>{ user.name }</td>
                                  <td>{ user.email }</td>
                                  <td>{
                                    ( user.reference ) ?
                                      <div
                                        className="inline wd-xxs badge badge-success">
                                        Yes</div>
                                      :
                                      <div
                                        className="inline wd-xxs badge badge-warning">
                                        No</div>
                                  }</td>

                                </tr>
                              }
                            )

                          }
                          </tbody>
                        </Table>
                        :
                        <Loader/>
                      }
                    </div>
                  </CardBody>
                </Card>
              </div>
              :
              <div className="ball-scale-ripple-multiple"
                   style={ { marginTop: "70px", marginLeft: "48%" } }>
                <div style={ { borderColor: "#5d9cec" } }/>
                <div style={ { borderColor: "#5d9cec" } }/>
                <div style={ { borderColor: "#5d9cec" } }/>
              </div>
            }
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = ( state ) => {
  return {
    domains: state.Domains
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { getUsers }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( UserOverview );
