import React, { Component } from 'react';
import Timestamp from "react-timestamp";
import { withRouter } from "react-router";
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { meIsAdmin } from "../Common/Utils";

class TicketRow extends Component {
  state = {
    ticket: undefined
  };

  componentWillReceiveProps( nextProps, nextContext ) {
    this.setState( { ticket: nextProps.ticket } );
  }

  componentDidMount( ) {
    if ( this.props.ticket ) {
      this.setState( { ticket: this.props.ticket } );
    }
  }

  renderTicketLink( id ) {
    return <Link to={ `/console/ticket/${ id }` }>{ id }</Link>;
  }

  renderUserLink( { id, name } ) {
    return <Link to={ `/console/user/${ id }` }>{ name }</Link>;
  }

  render() {
    const { ticket } = this.state;

    if ( ticket === undefined ) return null;

    return (
      <tr key={ ticket.id }>
        <td> { this.renderTicketLink( ticket.id ) } </td>

        { this.props.nameCol &&
        <td> { ticket.name } {meIsAdmin() && <i><small><br />Opened by { this.renderUserLink( ticket.user ) }</small></i>}</td>
        }
        { this.props.dateCol &&
        <td><Timestamp date={ ticket.date } options={ { format: "date" } }/></td>
        }
        { this.props.statusCol &&
        <td>
          {
            ( ticket.status === 1 ) ?
              <Badge color={ 'success wd-xxs' }>Closed</Badge>
              :
              <Badge color={ 'danger wd-xxs' }>Open</Badge>
          }
        </td>
        }

      </tr>
    );


  }
}

export default withRouter( TicketRow );
