import React from 'react';
import Header from "./Header";
import './frontpage.scss';

class Base extends React.Component {

  render() {
    return (

      <div className="wrapper frontpage ">
        <Header />

        { this.props.children }
      </div>
    );
  }

}

export default Base;
