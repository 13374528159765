import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { getTickets } from "../../actions/Tickets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {  Table, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Loader from "../Common/Loader";
import {
    containsRule, countVisibles, isInitialized, NewSearch
} from "../Common/Utils";
import NoResults from "../Common/NoResults";
import TicketRow from "./TicketRow";
import SearchBar from "../Common/SearchBar";
import { firstBy } from "thenby";
import NewTicket from "./NewTicket";


class TicketsOverview extends React.Component {

    constructor( props ) {
        super( props );
        this.search = NewSearch( [
            ( n, c ) => {
                return containsRule( c.id.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.name.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.user.name.toLowerCase(), n )
            }
        ], () => Object.values( this.props.tickets ).sort(
            firstBy( function ( v1, v2 ) {
                return v2.status - v1.status;
            }, -1 )
                .thenBy( function ( v1, v2 ) {
                    return v1.date - v2.date;
                }, -1 )
            )
        );
        this.search.onUpdate( () => {
            this.forceUpdate()
        } );

    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.search.refresh();
    }

    componentDidMount() {
        this.props.getTickets();
        this.search.refresh();
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    Tickets
                </div>
                <Row>
                    <Col lg={ 9 }>
                        <Card className="card-default">
                            <CardHeader>
                                <h3 style={ { display: 'inline' } }>{ this.search.printVisibles( [ "tickets", "ticket" ] ) }</h3>
                                <SearchBar search={ this.search }/>
                            </CardHeader>
                            <CardBody>
                                { isInitialized( this.search.getVisibles() ) ?
                                    <div>
                                        <Table responsive hover>
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.search.getVisibles()
                                                    .map( ticket => <TicketRow key={ ticket.id } ticket={ ticket }
                                                                               userCol={ true } nameCol={ true }
                                                                               dateCol={ true } statusCol={ true }/>
                                                    )
                                            }
                                            </tbody>
                                        </Table>
                                        { countVisibles( this.search.getVisibles() ) === 0 &&
                                        <NoResults/>
                                        }
                                    </div>
                                    :
                                    <Loader/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3}>
                        <NewTicket />
                    </Col>
                </Row>
            </ContentWrapper>);
    }
}

const mapStateToProps = ( state ) => {
    return {
        tickets: state.Tickets
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { getTickets }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( TicketsOverview );
