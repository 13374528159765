import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Badge } from 'reactstrap';
import SidebarRun from './Sidebar.run';
import SidebarUserBlock from './SidebarUserBlock';
import connect from "react-redux/es/connect/connect";
import { meIsAdmin } from "../Common/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEnvelope,
  faFileAlt,
  faGlobe,
  faHdd,
  faStopwatch,
  faTachometerAlt,
  faCalculator,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { faDatabase } from "@fortawesome/free-solid-svg-icons/faDatabase";
import { faServer } from "@fortawesome/free-solid-svg-icons/faServer";

const SidebarItem = ( { item, isActive } ) => (
  <li className={ isActive ? 'active' : '' }>
    <Link to={ "/" + item.path } title={ item.name }>
      { item.label && <Badge tag="div" className="pull-right" color={ item.label.color }>{ item.label.value }</Badge> }
      { item.icon && <FontAwesomeIcon style={ { width: '20px', display: 'inline-block' } } icon={ item.icon }/> }
      { item.img && <img alt="" src={item.img} style={ { marginTop: "-2px", marginLeft: "-4px",marginRight: "4px", width: '24px', height: '24px', display: 'inline-block' } } /> }
      <span>{ item.name }</span>
    </Link>
  </li>
);

class Sidebar extends Component {

  state = {
    route: "/console/dashboard",
    collapse: {},
    open: 0,
    unpaid: 0
  };

  componentDidMount() {
    SidebarRun( this.navigator.bind( this ) );
    this.setState( { route: this.props.history.location.pathname } );
    this.props.history.listen( ( location, action ) => {
      this.setState( { route: location.pathname } );
    } );
  }

  navigator( route ) {
    this.props.history.push( route );
  }

  routeActive( paths ) {
    paths = Array.isArray( paths ) ? paths : [ paths ];
    if ( paths.indexOf( this.state.route.replace( '/', '' ) ) > -1 )
      return true;
    return false;
  }

  toggleItemCollapse( stateName ) {
    for ( let c in this.state.collapse ) {
      if ( this.state.collapse[ c ] === true && c !== stateName )
        this.setState( {
          collapse: {
            [ c ]: false
          }
        } );
    }
    this.setState( {
      collapse: {
        [ stateName ]: !this.state.collapse[ stateName ]
      }
    } );
  }


  render() {
    let unpaid = 0;
    let openTickets = 0;
    Object.values( this.props.invoices ).map( invoice => {
      if ( invoice.due_date !== "DRAFT" && invoice.grand_total - invoice.payments > 0 ) {
        unpaid = unpaid + 1
      }
      return true;
    } );
    Object.values( this.props.tickets ).map( ticket => {
      if ( ticket.status === 0 ) {
        openTickets = openTickets + 1
      }
      return true;
    } );

    let Menu = [
      {
        name: 'Dashboard',
        icon: faTachometerAlt,
        path: 'console/dashboard'
      },
      {
        name: 'Domains',
        icon: faGlobe,
        path: 'console/domains'
      },
      {
        name: 'Volumes',
        icon: faHdd,
        path: 'console/volumes'
      },
      {
        name: 'Mail',
        icon: faEnvelope,
        path: 'console/mail'
      },
      {
        name: 'Databases',
        icon: faDatabase,
        path: 'console/databases'
      },
      {
        name: 'Vms',
        icon: faServer,
        path: 'console/vms'
      },
      {
        name: 'Webcrons',
        icon: faStopwatch,
        path: 'console/webcrons'
      },
      // {
      //   name: 'Wallet',
      //   icon: 'icon-wallet',
      //   path: 'console/wallet'
      // },
      {
        name: 'Invoices',
        icon: faFileAlt,
        path: 'console/invoices',
        label: { value: unpaid, color: 'danger' }
      },
      {
        name: 'Tickets',
        icon: faEdit,
        path: 'console/tickets',
        label: { value: openTickets, color: 'danger' }
      }
    ];

    if ( meIsAdmin() ) {
      Menu.push( {
        name: 'Taxes',
        icon: faCalculator,
        path: 'console/taxes'
      } );
      Menu.push( {
        name: 'Users',
        icon: faUsers,
        path: 'console/users'
      } );
    }

    return (
      <aside
        className={ ( this.props.layout.sidebar !== undefined && !this.props.layout.sidebar ) ? 'aside-container aside-container-hidden' : 'aside-container' }>
        <div className="aside-inner">
          <nav data-sidebar-anyclick-close="" className="sidebar">
            <ul className="sidebar-nav">
              <li className="has-user-block">
                <SidebarUserBlock/>
              </li>
              {
                Menu.map( ( item, i ) => {
                  return (
                    <SidebarItem isActive={ this.routeActive( item.path ) } item={ item } key={ i }/>
                  );
                } )
              }
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = ( state ) => {
  return {
    user: state.UserInfo,
    invoices: state.Invoices,
    tickets: state.Tickets,
    layout: state.Layout
  }
};

export default connect( mapStateToProps )( withRouter( Sidebar ) );
