import React from 'react';
import { getExpenses, getExpense } from "../../actions/Taxes";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import Timestamp from "react-timestamp";
import { Table, Card, CardBody, CardHeader, Button } from "reactstrap";
import Loader from "../Common/Loader";
import { containsRule, countVisibles, isInitialized, NewSearch } from "../Common/Utils";
import NoResults from "../Common/NoResults";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons/faPaperclip";
import SearchBar from "../Common/SearchBar";

class ExpensesOverview extends React.Component {

    constructor( props ) {
        super( props );

        this.search = NewSearch( [
            ( n, c ) => {
                return containsRule( c.id.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.name.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.target.name.toLowerCase(), n )
            }
        ], () => Object.values( this.props.expenses || [] ) );
        this.search.onUpdate( () => this.forceUpdate() )

        this.openAttachment = this.openAttachment.bind( this );
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.search.refresh();
    }

    componentDidMount() {
        this.props.getExpenses();
        this.search.refresh();
    }

    openAttachment = expenseId => {
        let tab = window.open( '', '_blank' );
        this.props.getExpense( expenseId ).then( expense => {
            tab.location.href = expense.attachment.data;
        } );
    };

    render() {
        const { history } = this.props;
        return (
            <Card className="card-default">
                <CardHeader>
                    <h3 style={ { display: 'inline' } }>{ this.search.printVisibles( [ "expenses", "expense" ] ) }</h3>
                    <Button onClick={ () => {
                        history.push( '/console/taxes/new-expense' );
                    } } color="primary" className="pull-right">
                        <em className="fa fa-plus-circle fa-fw mr-sm"/>New Expense</Button>
                    <SearchBar search={ this.search }/>
                </CardHeader>
                <CardBody>
                    { isInitialized( this.search.getVisibles() ) ?
                        <div>
                            <Table responsive hover>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Target</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.search.getVisibles()
                                        .sort( ( a, b ) => b.date - a.date )
                                        .map( expense => {
                                            return <tr key={ expense.id }>
                                                <td>{ expense.id }</td>
                                                <td>{ expense.name }</td>
                                                <td>{ expense.target.name }</td>
                                                <td><Timestamp date={ expense.date } options={ { format: "date" } }/>
                                                </td>
                                                <td>&euro; { parseFloat( expense.price ).toFixed( 2 ) } </td>
                                                <td>
                                                    {
                                                        (expense.status === 1) ?
                                                            <div
                                                                className="inline wd-xxs badge badge-success">
                                                                Paid</div>
                                                            :
                                                            <div
                                                                className="inline wd-xxs badge badge-danger">
                                                                Open</div>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        (expense.has_attachment) &&
                                                        <FontAwesomeIcon
                                                            style={ { cursor: 'pointer', color: '#5d9cec' } }
                                                            onClick={ () => {
                                                                this.openAttachment( expense.id )
                                                            } } icon={ faPaperclip }/>
                                                    }
                                                </td>
                                            </tr>
                                        } )
                                }
                                </tbody>
                            </Table>
                            { countVisibles( this.search.getVisibles() ) === 0 &&
                            <NoResults/>
                            }
                        </div>
                        :
                        <Loader/>
                    }
                </CardBody>
            </Card>);
    }
}

const mapStateToProps = ( state ) => {
    return {
        expenses: state.Taxes.expenses
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { getExpenses, getExpense }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( ExpensesOverview ) );
