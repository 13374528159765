import React from 'react';
import { Input } from 'reactstrap';
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import InputGroup from "reactstrap/es/InputGroup";


class SearchBar extends React.Component {

    componentDidMount() {
        if (this.props.search) {
            this.props.search.onUpdate(() => {
                this.forceUpdate();
            });
        }
    }

    render() {
        return (
            <InputGroup className="pull-right" style={ { width: '250px' } }>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <FontAwesomeIcon style={ { width: '15px' } } icon={ faSearch }/>
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    value={ this.props.search && this.props.search.term }
                    onChange={ this.props.search && this.props.search.handleInput }
                    disabled={ !this.props.search }
                    placeholder="Search"
                    type="text"
                />
            </InputGroup>
        );
    }
}

export default SearchBar;
