import { createRequestTypes, action } from './Util';
import * as api from '../services/Api';

export const GET_DATABASES = createRequestTypes( 'GET_DATABASES' );
export const CREATE_DATABASE = createRequestTypes( 'CREATE_DATABASE' );
export const DELETE_DATABASE = createRequestTypes( 'DELETE_DATABASE' );

export const getDatabasesDispatchers = {
    request: () => action( GET_DATABASES.REQUEST ),
    success: ( databases ) => action( GET_DATABASES.SUCCESS, databases ),
    failure: ( error ) => action( GET_DATABASES.FAILURE, error ),
};
export const createDatabaseDispatchers = {
    request: () => action( CREATE_DATABASE.REQUEST ),
    success: ( database ) => action( CREATE_DATABASE.SUCCESS, database ),
    failure: ( error ) => action( CREATE_DATABASE.FAILURE, error ),
};
export const deleteDatabaseDispatchers = {
    request: () => action( DELETE_DATABASE.REQUEST ),
    success: ( result ) => action( DELETE_DATABASE.SUCCESS, result ),
    failure: ( error ) => action( DELETE_DATABASE.FAILURE, error ),
};

export function getDatabases() {
    return dispatch => {
        dispatch( getDatabasesDispatchers.request() );
        return api.getDatabases()
            .then( databases => {
                dispatch( getDatabasesDispatchers.success( databases ) );
                return databases;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( getDatabasesDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function createDatabase(user_id) {
    return dispatch => {
        dispatch( createDatabaseDispatchers.request() );
        return api.createDatabase(user_id)
            .then( database => {
                dispatch( createDatabaseDispatchers.success( database ) );
                return database;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( createDatabaseDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function deleteDatabase(id) {
    return dispatch => {
        dispatch( deleteDatabaseDispatchers.request() );
        return api.deleteDatabase(id)
            .then( result => {
                dispatch( deleteDatabaseDispatchers.success( result ) );
                return result;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( deleteDatabaseDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}
