import React from 'react';
import { Col, Form, FormGroup } from 'reactstrap';
import Loader from "../Common/Loader.jsx";
import { enableAutoReply, disableAutoReply } from "../../actions/Domains";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import Input from "reactstrap/lib/Input";
import StateButton from "../Common/StateButton";
import Row from "reactstrap/lib/Row";


class MailBoxAutoReplyPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "My name",
            subject: 'I\'m out of office',
            body: "Hello,\n\nThank you for your email. I’m out of the office and will be back at (Date of Return). During this period I will have limited access to my email.\n" +
                "For immediate assistance please contact me on my cell phone at (your cell phone number).\n" +
                "\n" +
                "Best Regards,\n" +
                "Me",
        };
        this.enableAutoReplySubmit = this.enableAutoReplySubmit.bind( this );
        this.disableAutoReplySubmit = this.disableAutoReplySubmit.bind( this );
        this.handleMessage = this.handleMessage.bind( this );
    }

    enableAutoReplySubmit() {
        return this.props.enableAutoReply( this.props.mailbox.domainId, this.props.mailbox.id, this.nameInput.value, this.subjectInput.value, this.state.body.replace( /(?:\r\n|\r|\n)/g, '<br>' ) ).then( result => {
            this.props.onOooChange( result.ooo );
        } );
    }

    handleMessage( event ) {
        this.setState( { body: event.target.value } );
    }

    disableAutoReplySubmit() {
        return this.props.disableAutoReply( this.props.mailbox.domainId, this.props.mailbox.id ).then( result => {
            this.props.onOooChange( result.ooo );
        } );
    }

    componentWillReceiveProps( props ) {
        if ( props.mailbox.ooo && props.mailbox.ooo.enabled ) {
            this.setState( {
                name: props.mailbox.ooo.name,
                subject: props.mailbox.ooo.subject,
                body: props.mailbox.ooo.body.replace( /<br\s*[/]?>/gi, "\n" )
            } );

        }
    }

    render() {
        const { mailbox } = this.props;

        return (
            <Card className="card-default">
                <CardHeader>
                    Auto Reply { (mailbox.aliases !== undefined && mailbox.ooo !== undefined) ?
                    mailbox.ooo.enabled ?
                        <div
                            className="inline wd-xxs badge badge-success">
                            Enabled</div>
                        :
                        <div
                            className="inline wd-xxs badge badge-warning">
                            Disabled</div>
                    :
                    ""
                }
                </CardHeader>
                <CardBody>
                    { (mailbox.ooo !== undefined) ?
                        <Form className="form-horizontal">
                            <FormGroup>
                                <Col lg={ 10 }>
                                    <Input defaultValue={ this.state.name }
                                           innerRef={ input => this.nameInput = input }
                                           type="text" placeholder="Name"/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={ 10 }>
                                    <Input defaultValue={ this.state.subject }
                                           innerRef={ input => this.subjectInput = input }
                                           type="text" placeholder="Subject"/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={ 10 }>
                  <textarea defaultValue={ this.state.body }
                            onChange={ this.handleMessage } placeholder="Message..." style={ { marginBottom: "8px" } }
                            rows="5" className="form-control no-resize"/>
                                </Col>
                            </FormGroup>
                            <Row>
                                <Col lg={ 12 }>
                                    {
                                        mailbox.ooo.enabled ?
                                            <StateButton runClick={ this.enableAutoReplySubmit }
                                                         texts={ [ 'Update', 'Updating', 'Updated', 'Error' ] }
                                                         className={ 'pull-left' }/>
                                            :
                                            <StateButton runClick={ this.enableAutoReplySubmit }
                                                         texts={ [ 'Enable', 'Enabling', 'Enabled', 'Error' ] }
                                                         className={ 'pull-left' }/>
                                    }
                                    {
                                        mailbox.ooo.enabled &&
                                        <StateButton runClick={ this.disableAutoReplySubmit }
                                                     texts={ [ 'Disable', 'Disabling', 'Disabled', 'Error' ] }
                                                     initialColor={ 'danger' }
                                                     className={ 'pull-right' }/>
                                    }

                                </Col></Row>
                        </Form>
                        :
                        <Loader/>
                    }
                </CardBody>
            </Card>
        );
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { enableAutoReply, disableAutoReply }, dispatch )
};

export default connect( null, mapDispatchToProps )( MailBoxAutoReplyPanel );
