import React from 'react';
import { withRouter } from 'react-router';
import { Col, Row } from "reactstrap";

class Mail extends React.Component {

  render() {
    return (
      <div
        style={ { display: 'block', margin: '0', marginTop: '40px', width: '100%', height: '450px',paddingBottom: '30px' } }>
        <Row>
          <Col sm={ 1 }/>
          <Col sm={ 5 }>
            <h1 className={'triangle-title'}>Email configuration</h1>
          </Col>
          <Col sm={ 1 }>
          </Col>
          <Col sm={ 3 } style={{textAlign:'center'}}>
            <em className="fa fa-5x fa-envelope mb-lg"
                style={ { marginTop: '10px',marginBottom:'80px', fontSize: '150px'} }/>

          </Col>
        </Row>


        <div className="container container-lg" style={ { marginTop: '100px' } }><Row>
          <Col md={ 4 }>
            <div className="plan">
              <h3 className="plan-header">IMAP</h3>
              <ul className="plan-features" style={{textAlign: 'center'}}>
                <li><em className="fa fa-check"/>All ok</li>
                <hr />
                <li><small>Server:</small><br />imap.pushto.space</li>
                <hr />
                <li><small>Port:</small><br />993</li>
                <hr />
                <li><small>Encryption:</small><br />SSL</li>
                <hr />
                <li><small>Authentication:</small><br />Username & password</li>
              </ul>
            </div>
          </Col>
          <Col md={ 4 }>
            <div className="plan">
              <h3 className="plan-header">Webmail</h3>
              <ul className="plan-features" style={{textAlign: 'center'}}>
                <li><em className="fa fa-check"></em>All ok</li>
                <hr />
                <li><small>Server:</small><br /><a href="https://rainloop.pushto.space">Rainloop.pushto.space</a></li>
                <hr />
                <li><small>Authentication:</small><br />Username & password</li>
              </ul>
            </div>
          </Col>
          <Col md={ 4 }>
            <div className="plan">
              <h3 className="plan-header">SMTP</h3>
              <ul className="plan-features" style={{textAlign: 'center'}}>
                <li><em className="fa fa-check"></em>All ok</li>
                <hr />
                <li><small>Server:</small><br />smtp.pushto.space</li>
                <hr />
                <li><small>Port:</small><br />587</li>
                <hr />
                <li><small>Encryption:</small><br />StartTLS</li>
                <hr />
                <li><small>Authentication:</small><br />Username & password</li>
              </ul>
            </div>
          </Col>
        </Row>
        </div>
      </div>
    );
  }

}

export default withRouter( Mail );
