import React from 'react';
import { Button } from 'reactstrap';


class StateButton extends React.Component {

    constructor( props ) {
        super( props );

        this.state = {
            texts: [ 'Run', 'Running', 'Ran', 'Fell' ],
            styles: [ 'primary', 'warning', 'success', 'danger' ],
            state: 0,
        };
        this.runClick = this.runClick.bind( this );
    }

    runClick( e ) {
        e.preventDefault();

        this.setState( { state: 1 } );
        let promise = this.props.runClick();
        promise.then( () => {
            this.setState( { state: 2 } );
        } ).catch( () => {
            this.setState( { state: 3 } );
        } ).finally( () => {
            setTimeout( function () {
                this.setState( { state: 0 } );
            }.bind( this ), 5000 );
        } )
    }

    componentDidMount() {
        if ( this.props.texts ) {
            this.setState( { texts: this.props.texts } );
        }
        if ( this.props.styles ) {
            this.setState( { styles: this.props.styles } );
        }
        if ( this.props.initialColor ) {
            let styles = this.state.styles;
            styles[ 0 ] = this.props.initialColor;
            this.setState( { styles } );
        }
    }

    render() {
        return (
            <Button onClick={ this.runClick }
                    className={ this.props.className ? this.props.className : "" }
                    disabled={ (this.state.state !== 0) ? true : (this.props.disabled ? this.props.disabled : this.state.disabled) }
                    color={ this.state.styles[ this.state.state ] }>
                { this.state.texts[ this.state.state ] }
            </Button>
        );
    }
}

export default StateButton;
