import React from 'react';
import Loader from "../Common/Loader.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import prettyBytes from "../Common/PrettyBytes";
import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import { containsRule, searchArray, visibleTitle } from "../Common/Utils";
import {getDomainMailBoxes} from "../../actions/Domains";
import {bindActionCreators} from "redux";
import { Link } from "react-router-dom";


class MailDomainBoxesPanel extends React.Component {

  constructor() {
    super();
    this.state = {
      boxes: undefined
    };
    this.handleSearch = this.handleSearch.bind( this );
  }

  componentDidMount() {
    if (this.props.domain !== undefined && this.state.boxes === undefined) {
      this.props.getDomainMailBoxes(this.props.domain.id).then(boxes => {
        this.setState({boxes});
      });
    }
  }
   componentDidUpdate(prevProps, prevState, prevContext) {
     if (this.props.domain !== undefined && this.state.boxes === undefined) {
       this.props.getDomainMailBoxes(this.props.domain.id).then(boxes => {
         this.setState({boxes});
       });
     }
   }

  handleSearch( event ) {
    searchArray( event.target.value, this.state.boxes, [
      ( n, c ) => {
        return containsRule( c.id.toLowerCase(), n )
      },
      ( n, c ) => {
        return containsRule( c.address.toLowerCase(), n )
      }
    ] );
    this.forceUpdate();
  }


  render() {
    const { history, domain } = this.props;
    const { boxes } = this.state;
    return (
      <Card className="card-default">
        <CardHeader>
          <h3 style={ { display: 'inline' } }>{ visibleTitle( boxes, [ "boxes", "box" ] ) }</h3>
          <Button disabled onClick={ () => {
            history.push( '/console/box/new' );
          } } color="primary" className="pull-right">
            <em className="fa fa-plus-circle fa-fw mr-sm"/>New box</Button>
          <input placeholder="Search" type="text" autoComplete="off"
                 className="form-control pull-right" onChange={ this.handleSearch }/>
        </CardHeader>
        <CardBody>
          { ( boxes !== undefined ) ?
            <Table responsive bordered hover>
              <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>Size</th>
              </tr>
              </thead>
              <tbody>
              {
                boxes.filter( box => ( box.visible === undefined || box.visible ) )
                  .map( box => {
                    return <tr key={ box.id }>
                      <td><Link to={ `/console/mail/${ domain.id }/box/${box.id}` }>{ box.id }</Link></td>
                      <td>{ box.address }
                      </td>
                      <td>
                        { ( box.size === 0 ) ?
                          <div
                            className="inline wd-xxs label label-info">
                            N/A
                          </div>
                          :
                          prettyBytes( box.size )
                        }
                      </td>

                    </tr>
                  } )

              }
              </tbody>
            </Table>
            :
            <Loader/>
          }
        </CardBody>
      </Card> );
  }
}

const mapStateToProps = ( state ) => {
  return {
    user: state.UserInfo
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { getDomainMailBoxes }, dispatch )
};


export default connect( mapStateToProps, mapDispatchToProps )( withRouter( MailDomainBoxesPanel ) );
