import React from 'react';
import Container from "reactstrap/es/Container";
import { Col, Row } from "reactstrap";
import KeyFeatureBlock from "./KeyFeatureBlock";
import {
    faChartLine,
    faLink,
    faLocationArrow,
    faLock,
    faShieldAlt,
    faUserSecret
} from "@fortawesome/free-solid-svg-icons";

class KeyFeatures extends React.Component {

    render() {
        return (
            <section className="section section-lg ">
                <Container className="pt-lg pb-300">
                    <Row className="text-center justify-content-center">
                        <Col lg="10">
                            <h2 className="display-3">Key features</h2>

                        </Col>
                    </Row>
                    <Row className="row-grid mt-5">
                        <Col lg="4">
                            <KeyFeatureBlock
                                icon={ faUserSecret }
                                color={ "warning" }
                                title={ "DDOS Mitigation" }
                                content={ <span>With our standard DDOS mitigation with a capacity of 10 Gbps, we are able to keep your services running without relying on third parties.</span> }/>
                        </Col>
                        <Col lg="4">
                            <KeyFeatureBlock
                                icon={ faLocationArrow }
                                color={ "success" }
                                title={ "First-class DNS" }
                                content={ <span>We run real-time nameservers to be able to load-balance before the requests even hit our networks. Downtime? <i>Not today</i></span> }/>
                        </Col>
                        <Col lg="4">
                            <KeyFeatureBlock
                                icon={ faShieldAlt }
                                color={ "danger" }
                                title={ "IDS & IPS" }
                                content={ <span>Your data is valuable, hackers know that too. We shut the doors in front of them as soon as we detect suspicious traffic going to your engines and notify you about the incident.</span> }/>
                        </Col>
                    </Row>
                    <Row className="row-grid mt-5">
                        <Col lg="4">
                            <KeyFeatureBlock
                                icon={ faLock }
                                color={ "success" }
                                title={ "SSL by default" }
                                content={ <span>Why should you pay extra for security? We don't know either. Everything we host is automatically secured by our high-performance SSL-negotiators.</span> }/>
                        </Col>
                        <Col lg="4">
                            <KeyFeatureBlock
                                icon={ faChartLine }
                                color={ "primary" }
                                title={ "Autonomous auto-scaling" }
                                content={ <span>When load rises on our services, our infrastructure is able to scale up autonomously within seconds. Your visitors won't even notice a thing.</span> }/>
                        </Col>
                        <Col lg="4">
                            <KeyFeatureBlock
                                icon={ faLink }
                                color={ "warning" }
                                title={ "Instant DC switchover" }
                                content={ <span>In case of an emergency we are able to switch over all the traffic to one of our
                                    backup locations, without your services going down.</span> }/>
                        </Col>
                    </Row>
                </Container>

            </section>
        );
    }

}

export default KeyFeatures;
