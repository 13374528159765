import { createRequestTypes, action } from './Util';
import * as api from '../services/Api';

export const GET_DOMAINS = createRequestTypes( 'GET_DOMAINS' );
export const GET_DOMAIN_AVAILABILTY = createRequestTypes( 'GET_DOMAIN_AVAILABILTY' );
export const GET_DOMAIN_MAILBOXES = createRequestTypes( 'GET_DOMAIN_MAILBOXES' );
export const GET_DOMAIN_MAILBOX = createRequestTypes( 'GET_DOMAIN_MAILBOX' );
export const CREATE_MAILBOX_ALIAS = createRequestTypes( 'CREATE_MAILBOX_ALIAS' );
export const DELETE_MAILBOX_ALIAS = createRequestTypes( 'DELETE_MAILBOX_ALIAS' );
export const UPDATE_MAILBOX_PASSWORD = createRequestTypes( 'UPDATE_MAILBOX_PASSWORD' );
export const CREATE_MAILBOX = createRequestTypes( 'CREATE_MAILBOX' );
export const GET_DOMAINS_CF_STATS = createRequestTypes( 'GET_DOMAINS_CF_STATS' );
export const REFRESH_DOMAIN_ENDPOINTS = createRequestTypes( 'REFRESH_DOMAIN_ENDPOINTS' );
export const ENABLE_AUTO_REPLY_MAILBOX = createRequestTypes( 'ENABLE_AUTO_REPLY_MAILBOX' );
export const DISABLE_AUTO_REPLY_MAILBOX = createRequestTypes( 'DISABLE_AUTO_REPLY_MAILBOX' );
export const ENABLE_DMARC = createRequestTypes( 'ENABLE_DMARC' );
export const DISABLE_DMARC = createRequestTypes( 'DISABLE_DMARC' );
export const GET_DMARC_REPORTS = createRequestTypes( 'GET_DMARC_REPORTS' );
export const GET_DOMAIN_DNS_RECORDS = createRequestTypes( 'GET_DOMAIN_DNS_RECORDS' );
export const GET_DKIM = createRequestTypes( 'GET_DKIM' );
export const ENABLE_DKIM = createRequestTypes( 'ENABLE_DKIM' );
export const DISABLE_DKIM = createRequestTypes( 'DISABLE_DKIM' );
export const GET_SPF = createRequestTypes( 'GET_SPF' );
export const ENABLE_SPF = createRequestTypes( 'ENABLE_SPF' );
export const DISABLE_SPF = createRequestTypes( 'DISABLE_SPF' );
export const CHECK_MX = createRequestTypes( 'CHECK_MX' );
export const Fix_MX = createRequestTypes( 'Fix_MX' );

export const checkMxDispatchers = {
    request: () => action( CHECK_MX.REQUEST ),
    success: ( status ) => action( CHECK_MX.SUCCESS, status ),
    failure: ( error ) => action( CHECK_MX.FAILURE, error ),
};
export const fixMxDispatchers = {
    request: () => action( Fix_MX.REQUEST ),
    success: ( result ) => action( Fix_MX.SUCCESS, result ),
    failure: ( error ) => action( Fix_MX.FAILURE, error ),
};
export const getSpfDispatchers = {
    request: () => action( GET_SPF.REQUEST ),
    success: ( status ) => action( GET_SPF.SUCCESS, status ),
    failure: ( error ) => action( GET_SPF.FAILURE, error ),
};
export const enableSpfDispatchers = {
    request: () => action( ENABLE_SPF.REQUEST ),
    success: ( result ) => action( ENABLE_SPF.SUCCESS, result ),
    failure: ( error ) => action( ENABLE_SPF.FAILURE, error ),
};
export const disableSpfDispatchers = {
    request: () => action( DISABLE_SPF.REQUEST ),
    success: ( result ) => action( DISABLE_SPF.SUCCESS, result ),
    failure: ( error ) => action( DISABLE_SPF.FAILURE, error ),
};
export const getDkimDispatchers = {
    request: () => action( GET_DKIM.REQUEST ),
    success: ( status ) => action( GET_DKIM.SUCCESS, status ),
    failure: ( error ) => action( GET_DKIM.FAILURE, error ),
};
export const enableDkimDispatchers = {
    request: () => action( ENABLE_DKIM.REQUEST ),
    success: ( result ) => action( ENABLE_DKIM.SUCCESS, result ),
    failure: ( error ) => action( ENABLE_DKIM.FAILURE, error ),
};
export const disableDkimDispatchers = {
    request: () => action( DISABLE_DKIM.REQUEST ),
    success: ( result ) => action( DISABLE_DKIM.SUCCESS, result ),
    failure: ( error ) => action( DISABLE_DKIM.FAILURE, error ),
};
export const getDomainsDispatchers = {
    request: () => action( GET_DOMAINS.REQUEST ),
    success: ( domains ) => action( GET_DOMAINS.SUCCESS, domains ),
    failure: ( error ) => action( GET_DOMAINS.FAILURE, error ),
};
export const getDomainDnsRecordsDispatchers = {
    request: () => action( GET_DOMAIN_DNS_RECORDS.REQUEST ),
    success: ( domains ) => action( GET_DOMAIN_DNS_RECORDS.SUCCESS, domains ),
    failure: ( error ) => action( GET_DOMAIN_DNS_RECORDS.FAILURE, error ),
};
export const refreshDomainEndpointsDispatchers = {
    request: () => action( REFRESH_DOMAIN_ENDPOINTS.REQUEST ),
    success: ( domains ) => action( REFRESH_DOMAIN_ENDPOINTS.SUCCESS, domains ),
    failure: ( error ) => action( REFRESH_DOMAIN_ENDPOINTS.FAILURE, error ),
};
export const getDomainCfStatsDispatchers = {
    request: () => action( GET_DOMAINS_CF_STATS.REQUEST ),
    success: ( domains ) => action( GET_DOMAINS_CF_STATS.SUCCESS, domains ),
    failure: ( error ) => action( GET_DOMAINS_CF_STATS.FAILURE, error ),
};
export const checkAvailabiltyDispatchers = {
    request: () => action( GET_DOMAIN_AVAILABILTY.REQUEST ),
    success: ( available ) => action( GET_DOMAIN_AVAILABILTY.SUCCESS, available ),
    failure: ( error ) => action( GET_DOMAIN_AVAILABILTY.FAILURE, error ),
};
export const createMailboxAliasDispatchers = {
    request: () => action( CREATE_MAILBOX_ALIAS.REQUEST ),
    success: ( alias ) => action( CREATE_MAILBOX_ALIAS.SUCCESS, alias ),
    failure: ( error ) => action( CREATE_MAILBOX_ALIAS.FAILURE, error ),
};
export const deleteMailboxAliasDispatchers = {
    request: () => action( DELETE_MAILBOX_ALIAS.REQUEST ),
    success: ( alias ) => action( DELETE_MAILBOX_ALIAS.SUCCESS, alias ),
    failure: ( error ) => action( DELETE_MAILBOX_ALIAS.FAILURE, error ),
};
export const getDomainMailboxesDispatchers = {
    request: () => action( GET_DOMAIN_MAILBOXES.REQUEST ),
    success: ( boxes ) => action( GET_DOMAIN_MAILBOXES.SUCCESS, boxes ),
    failure: ( error ) => action( GET_DOMAIN_MAILBOXES.FAILURE, error ),
};
export const getDomainMailboxDispatchers = {
    request: () => action( GET_DOMAIN_MAILBOX.REQUEST ),
    success: ( box ) => action( GET_DOMAIN_MAILBOX.SUCCESS, box ),
    failure: ( error ) => action( GET_DOMAIN_MAILBOX.FAILURE, error ),
};

export const updateMailBoxPasswordDispatchers = {
    request: () => action( UPDATE_MAILBOX_PASSWORD.REQUEST ),
    success: ( box ) => action( UPDATE_MAILBOX_PASSWORD.SUCCESS, box ),
    failure: ( error ) => action( UPDATE_MAILBOX_PASSWORD.FAILURE, error ),
};

export const createNewMailboxDispatchers = {
    request: () => action( CREATE_MAILBOX.REQUEST ),
    success: ( box ) => action( CREATE_MAILBOX.SUCCESS, box ),
    failure: ( error ) => action( CREATE_MAILBOX.FAILURE, error ),
};

export const disableAutoReplyMailboxDispatchers = {
    request: () => action( DISABLE_AUTO_REPLY_MAILBOX.REQUEST ),
    success: ( box ) => action( DISABLE_AUTO_REPLY_MAILBOX.SUCCESS, box ),
    failure: ( error ) => action( DISABLE_AUTO_REPLY_MAILBOX.FAILURE, error ),
};
export const enableAutoReplyMailboxDispatchers = {
    request: () => action( ENABLE_AUTO_REPLY_MAILBOX.REQUEST ),
    success: ( box ) => action( ENABLE_AUTO_REPLY_MAILBOX.SUCCESS, box ),
    failure: ( error ) => action( ENABLE_AUTO_REPLY_MAILBOX.FAILURE, error ),
};
export const enableDmarcDispatcher = {
    request: () => action( ENABLE_DMARC.REQUEST ),
    success: ( domain ) => action( ENABLE_DMARC.SUCCESS, domain ),
    failure: ( error ) => action( ENABLE_DMARC.FAILURE, error ),
};
export const disableDmarcDispatchers = {
    request: () => action( DISABLE_DMARC.REQUEST ),
    success: ( domain ) => action( DISABLE_DMARC.SUCCESS, domain ),
    failure: ( error ) => action( DISABLE_DMARC.FAILURE, error ),
};
export const getDmarcReportsDispatchers = {
    request: () => action( GET_DMARC_REPORTS.REQUEST ),
    success: ( domain ) => action( GET_DMARC_REPORTS.SUCCESS, domain ),
    failure: ( error ) => action( GET_DMARC_REPORTS.FAILURE, error ),
};

export function getDomains() {
    return dispatch => {
        dispatch( getDomainsDispatchers.request() );
        return api.getDomains()
            .then( domains => {
                dispatch( getDomainsDispatchers.success( domains ) );
                return domains;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( getDomainsDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function getDomainMailBoxes( domainId ) {
    return dispatch => {
        dispatch( getDomainMailboxesDispatchers.request() );
        return api.getDomainMailBoxes( domainId )
            .then( boxes => {
                dispatch( getDomainMailboxesDispatchers.success( boxes ) );
                return boxes;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( getDomainMailboxesDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function refreshDomainEndpoints( domainId ) {
    return dispatch => {
        dispatch( refreshDomainEndpointsDispatchers.request() );
        return api.refreshDomainEndpoints( domainId )
            .then( resp => {
                dispatch( refreshDomainEndpointsDispatchers.success( resp ) );
                return resp;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( refreshDomainEndpointsDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function getDomainCfStats( domainId ) {
    return dispatch => {
        dispatch( getDomainCfStatsDispatchers.request() );
        return api.getDomainCfStats( domainId )
            .then( boxes => {
                dispatch( getDomainCfStatsDispatchers.success( boxes ) );
                return boxes;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( getDomainCfStatsDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function getDomainDnsRecords( domainId ) {
    return dispatch => {
        dispatch( getDomainDnsRecordsDispatchers.request() );
        return api.getDomainDnsRecords( domainId )
            .then( records => {
                dispatch( getDomainDnsRecordsDispatchers.success( records ) );
                return records;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( getDomainDnsRecordsDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function checkAvailabilty( domain ) {
    return dispatch => {
        dispatch( checkAvailabiltyDispatchers.request() );
        return api.checkAvailabiltyDispatchers( domain )
            .then( resp => {
                dispatch( checkAvailabiltyDispatchers.success( resp ) );
                return resp;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( checkAvailabiltyDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function createNewMailboxAlias( domainId, mailboxId, alias ) {
    return dispatch => {
        dispatch( createMailboxAliasDispatchers.request() );
        return api.createNewMailboxAlias( domainId, mailboxId, alias )
            .then( alias => {
                dispatch( createMailboxAliasDispatchers.success( alias ) );
                return alias;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( createMailboxAliasDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function deleteMailboxAlias( domainId, mailboxId, aliasId ) {
    return dispatch => {
        dispatch( deleteMailboxAliasDispatchers.request() );
        return api.deleteMailboxAlias( domainId, mailboxId, aliasId )
            .then( alias => {
                dispatch( deleteMailboxAliasDispatchers.success( alias ) );
                return alias;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( deleteMailboxAliasDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function getMailBox( domainId, mailboxId ) {
    return dispatch => {
        dispatch( getDomainMailboxDispatchers.request() );
        return api.getDomainMailBox( domainId, mailboxId )
            .then( box => {
                dispatch( getDomainMailboxDispatchers.success( box ) );
                return box;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( getDomainMailboxDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function updateMailBoxPassword( domainId, mailboxId, password ) {
    return dispatch => {
        dispatch( updateMailBoxPasswordDispatchers.request() );
        return api.updateMailboxPassword( domainId, mailboxId, password )
            .then( box => {
                dispatch( updateMailBoxPasswordDispatchers.success( box ) );
                return box;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( updateMailBoxPasswordDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function createNewMailbox( domainId, address ) {
    return dispatch => {
        dispatch( createNewMailboxDispatchers.request() );
        return api.createMailbox( domainId, address )
            .then( box => {
                dispatch( createNewMailboxDispatchers.success( box ) );
                return box;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( createNewMailboxDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function enableAutoReply( domainId, mailboxId, name, subject, body ) {
    return dispatch => {
        dispatch( enableAutoReplyMailboxDispatchers.request() );
        return api.enableMailboxAutoReply( domainId, mailboxId, name, subject, body )
            .then( box => {
                dispatch( enableAutoReplyMailboxDispatchers.success( box ) );
                return box;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( enableAutoReplyMailboxDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function disableAutoReply( domainId, mailboxId ) {
    return dispatch => {
        dispatch( disableAutoReplyMailboxDispatchers.request() );
        return api.disableMailboxAutoReply( domainId, mailboxId )
            .then( box => {
                dispatch( disableAutoReplyMailboxDispatchers.success( box ) );
                return box;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( disableAutoReplyMailboxDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function enableDmarc( domainId ) {
    return dispatch => {
        dispatch( enableDmarcDispatcher.request() );
        return api.enableDomainDmarc( domainId )
            .then( () => {
                dispatch( enableDmarcDispatcher.success( domainId ) );
                return domainId;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( enableDmarcDispatcher.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function disableDmarc( domainId ) {
    return dispatch => {
        dispatch( disableDmarcDispatchers.request() );
        return api.disableDomainDmarc( domainId )
            .then( () => {
                dispatch( disableDmarcDispatchers.success( domainId ) );
                return domainId;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( disableDmarcDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function getDmarcReports( domainId ) {
    return dispatch => {
        dispatch( getDmarcReportsDispatchers.request() );
        return api.getDmarcReports( domainId )
            .then( reports => {
                dispatch( getDmarcReportsDispatchers.success( domainId ) );
                return reports;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( getDmarcReportsDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}
export function getDkim( domainId ) {
    return dispatch => {
        dispatch( getDkimDispatchers.request() );
        return api.getDkim( domainId )
            .then( status => {
                dispatch( getDkimDispatchers.success( status ) );
                return status;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( getDkimDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function enableDkim( domainId ) {
    return dispatch => {
        dispatch( enableDkimDispatchers.request() );
        return api.enableDkim( domainId )
            .then( result => {
                dispatch( enableDkimDispatchers.success( result ) );
                return result;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( enableDkimDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}
export function disableDkim( domainId ) {
    return dispatch => {
        dispatch( disableDkimDispatchers.request() );
        return api.disableDkim( domainId )
            .then( result => {
                dispatch( disableDkimDispatchers.success( result ) );
                return result;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( disableDkimDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function getSpf( domainId ) {
    return dispatch => {
        dispatch( getSpfDispatchers.request() );
        return api.getSpf( domainId )
            .then( status => {
                dispatch( getSpfDispatchers.success( status ) );
                return status;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( getSpfDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function enableSpf( domainId ) {
    return dispatch => {
        dispatch( enableSpfDispatchers.request() );
        return api.enableSpf( domainId )
            .then( result => {
                dispatch( enableSpfDispatchers.success( result ) );
                return result;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( enableSpfDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}
export function disableSpf( domainId ) {
    return dispatch => {
        dispatch( disableSpfDispatchers.request() );
        return api.disableSpf( domainId )
            .then( result => {
                dispatch( disableSpfDispatchers.success( result ) );
                return result;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( disableSpfDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function checkMx( domainId ) {
    return dispatch => {
        dispatch( checkMxDispatchers.request() );
        return api.checkMx( domainId )
            .then( status => {
                dispatch( checkMxDispatchers.success( status ) );
                return status;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( checkMxDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}

export function fixMx( domainId ) {
    return dispatch => {
        dispatch( fixMxDispatchers.request() );
        return api.fixMx( domainId )
            .then( result => {
                dispatch( fixMxDispatchers.success( result ) );
                return result;
            } )
            .catch( resp => {
                const msg = resp.message;
                dispatch( fixMxDispatchers.failure( msg ) );
                return Promise.reject( msg );
            } );
    };
}
