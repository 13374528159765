import React, { Component } from 'react';
import { Button, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentWrapper from '../Layout/ContentWrapper';
import Dropzone from 'react-dropzone';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import NewTargetForm from "./NewTargetForm";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import { FormGroup } from "reactstrap/lib/index";
import moment from "moment";
import { createNewExpense } from "../../actions/Taxes";
import AsyncSelect from "react-select/async/dist/react-select.esm";

class NewExpense extends Component {

  constructor() {
    super();
    this.state = {
      creatingNewTarget: 'none',
      target_id: undefined,
      target_name: undefined,
      addExpenseButton: { text: "Add", disabled: false, style: "primary" }
    };

    this.handleCreateExpense = this.handleCreateExpense.bind( this );
    this.handleUserInput = this.handleUserInput.bind( this );
    this.targetCreatedCallback = this.targetCreatedCallback.bind( this );
    this.handleTargetNameInput = this.handleTargetNameInput.bind( this );
    this.validateInput = this.validateInput.bind( this );
    this.onDrop = this.onDrop.bind( this );

    this.expense_name = React.createRef();
    this.expense_date = React.createRef();
    this.expense_price = React.createRef();
  }

  targetCreatedCallback( target ) {
    this.setState( { creatingNewTarget: "none", target_id: target.id } );
  }

  onDrop( files ) {
    let reader = new FileReader();
    reader.onload = event => {
      let fd = {
        name: files[ 0 ].name,
        type: files[ 0 ].type,
        data: event.target.result
      };
      this.setState( {
        attachment: fd
      } );
    };
    reader.readAsDataURL( files[ 0 ] );

  }

  componentDidMount() {
  }

  handleTargetNameInput( val ) {
    this.setState( { target_name: val } );
    this.validateInput();
  }

  validateInput() {

  }

  handleCreateExpense( e ) {
    e.preventDefault();
    this.setState( { addExpenseButton: { text: "Adding...", disabled: true, style: "warning" } } );
    this.props.createNewExpense(
      this.state.target_id,
      this.state.target_name,
      this.expense_name.value,
      moment( this.refs.expense_date.state.inputValue ).unix(),
      this.expense_price.value,
      this.state.attachment
    ).then( () => {
      this.props.history.push( '/console/taxes/' );
      setTimeout( function() {
        this.setState( { addExpenseButton: { text: "Add", disabled: false, style: "primary" } } );
      }.bind( this ), 5000 );
    } ).catch( () => {
      this.setState( { addExpenseButton: { disabled: true, text: "Error", style: "danger" } } );
      setTimeout( function() {
        this.setState( { addExpenseButton: { text: "Add", disabled: false, style: "primary" } } );
      }.bind( this ), 5000 );
    } );
  }

  handleUserInput( input ) {
    if ( input.value === "NEW" ) {
      this.setState( { target_id: "NEW" } );
      this.setState( { creatingNewTarget: "block" } );
    } else {
      this.setState( { creatingNewTarget: "none" } );
      this.setState( { target_id: input.value } );
    }
  }

  render() {
    const { addExpenseButton } = this.state;
    const targets = Object.values( this.props.targets );
    return (
      <ContentWrapper>
        <div className="content-heading">
          Create expense
        </div>
        <div className="form-horizontal">
          <fieldset>
            <div className="form-group">
              <Col sm={ 10 }>
                <label className="col-sm-6 control-label">Target name</label>
                { ( targets !== undefined && targets instanceof Array ) ?
                    <AsyncSelect
                        loadOptions={ ( input, update ) => {
                          targets.push({id:"NEW", name:"New target"});
                          update( targets.filter( target => {
                            return target.name.toLowerCase().includes( input.toLowerCase() );
                            // return true;
                          } ).map( target => {
                            return {
                              value: target.id,
                              label: target.name
                            }
                          } ) );
                        } }
                        defaultOptions
                        onChange={ this.handleUserInput }
                    />
                  :
                  <label className="col-sm-2 control-label">Loading targets...</label>
                }
              </Col>
            </div>
          </fieldset>

          <NewTargetForm ref="newUserForm" display={ this.state.creatingNewTarget }
                         callback={ this.handleTargetNameInput }/>

          <FormGroup>
            <Col sm={ 6 }>
              <label className="col-sm-2 control-label">Name</label>
              <Input type="text" placeholder="Name" innerRef={ input => ( this.expense_name = input ) }/>
            </Col>
          </FormGroup>

          <FormGroup>
            <Col sm={ 6 }>
              <label className="col-sm-2 control-label">Date</label>
              <Datetime timeFormat={ false } ref="expense_date" inputProps={ { className: 'form-control' } }/>
            </Col>
          </FormGroup>
          <FormGroup><Col md={ 6 }>
            <label className="col-sm-2 control-label">Price</label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">&euro;</InputGroupAddon>
              <Input placeholder="price" innerRef={ input => ( this.expense_price = input ) }/>
            </InputGroup>
          </Col>
          </FormGroup>
          <FormGroup>
            <Col md={ 6 }>
              <label className="col-sm-2 control-label">Attachment</label>
              <Dropzone onDrop={this.onDrop}>
                {({getRootProps, getInputProps}) => (
                    <section className="text-center box-placeholder m0">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drop the invoice here</p>
                        { this.state.attachment !== undefined &&
                        <span>{ this.state.attachment.name }</span>
                        }
                      </div>
                    </section>
                )}
              </Dropzone>
            </Col>
          </FormGroup>

          <fieldset>
            <div className="form-group">
              <div className="col-sm-4 col-sm-offset-2">
                <Button ref="addExpenseButton" onClick={ this.handleCreateExpense }
                        className={ 'btn-block' }
                        disabled={ addExpenseButton.disabled }
                        color={ addExpenseButton.style }>
                  { addExpenseButton.text }
                </Button>
              </div>
            </div>
          </fieldset>
        </div>
      </ContentWrapper>
    );
  }
}


const mapStateToProps = ( state ) => {
  return {
    targets: state.Taxes.targets
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { createNewExpense }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( NewExpense );
