import React from 'react';
import { Container } from "reactstrap";

class Hero extends React.Component {
    render() {
        return (
                <div className="position-relative">
                    {/* Hero for FREE version */}
                    <section className="section section-hero section-shaped">
                        {/* Background circles */}
                        <div className="shape shape-style-1 shape-default" style={{background: 'linear-gradient(150deg,#23b8e5 15%,#4ad5ff 70%,#16acd9 94%)'}}>
                            <span className="span-150" />
                            <span className="span-50" />
                            <span className="span-50" />
                            <span className="span-75" />
                            <span className="span-100" />
                            <span className="span-75" />
                            <span className="span-50" />
                            <span className="span-100" />
                            <span className="span-50" />
                            <span className="span-100" />
                        </div>
                        <Container style={{marginTop: '150px', paddingBottom: '100px'}} className="shape-container d-flex align-items-center py-lg">
                            {this.props.content}
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                                style={{marginBottom: '-7px'}}
                            >
                                <polygon
                                    style={{fill:'#f5f7fa'}}
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </section>
                </div>
        );
    }
}

export default Hero;
