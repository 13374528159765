import React from 'react';
import Loader from "../Common/Loader.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { containsRule, isInitialized, NewSearch } from "../Common/Utils";
import { Link } from "react-router-dom";
import SearchBar from "../Common/SearchBar";
import NoResults from "../Common/NoResults";


class MailDomainsPanel extends React.Component {

    constructor( props ) {
        super( props );

        this.search = NewSearch( [
            ( n, c ) => {
                return containsRule( c.id.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.name.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.user.name.toLowerCase(), n )
            }
        ], () => Object.values( this.props.domains ).filter( a => {
            return a.mail !== ""
        } ) );
        this.search.onUpdate( () => this.forceUpdate() )
    }

    renderProvider( provider ) {
        switch ( provider ) {
            case "local":
                return (<span><img src={ '/img/logo_colored.png' } style={ { marginRight: '10px', height: '15px' } }
                                   alt={ "" }/>PushTo.Space</span>);
            case "google":
                return (
                    <span><img src={ '/img/google.png' } style={ { marginRight: '10px', height: '15px' } } alt={ "" }/>Google</span>);
            default:
                return (<span>Unknown</span>);
        }
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.search.refresh();
    }
    componentDidMount() {
        this.search.refresh();
    }

    render() {
        const { user } = this.props;
        return (
            <Card className="card-default">
                <CardHeader>
                    <h3 style={ { display: 'inline' } }>{this.search.printVisibles([ "domains", "domain" ] ) }</h3>
                    <SearchBar search={ this.search }/>
                </CardHeader>

                <CardBody>
                    { (isInitialized( this.search.getVisibles() )) ?
                        <Table responsive hover>
                            <thead>
                            <tr>
                                <th>ID</th>
                                { user.admin &&
                                <th>User</th>
                                }
                                <th>Domain</th>
                                <th>Provider</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.search.getVisibles().map( domain => {
                                        return <tr key={ domain.id }>
                                            <td><Link to={ `/console/mail/${ domain.id }` }>{ domain.id }</Link></td>
                                            { (user.admin) &&
                                            <td><Link
                                                to={ `/console/user/${ domain.user.id }` }>{ domain.user.name }</Link>
                                            </td>
                                            }
                                            <td>{ domain.name }.{ domain.tld.name }</td>
                                            <td>{ this.renderProvider( domain.mail ) }</td>

                                        </tr>
                                    }
                                )
                            }
                            </tbody>

                        </Table>
                        :
                        <Loader/>
                    }
                    {
                        this.search.getVisibles().length === 0 &&
                        <NoResults/>
                    }
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        user: state.UserInfo,
        domains: state.Domains
    }
};


export default connect( mapStateToProps )( withRouter( MailDomainsPanel ) );
