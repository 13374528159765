import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import setAuth from '../middleware/SetAuthToken';
import authTokenRefreshInterceptor from '../middleware/AuthTokenRefreshInterceptor';
import { loadState, saveState } from './LocalStorage';
import rootReducer from '../reducers/Index';
import Api from './Api';

const persistedState = loadState();

let middleWares = [
    thunkMiddleware,
    authTokenRefreshInterceptor( Api ),
    setAuth( Api )
];

if ( process.env.NODE_ENV !== 'production' ) {
    const loggerMiddleware = createLogger( { collapsed: true } );
    middleWares = [ ...middleWares, loggerMiddleware ];
}

const store = createStore(
    rootReducer,
    persistedState,
    applyMiddleware(
        ...middleWares
    )
);

store.subscribe( () => {
    saveState( store.getState() );
} );

export default store;