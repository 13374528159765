import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ContentWrapper from '../Layout/ContentWrapper';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from "redux";
import { getVolumes } from "../../actions/Volumes";
import VolumeDetailsPanel from "./VolumeDetailsPanel";
import VolumeFtpPanel from "./VolumeFtpPanel";
import VolumeDeploymentsPanel from "./VolumeDeploymentsPanel";
import VolumeSizePanel from "./VolumeSizePanel";

class Volume extends React.Component {

  state = { volume: undefined };

  componentDidMount() {
    this.setState( {
      volume: this.props.volumes[ this.props.match.params.volumeId ]
    } );
  }


  render() {
    const { volume } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          Volume #{ this.props.match.params.volumeId }
        </div>
        <Row>
          <Col md={ 6 }>
            <VolumeDetailsPanel volume={ volume }/>
            <VolumeSizePanel volume={ volume }/>
          </Col>
          <Col md={ 6 }>
            <VolumeFtpPanel volume={ volume }/>
            <VolumeDeploymentsPanel volume={ volume }/>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

function mapDispatchToProps( dispatch ) {
  return bindActionCreators( { getVolumes }, dispatch )
}

const mapStateToProps = ( state ) => {
  return {
    volumes: state.Volumes
  }
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( Volume ) );
