import { refreshAuthToken, fetchPtsClientToken } from '../actions/Auth';

const is401 = e => e && e.response && e.response.status === 401;
let refreshing = undefined;

const refreshToken = store => {
    return new Promise( ( resolve, reject ) => {
        store
            .dispatch( fetchPtsClientToken() )
            .then( clientToken => {
                store
                    .dispatch( refreshAuthToken( store.getState().Authentication.accessToken, store.getState().Authentication.refreshToken, clientToken ) )
                    .then( token => {
                        resolve( token );
                        refreshing = undefined
                    } ).catch( err => {
                    reject( err );
                    refreshing = undefined
                } );
            } ).catch( err => {
            reject( err );
            refreshing = undefined
        } );
    } );
}

const refreshAuthTokenAndRetry = ( api, store, failedRequest ) => {
    if ( !refreshing ) {
        refreshing = refreshToken( store );
    }
    refreshing.then( token => {
        failedRequest.headers[ 'Authorization' ] = `Bearer pts:v1 ${ token }`; // eslint-disable-line
        return api.request( failedRequest );
    } )
};

export default function authTokenRefreshInterceptor( api ) {
    return store => {

        api.interceptors.response.use( resp => resp, error => {
            if ( !is401( error ) ) {
                return Promise.reject( error );
            }
            return refreshAuthTokenAndRetry( api, store, error.config )
        } );

        return next => action => next( action );
    };
}
