import React, {Component} from 'react';
import {Col, Row} from "reactstrap";

class NoResults extends Component {

    render() {
        return (
            <Row>
                { !this.props.big && <Col sm={4}/> }

                <Col sm={this.props.big ? 12 : 4} className="text-center">
                    <img alt="space" src="/img/logo_colored.png" style={{margin: '20px', width: this.props.big ? "100px" : '50%' }}/>
                    <h3>{ (this.props.title !== undefined) ? this.props.title : "Oops! No results..." }</h3>
                    <small>We are on our way to another galaxy to continue the search</small>

                </Col>
                { !this.props.big && <Col sm={4}/> }
            </Row>
        );
    }
}

export default NoResults;
