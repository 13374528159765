import React from 'react';
import Timestamp from "react-timestamp";
import Loader from "../Common/Loader.jsx";
import {
    Table,
    ButtonDropdown,
    Card,
    CardBody,
    CardHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import moment from "moment/moment";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
    updateInvoice
} from "../../actions/Invoices";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";


class InvoiceDetailsPanel extends React.Component {

    state = {
        setDueDateButton: { text: "TBD", style: "secondary" }
    };

    handleDueDateChange( event ) {
        this.setState( { setDueDateButton: { text: "Updating", style: "warning" } } );
        let due_date = moment().utc();
        due_date.add( event, 'days' );
        this.props.updateInvoice( { id: this.props.invoice.id, due_date: due_date.unix() } ).then( invoice => {
            this.props.updated( invoice );
        } );
    }

    toggleDD = dd => {
        this.setState( {
            [ dd ]: !this.state[ dd ]
        } )
    };

    render() {
        const { invoice, user } = this.props;
        return (
            <Card className="card-default">
                <CardHeader>
                    <h3>Details</h3>
                </CardHeader>
                <CardBody>
                    { (invoice !== undefined && invoice.user !== undefined) ?
                        <Table responsive hover>
                            <tbody>
                            <tr>
                                <td><strong>Created date</strong></td>
                                <td>
                                    { (invoice.due_date === "DRAFT" && user.admin) &&
                                    <Button
                                        onClick={ () => this.props.updateInvoice( {
                                            id: this.props.invoice.id,
                                            date: moment().unix()
                                        } ).then( invoice => {
                                            this.props.updated( invoice );
                                        } ) }
                                        color="primary"
                                        className="pull-right">
                                        <FontAwesomeIcon icon={ faClock } /></Button>
                                    }
                                    <Timestamp date={ invoice.date } options={ { format: "date" } }/>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Due date</strong></td>
                                <td>
                                    { (invoice.due_date === "DRAFT") ?
                                        (user.admin) ?
                                            <ButtonDropdown
                                                isOpen={ this.state[ `ddSingle` ] }
                                                onChange={ this.handleDueDateChange }
                                                toggle={ () => this.toggleDD( `ddSingle` ) } id={ `duedate_button` }>
                                                <DropdownToggle caret color={ this.state.setDueDateButton.style }>
                                                    { this.state.setDueDateButton.text }
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={ () => this.handleDueDateChange( 30 ) }>Set
                                                        +30 days</DropdownItem>
                                                    <DropdownItem onClick={ () => this.handleDueDateChange( 14 ) }>Set
                                                        +14 days</DropdownItem>
                                                    <DropdownItem onClick={ () => this.handleDueDateChange( 7 ) }>Set +7
                                                        days</DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                            :
                                            "TBD"
                                        :
                                        <Timestamp date={ invoice.due_date } options={ { format: "date" } }/>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>User</strong>
                                </td>
                                <td>
                                    <Link to={`/console/user/${ invoice.user.id }`} style={ { cursor: "pointer" } }>{ invoice.user.name }</Link>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                        :
                        <Loader/>
                    }
                </CardBody>
            </Card>

        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        user: state.UserInfo
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( {
        updateInvoice
    }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( InvoiceDetailsPanel ) );

