import React from 'react';
import { Button, Col, Form } from 'reactstrap';
import { createVm, getDisks, getImages } from "../../actions/Vms";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import {
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import UserSelect from "../User/UserSelect";
import StateButton from "../Common/StateButton";
import { meIsAdmin } from "../Common/Utils";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Switch from "react-switch";
import Row from "reactstrap/lib/Row";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import prettyBytes from "../Common/PrettyBytes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import Select from 'react-select'


class NewVmPanel extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            name: "",
            public_key: "",
            user_id: null,
            image: null,
            public_ip: false,
            cpus: 4,
            mem: 4,
            ports: [
                { protocol: "TCP", port: 22 }
            ],
            disks: [ { id: null, name: "disk1", size: 50, create: true } ],
            images: [],
            my_disks: []
        };
        this.createVm = this.createVm.bind( this );
    }

    componentDidMount() {
        this.props.getImages().then( images => {
            this.setState( { images } );
        } );
    }

    createVm() {
        let ports = this.state.ports.map(port=> {
            return port.protocol.toUpperCase() + "/" + port.port;
        });
        return this.props.createVm(
            this.state.name,
            this.state.image.value,
            this.state.public_ip,
            this.state.cpus,
            this.state.mem,
            this.state.disks,
            ports,
            this.state.public_key,
            this.state.user_id ).then( this.props.callback );
    }

    render() {
        const { users } = this.props;
        const { public_ip } = this.state;

        let total = this.state.cpus * 4 + this.state.mem * 2;
        this.state.disks.map( disk => {
            total = total + disk.size * 0.2;
            return true;
        } );
        this.state.ports.map( () => {
            total = total + 0.1;
            return true;
        } );
        if ( this.state.public_ip ) {
            total = total + 2.5
        } else {
            total = total + 0.1
        }
        return (
            <Card className="card-default">
                <CardHeader>
                    <h3 style={ { display: 'inline' } }>Create vm</h3>
                    <Button onClick={ () => {
                        this.props.close();
                    } } color="primary" className="pull-right">
                        <FontAwesomeIcon icon={ faTimes } style={ { marginRight: '5px' } }/>
                        Close
                    </Button>
                </CardHeader>
                <CardBody>
                    <Form className="form-horizontal">

                        <Row>
                            <Col lg={ 4 }>
                                <Label style={ { marginTop: '6px' } }>Name</Label></Col>
                            <Col lg={ 8 }><Input
                                type="text" className="form-control"
                                placeholder={ 'My server' }
                                onChange={ e => this.setState( { name: e.target.value } ) }/>
                            </Col>


                        </Row>
                        { meIsAdmin() &&
                        <Row style={ { marginTop: '30px' } }>
                            <Col sm={ 4 }>
                                <Label style={ { marginTop: '6px' } }>User</Label>
                            </Col>
                            <Col sm={ 8 }>
                                <UserSelect users={ users } callback={ user_id => {
                                    this.setState( { user_id } )
                                } }/>
                            </Col>
                        </Row>
                        }
                        <Row style={ { marginTop: '30px' } }>
                            <Col sm={ 4 }>
                                <Label style={ { marginTop: '6px' } }>Image</Label>
                            </Col>
                            <Col sm={ 8 }>
                                <AsyncSelect
                                    loadOptions={ ( input, update ) => {
                                        if ( this.state.images.length === 0 ) {
                                            this.props.getImages().then( images => {
                                                update( images.filter( image => {
                                                    if ( input === "" ) {
                                                        return true;
                                                    }
                                                    return image.name.toLowerCase().includes( input.toLowerCase() );
                                                } ).map( user => {
                                                    return {
                                                        value: user.id,
                                                        label: user.name
                                                    }
                                                } ) )
                                            } )
                                        } else {
                                            update( this.state.images.filter( image => {
                                                if ( input === "" ) {
                                                    return true;
                                                }
                                                return image.name.toLowerCase().includes( input.toLowerCase() );
                                            } ).map( user => {
                                                return {
                                                    value: user.id,
                                                    label: user.name
                                                }
                                            } ) )
                                        }

                                    } }
                                    defaultOptions
                                    onChange={ ( newValue ) => {
                                        this.setState( { image: newValue } )
                                        return newValue;
                                    } }
                                />
                            </Col>
                        </Row>
                        <Row style={ { marginTop: '30px' } }>

                            <Col lg={ 4 }><span style={ { marginTop: '6px' } }>Public IP</span>
                            </Col>
                            <Col lg={ 8 }><Switch onChange={ () => this.setState( { public_ip: !public_ip } ) }
                                                  checked={ public_ip }/>
                            </Col>
                        </Row>
                        <Row style={ { marginTop: '30px' } }>
                            <Col lg={ 4 }>{ this.state.cpus } Cpus</Col>
                            <Col lg={ 8 }><Slider onChange={ ( val ) => {
                                this.setState( { cpus: val } );
                            } } min={ 1 } max={ 8 } defaultValue={ this.state.cpus }/>
                            </Col>


                        </Row>
                        <Row style={ { marginTop: '30px' } }>
                            <Col lg={ 4 }>{ prettyBytes( this.state.mem * 1000 * 1000 * 1000 ) } Memory</Col>
                            <Col lg={ 8 }><Slider onChange={ ( val ) => {
                                this.setState( { mem: val } );
                            } } min={ 1 } max={ 16 } defaultValue={ this.state.mem }/>
                            </Col>


                        </Row>
                        { this.state.disks.map( ( disk, index ) => {
                                return <Row key={ index } style={ { marginTop: '30px' } }>
                                    <Col lg={ 4 }>Disk { index }<br/>{ prettyBytes( disk.size * 1000 * 1000 * 1000 ) }
                                    </Col>
                                    { (disk.create) ?
                                        <Col lg={ 8 }>
                                            <Slider onChange={ ( val ) => {
                                                disk.size = val;
                                                let disks = this.state.disks;
                                                disks[ index ] = disk
                                                this.setState( { disks } );
                                            } } min={ index === 0 ? 15 : 1 } max={ 100 } defaultValue={ disk.size }/>
                                            <br/>
                                            { index > 0 &&
                                            <span onClick={ () => {
                                                let diskstemp = this.state.disks;
                                                diskstemp[ index ].create = false;
                                                this.setState( { disks: diskstemp } );
                                            } } style={ {
                                                cursor: 'pointer',
                                                color: "#1b72e2"
                                            } }>Or add an existing disk</span>
                                            }
                                        </Col>
                                        :
                                        <Col lg={ 8 }>
                                            <AsyncSelect
                                                loadOptions={ ( input, update ) => {
                                                    if ( this.state.my_disks.length === 0 ) {
                                                        this.props.getDisks().then( disks => {
                                                            update( disks.filter( disk => {
                                                                if ( disk.attached ) {
                                                                    return false;
                                                                }
                                                                if ( input === "" ) {
                                                                    return true;
                                                                }
                                                                return disk.name.toLowerCase().includes( input.toLowerCase() ) || disk.id.toLowerCase().includes( input.toLowerCase() );
                                                            } ).map( disk => {
                                                                return {
                                                                    value: disk.id,
                                                                    label: disk.id + " | " + disk.name + " (" + prettyBytes( disk.size * 1000 * 1000 * 1000 ) + ")"
                                                                }
                                                            } ) )
                                                        } )
                                                    } else {
                                                        update( this.state.disks.filter( disk => {
                                                            if ( input === "" ) {
                                                                return true;
                                                            }
                                                            return disk.name.toLowerCase().includes( input.toLowerCase() );
                                                        } ).map( user => {
                                                            return {
                                                                value: user.id,
                                                                label: user.name
                                                            }
                                                        } ) )
                                                    }
                                                } }
                                                defaultOptions
                                                onChange={ ( newValue ) => {
                                                    let diskstemp = this.state.disks;
                                                    diskstemp[ index ].id = newValue.value;
                                                    this.setState( { disks: diskstemp } );
                                                    return newValue;
                                                } }
                                            />
                                            <span onClick={ () => {
                                                let diskstemp = this.state.disks;
                                                diskstemp[ index ].create = true;
                                                this.setState( { disks: diskstemp } );
                                            } } style={ {
                                                cursor: 'pointer',
                                                color: "#1b72e2"
                                            } }>Or add an new disk</span>
                                        </Col>
                                    }
                                </Row>
                            }
                        ) }
                        { this.state.disks.length < 4 &&
                        <Row>
                            <Col lg={ 4 }><span style={ { cursor: 'pointer', color: "#5d9cec" } } onClick={ () => {
                                let disks = this.state.disks;
                                disks.push( { id: null, size: 50, name: "disk " + disks.length, create: true } );
                                this.setState( { disks } );
                            } }><i>Add extra disk</i></span>
                            </Col>
                        </Row>
                        }

                        { this.state.ports.map( ( port, index ) => {
                                return <Row key={ index } style={ { marginTop: '30px' } }>
                                    <Col lg={ 4 }><Label style={ { marginTop: '6px' } }>Port { index }</Label>
                                    </Col>

                                    <Col lg={ 4} style={{paddingRight: 0}}>
                                        <Select options={[
                                            { value: 'tcp', label: 'TCP' },
                                            { value: 'udp', label: 'UDP' }
                                        ]} onChange={ ( newValue ) => {
                                            let portsTemp = this.state.ports;
                                            portsTemp[ index ].protocol = newValue.value;
                                            this.setState( { ports: portsTemp } );
                                            return newValue;
                                        } } />
                                    </Col>
                                    <Col lg={ 4} style={{paddingLeft: 0}}><Input
                                        type="number" className="form-control"
                                        placeholder={ '22' }
                                        value={port.port}
                                        onChange={ e => {
                                            let portsTemp = this.state.ports;
                                            portsTemp[ index ].port = e.target.value;
                                            this.setState( { ports: portsTemp } );
                                            return e.target.value;
                                        } } />
                                    </Col>
                                </Row>
                            }
                        ) }
                        { this.state.ports.length < 5 &&
                        <Row>
                            <Col lg={ 4 }><span style={ { cursor: 'pointer', color: "#5d9cec" } } onClick={ () => {
                                let ports = this.state.ports;
                                ports.push( { protocol:"tcp", port: 0 } );
                                this.setState( { ports } );
                            } }><i>Add extra port-forward</i></span>
                            </Col>
                        </Row>
                        }
                        <Row style={ { marginTop: '30px' } }>
                            <Col lg={ 4 }>
                                <Label style={ { marginTop: '6px' } }>Public-key</Label></Col>
                            <Col lg={ 8 }><Input
                                type="text" className="form-control"
                                placeholder={ 'ssh-rsa ...' }
                                onChange={ e => this.setState( { public_key: e.target.value } ) }/>
                            </Col>


                        </Row>
                        <Row>
                            <Col lg={ 9 }><h4
                                style={ { marginTop: '40px', marginBottom: '-15px' } }>&euro; { total.toFixed( 2 ) } /
                                month</h4><br/>
                                <i style={ { marginTop: '-15px' } }>&euro; { (total / (30 * 24)).toFixed( 4 ) } / hr</i></Col>

                            <Col lg={ 2 } style={ { marginTop: '40px' } }>
                                <StateButton runClick={ this.createVm }
                                             texts={ [ 'Create', 'Creating', 'Created', 'Failed' ] }
                                             color="primary"/>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>);
    }
}

const mapStateToProps = ( state ) => {
    return {
        user: state.UserInfo,
        users: state.Users
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { createVm, getImages, getDisks }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( NewVmPanel ) );
