import React, { Component } from 'react';
import { Button, Col } from 'reactstrap';
import { connect } from 'react-redux';
import Timestamp from "react-timestamp";
import InputMask from "react-input-mask";
import { bindActionCreators } from 'redux';
import { createInvoiceMandatePayment, createInvoicePayment, createPayment } from "../../actions/Invoices";
import Loader from "../Common/Loader";
import * as ReactDOM from "react-dom";
import { getMandates } from "../../actions/Users";
import { Card, CardBody, CardHeader, Row } from "reactstrap";

class InvoicePaymentPanel extends Component {

  constructor() {
    super();
    this.state = {
      mandates: [],
      chargeMandateButton: { text: "Charge mandate", style: "default" }
    };
    this.startIdealPayment = this.startIdealPayment.bind( this );
    this.createNewPayment = this.createNewPayment.bind( this );
    this.startMandatePayment = this.startMandatePayment.bind( this );
  }

  startIdealPayment() {
    this.props.createPayment( this.props.invoice.id, window.location.href ).then( payment => window.location = payment.link );
  }

  startMandatePayment() {
    this.setState( { chargeMandateButton: { text: "Scheduling", style: "warning" } } );
    this.props.createInvoiceMandatePayment( this.props.invoice.id ).then( () => {
      this.setState( { chargeMandateButton: { text: "Scheduled", style: "success" } } );
      this.props.fetchHandler(); // hacky
      setTimeout( function() {
        this.setState( { chargeMandateButton: { text: "Charge mandate", style: "default" } } );
      }.bind( this ), 5000 );
    } );
  }

  createNewPayment( event ) {
    if ( event.key !== 'Enter' ) {
      return false;
    }
    ReactDOM.findDOMNode( this.refs.newPaymentAccount ).style.borderColor = '#dde6e9';
    if ( this.refs.newPaymentAccount.value === "" ) {
      ReactDOM.findDOMNode( this.refs.newPaymentAccount ).style.borderColor = '#f05050';
      return false;
    }
    ReactDOM.findDOMNode( this.refs.newPaymentAmount ).style.borderColor = '#dde6e9';
    if ( this.refs.newPaymentAmount.value === "" ) {
      ReactDOM.findDOMNode( this.refs.newPaymentAmount ).style.borderColor = '#f05050';
      return false;
    }

    this.props.createInvoicePayment( this.props.invoice.id, this.refs.newPaymentAccount.value, this.refs.newPaymentAmount.value ).then( invoice => {
      this.props.fetchHandler(); // hacky
    } );
    this.refs.newPaymentAccount.value = "";
    this.refs.newPaymentAmount.value = "";
  }

  openSumUpPayment = () => {
    window.open( "sumupmerchant://pay/1.0?" +
      "amount=" + parseFloat( Math.round( ( this.props.invoice.grand_total - this.props.invoice.paid_payments_total ) * 100 ) / 100 ).toFixed( 2 ) + "&" +
      "currency=EUR&" +
      "affiliate-key=b5f3b14c-ec17-4625-b06b-00befad9d21a&" +
      "title=" + this.props.invoice.id + "&" +
      "callback=https://google.com" );
    //

  };

  componentWillReceiveProps( props ) {
    if ( props.user.admin && props.invoice.user !== undefined ) {
      this.props.getMandates( props.invoice.user.id ).then( mandates => {
        this.setState( { mandates } );
      } );
    }
  }


  render() {
    const { invoice, user } = this.props;
    // const { mandates } = this.state;
    return ( <div>
        <Card className="card-default">
          <CardHeader>
              <span className="pull-right">
                            {/*{ ( user.admin && mandates !== undefined && mandates.length > 0 && invoice.due_date !== "DRAFT" && parseFloat( Math.round( ( invoice.grand_total - invoice.payments_total ) * 100 ) / 100 ).toFixed( 2 ) < 1000 && parseFloat( Math.round( ( invoice.grand_total - invoice.payments_total ) * 100 ) / 100 ).toFixed( 2 ) > 0 ) ?*/}
                            {/*  <SplitButton onSelect={ this.startMandatePayment }*/}
                            {/*               color={ this.state.chargeMandateButton.style }*/}
                            {/*               title={ this.state.chargeMandateButton.text } id={ `mandate_button` }>*/}
                            {/*    {*/}
                            {/*      mandates.map( function( mandate ) {*/}
                            {/*          return <MenuItem key={ mandate.id }*/}
                            {/*                           eventKey={ mandate.id }>{ mandate.iban }</MenuItem>*/}
                            {/*        }*/}
                            {/*      )*/}
                            {/*    }*/}
                            {/*  </SplitButton>*/}
                            {/*  :*/}
                            {/*  ""*/}
                            {/*}*/}
                { ( !user.admin && invoice.id !== undefined && invoice.grand_total - invoice.payments_total > 0 && invoice.due_date !== "DRAFT" ) ?
                  <Button onClick={ this.startIdealPayment } color="primary">
                    <em className="fa fa-money fa-fw mr-sm"> </em>
                    Pay with iDeal
                  </Button>
                  :
                  ""
                }
                        </span>

            <h3>Payments</h3>
          </CardHeader>
          <CardBody>
            { ( invoice.id !== undefined ) ?
              <table className="table bb">
                <tbody>
                {
                  invoice.payments.sort( ( a, b ) => b.date - a.date )
                    .map( function( payment ) {
                        return <tr key={ payment.id }>
                          <td>

                            { ( payment.status === "open" ) ?
                              <strong>Mandate pending</strong>
                              :
                              <strong><Timestamp date={ payment.date } options={ { format: "date" } }/></strong>
                            }
                          </td>
                          <td
                            style={ { color: ( payment.status === "open" ) ? 'orange' : "" } }>&euro; { parseFloat( Math.round( payment.amount * 100 ) / 100 ).toFixed( 2 ) }</td>
                        </tr>
                      }
                    )
                }
                <tr style={ { color: ( invoice.grand_total - invoice.paid_payments_total > 0 ) ? 'red' : "green" } }>
                  <td>
                    <strong>Due</strong>
                  </td>
                  <td>
                    &euro; { parseFloat( Math.round( ( invoice.grand_total - invoice.paid_payments_total ) * 100 ) / 100 ).toFixed( 2 ) }</td>
                </tr>
                </tbody>
              </table>
              :
              <Loader> </Loader>
            }
          </CardBody>
        </Card>
        {
          ( invoice.grand_total - invoice.payments_total > 0 && user.admin ) ?
            <Card className="card-default">
              <CardHeader>
              <span className="pull-right">
                            {/*{ ( user.admin && mandates !== undefined && mandates.length > 0 && invoice.due_date !== "DRAFT" && parseFloat( Math.round( ( invoice.grand_total - invoice.payments_total ) * 100 ) / 100 ).toFixed( 2 ) < 1000 && parseFloat( Math.round( ( invoice.grand_total - invoice.payments_total ) * 100 ) / 100 ).toFixed( 2 ) > 0 ) ?*/}
                            {/*  <SplitButton onSelect={ this.startMandatePayment }*/}
                            {/*               color={ this.state.chargeMandateButton.style }*/}
                            {/*               title={ this.state.chargeMandateButton.text } id={ `mandate_button` }>*/}
                            {/*    {*/}
                            {/*      mandates.map( function( mandate ) {*/}
                            {/*          return <MenuItem key={ mandate.id }*/}
                            {/*                           eventKey={ mandate.id }>{ mandate.iban }</MenuItem>*/}
                            {/*        }*/}
                            {/*      )*/}
                            {/*    }*/}
                            {/*  </SplitButton>*/}
                            {/*  :*/}
                            {/*  ""*/}
                            {/*}*/}
                { ( !user.admin && invoice.id !== undefined && invoice.grand_total - invoice.payments_total > 0 && invoice.due_date !== "DRAFT" ) ?
                  <Button onClick={ this.startIdealPayment } color="primary">
                    <em className="fa fa-money fa-fw mr-sm"> </em>
                    Pay with iDeal
                  </Button>
                  :
                  ""
                }
                { ( user.admin && invoice.id !== undefined && invoice.grand_total - invoice.payments_total > 0 && invoice.due_date !== "DRAFT" ) ?
                  <Button onClick={ this.openSumUpPayment } color="primary">
                    <em className="fa fa-money fa-fw mr-sm"> </em>
                    Pay with PIN
                  </Button>

                  :
                  ""
                }
                        </span>
                <h3>New payment</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={ 8 }>
                    <InputMask ref='newPaymentAccount'
                               style={ { textTransform: 'uppercase' } }
                               mask="aa99 aaaa 9999 9999 99"
                               placeholder="account"
                               className="form-control"/>
                  </Col>
                  <Col md={ 4 }>
                    <input ref='newPaymentAmount'
                           type="number"
                           placeholder="Amount"
                           className="form-control"
                           onKeyPress={ this.createNewPayment }/>
                  </Col>

                </Row>
              </CardBody>
            </Card>
            : ""
        }
      </div>
    );
  }
}

const mapStateToProps = ( state ) => {
  return {
    user: state.UserInfo
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( {
    createInvoicePayment,
    createInvoiceMandatePayment,
    createPayment,
    getMandates
  }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( InvoicePaymentPanel );
