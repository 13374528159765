import axios from 'axios';
import { PTS_AUTH_LOCATION, API_LOCATION } from '../Constants';

const api = axios.create();

/** AUTH */
export function fetchClientToken() {
    return api.get( `${ API_LOCATION }/authentication/clienttoken` )
        .then( response => response.data.token );
}

export function authenticateUser( username, password, client_token ) {
    return api.post( `${ PTS_AUTH_LOCATION }/user/authenticate`, { username, password, client_token } )
        .then( resp => resp.data );
}

export function refreshAccessToken( client_token, refresh_token, access_token ) {
    return api.post( `${ PTS_AUTH_LOCATION }/user/refresh`, { client_token, refresh_token, access_token } )
        .then( resp => resp.data.access_token.token );
}

export function logoutUser() {
    return api.get( `${ API_LOCATION }/` )
        .then( resp => resp.data );
}

/** GENERAL */
export function getServerStats() {
    return api.get( `${ API_LOCATION }/serverstats` )
        .then( response => response.data );
}

/** USERS */
export function getCrons() {
    return api.get( `${ API_LOCATION }/webcrons` )
        .then( response => response.data );
}

/** USERS */
export function getDatabases() {
    return api.get( `${ API_LOCATION }/databases` )
        .then( response => response.data );
}

export function sendContactMessage( name, email, message ) {
    return api.post( `${ API_LOCATION }/beta/contact`, { name, email, message } )
        .then( response => response.data );
}

export function signupBeta( email ) {
    return api.post( `${ API_LOCATION }/beta/signup`, { email } )
        .then( response => response.data );
}

export function createCron( url, interval, user_id ) {
    return api.post( `${ API_LOCATION }/webcrons`, { url, interval, user_id } )
        .then( response => response.data );
}

export function createDatabase( user_id ) {
    return api.post( `${ API_LOCATION }/database`, { user_id } )
        .then( response => response.data );
}

export function deleteCron( id ) {
    return api.delete( `${ API_LOCATION }/webcrons/${ id }` )
        .then( response => response.data );
}

export function deleteDatabase( id ) {
    return api.delete( `${ API_LOCATION }/databases/${ id }` )
        .then( response => response.data );
}

export function getUsers() {
    return api.get( `${ API_LOCATION }/users` )
        .then( response => response.data );
}

export function createNewUser( username, addressTitle, streetName, streetNumber, postalCode, city ) {
    return api.post( `${ API_LOCATION }/users`, { username, addressTitle, streetName, streetNumber, postalCode, city } )
        .then( response => response.data );
}

export function getUserInformation( user_id ) {
    return api.get( `${ API_LOCATION }/users/${ user_id }` )
        .then( response => response.data );
}

export function getUserAvatar( user_id ) {
    return api.get( `${ API_LOCATION }/users/${ user_id }/avatar` )
        .then( response => response.data.location );
}

export function callUserPhone( user_id, phone_id ) {
    return api.post( `${ API_LOCATION }/users/${ user_id }/phones/${ phone_id }` )
        .then( response => response.data );
}

export function getUserMandates( user_id ) {
    return api.get( `${ API_LOCATION }/users/${ user_id }/mandates` )
        .then( response => response.data );
}

export function createUserMandatePayment( user_id, redirect_url ) {
    return api.post( `${ API_LOCATION }/users/${ user_id }/mandates`, { redirect_url } )
        .then( response => response.data );
}

export function getPostalCodeDetails( country_code, postal_code, number ) {
    return api.post( `${ API_LOCATION }/users/addresses/postalcode`, { postal_code, country_code, number } )
        .then( response => response.data.details );
}

/** INVOICES */
export function getInvoice( invoiceId ) {
    return api.get( `${ API_LOCATION }/invoices/${ invoiceId }` )
        .then( response => response.data );
}

export function getInvoices() {
    return api.get( `${ API_LOCATION }/invoices` )
        .then( response => response.data );
}

export function getInvoiceComponents() {
    return api.get( `${ API_LOCATION }/invoices/components` )
        .then( response => response.data );
}

export function updateInvoice( invoice ) {
    return api.put( `${ API_LOCATION }/invoices/${ invoice.id }`, { invoice } )
        .then( response => response.data );
}

export function createInvoice( user_id, date, due_date ) {
    return api.post( `${ API_LOCATION }/invoices`, { user_id, date, due_date } )
        .then( response => response.data );
}

export function createInvoiceContent( invoice_id, component_id, comment, price, quantity ) {
    return api.post( `${ API_LOCATION }/invoices/${ invoice_id }/contents`, { component_id, comment, price, quantity } )
        .then( response => response.data );
}

export function createPayment( invoice_id, redirect_url ) {
    return api.post( `${ API_LOCATION }/invoices/${ invoice_id }/payments/ideal`, { invoice_id, redirect_url } )
        .then( response => response.data );
}

export function createInvoicePayment( invoice_id, account, amount ) {
    return api.post( `${ API_LOCATION }/invoices/${ invoice_id }/payments`, { invoice_id, account, amount } )
        .then( response => response.data );
}

export function createInvoiceMandatePayment( invoice_id, user_id ) {
    return api.post( `${ API_LOCATION }/invoices/${ invoice_id }/payments/mandates` )
        .then( response => response.data );
}

export function sendInvoiceReminder( invoice_id, reason ) {
    return api.post( `${ API_LOCATION }/invoices/${ invoice_id }/reminder`, { invoice_id, reason } )
        .then( response => response.data.result );
}

/** TICKETS */
export function getTickets() {
    return api.get( `${ API_LOCATION }/tickets` )
        .then( response => response.data );
}

export function getTicket( id ) {
    return api.get( `${ API_LOCATION }/tickets/${ id }` )
        .then( response => response.data );
}

export function createTicket( user_id, name ) {
    return api.post( `${ API_LOCATION }/tickets`, { user_id, name } )
        .then( response => response.data );
}

export function createTicketMessage( ticket_id, message, send_mail, user_post ) {
    return api.post( `${ API_LOCATION }/tickets/${ ticket_id }/messages`, { message, send_mail, user_post } )
        .then( response => response.data );
}

export function setTicketStatus( ticket_id, status ) {
    return api.post( `${ API_LOCATION }/tickets/${ ticket_id }/status`, { status } )
        .then( response => response.data );
}

/** VOLUMES */
export function createVolume( name, engine, template, account, backup_policy, user_id ) {
    return api.post( `${ API_LOCATION }/volumes`, { name, engine, template, account, backup_policy, user_id } )
        .then( response => response.data );
}

export function createVm( name, image, public_ip, cpus, mem, disks, public_ports, public_key, user_id ) {
    let env_data = {
        PTS_NOTE: "Created via https://pushto.space/console"
    }
    return api.post( `${ API_LOCATION }/vms`, {
        name,
        image,
        public_ip,
        cpus,
        mem,
        disks,
        public_ports,
        public_key,
        user_id,
        env_data
    } )
        .then( response => response.data );
}

export function getVolumes() {
    return api.get( `${ API_LOCATION }/volumes` )
        .then( response => response.data );
}

export function getVms() {
    return api.get( `${ API_LOCATION }/vms` )
        .then( response => response.data );
}

export function getDisks() {
    return api.get( `${ API_LOCATION }/vms/disks` )
        .then( response => response.data );
}

export function getImages() {
    return api.get( `${ API_LOCATION }/vms/images` )
        .then( response => response.data );
}

export function powerOffVm( vm ) {
    return api.post( `${ API_LOCATION }/vms/${ vm }/power/off` )
        .then( response => response.data );
}

export function detachDiskFromVm( vm, disk ) {
    return api.delete( `${ API_LOCATION }/vms/${ vm }/disks/${ disk }` )
        .then( response => response.data );
}

export function attachDiskToVm( vm, disk ) {
    return api.post( `${ API_LOCATION }/vms/${ vm }/disks/${ disk }` )
        .then( response => response.data );
}

export function removeForwardFromIp( vm, ip, forward ) {
    return api.delete( `${ API_LOCATION }/vms/${ vm }/ips/${ ip }/forwards/${forward}` )
        .then( response => response.data );
}

export function addForwardToIp( vm, ip, port ) {
    return api.post( `${ API_LOCATION }/vms/${ vm }/ips/${ ip }/forwards` ,{port})
        .then( response => response.data );
}

export function powerOnVm( vm ) {
    return api.post( `${ API_LOCATION }/vms/${ vm }/power/on` )
        .then( response => response.data );
}

export function createImageFromVm( vm ) {
    return api.post( `${ API_LOCATION }/vms/${ vm }/image` )
        .then( response => response.data );
}

export function deleteVm( vm ) {
    return api.delete( `${ API_LOCATION }/vms/${ vm }` )
        .then( response => response.data );
}

export function getVolumeDeployments( id ) {
    return api.get( `${ API_LOCATION }/volumes/${ id }/deployments` )
        .then( response => response.data );
}

/** DOMAINS */
export function getDomains() {
    return api.get( `${ API_LOCATION }/domains` )
        .then( response => response.data );
}

export function getDomainCfStats( id ) {
    return api.get( `${ API_LOCATION }/domains/${ id }/cfstats` )
        .then( response => response.data );
}

export function checkAvailabiltyDispatchers( domain ) {
    return api.post( `${ API_LOCATION }/domains/available`, { domain } )
        .then( response => response.data );
}

export function refreshDomainEndpoints( id ) {
    return api.get( `${ API_LOCATION }/domains/${ id }/endpoints/refresh` )
        .then( response => response.data );
}

export function enableDomainDmarc( id ) {
    return api.post( `${ API_LOCATION }/domains/${ id }/dmarc` )
        .then( response => response.data );
}

export function disableDomainDmarc( id ) {
    return api.delete( `${ API_LOCATION }/domains/${ id }/dmarc` )
        .then( response => response.data );
}

export function getDmarcReports( id ) {
    return api.get( `${ API_LOCATION }/domains/${ id }/dmarc/reports` )
        .then( response => response.data );
}

export function getDkim( id ) {
    return api.get( `${ API_LOCATION }/domains/${ id }/dkim` )
        .then( response => response.data );
}

export function enableDkim( id ) {
    return api.post( `${ API_LOCATION }/domains/${ id }/dkim` )
        .then( response => response.data );
}

export function disableDkim( id ) {
    return api.delete( `${ API_LOCATION }/domains/${ id }/dkim` )
        .then( response => response.data );
}

export function checkMx( id ) {
    return api.get( `${ API_LOCATION }/domains/${ id }/mx` )
        .then( response => response.data );
}

export function fixMx( id ) {
    return api.post( `${ API_LOCATION }/domains/${ id }/mx` )
        .then( response => response.data );
}

export function getSpf( id ) {
    return api.get( `${ API_LOCATION }/domains/${ id }/spf` )
        .then( response => response.data );
}

export function enableSpf( id ) {
    return api.post( `${ API_LOCATION }/domains/${ id }/spf` )
        .then( response => response.data );
}

export function disableSpf( id ) {
    return api.delete( `${ API_LOCATION }/domains/${ id }/spf` )
        .then( response => response.data );
}

export function getDomainDnsRecords( id ) {
    return api.get( `${ API_LOCATION }/domains/${ id }/dns/records` )
        .then( response => response.data );
}

/** MAIL */
export function getDomainMailBoxes( id ) {
    return api.get( `${ API_LOCATION }/domains/${ id }/mailboxes` )
        .then( response => response.data );
}

export function getDomainMailBox( domain, id ) {
    return api.get( `${ API_LOCATION }/domains/${ domain }/mailboxes/${ id }` )
        .then( response => response.data );
}

export function createNewMailboxAlias( domain, id, alias ) {
    return api.post( `${ API_LOCATION }/domains/${ domain }/mailboxes/${ id }/aliases`, { alias } )
        .then( response => response.data );
}

export function updateMailboxPassword( domain, id, password ) {
    return api.post( `${ API_LOCATION }/domains/${ domain }/mailboxes/${ id }/password`, { password } )
        .then( response => response.data );
}

export function createMailbox( domain, address ) {
    return api.post( `${ API_LOCATION }/domains/${ domain }/mailboxes`, { address } )
        .then( response => response.data );
}

export function deleteMailboxAlias( domain, id, alias ) {
    return api.delete( `${ API_LOCATION }/domains/${ domain }/mailboxes/${ id }/aliases/${ alias }` )
        .then( response => response.data );
}

export function enableMailboxAutoReply( domain, box, name, subject, body ) {
    return api.post( `${ API_LOCATION }/domains/${ domain }/mailboxes/${ box }/ooo`, { name, subject, body } )
        .then( response => response.data );
}

export function disableMailboxAutoReply( domain, box ) {
    return api.delete( `${ API_LOCATION }/domains/${ domain }/mailboxes/${ box }/ooo` )
        .then( response => response.data );
}

/** VOLUMES */
export function createVolumeFtpUser( volume_id, password ) {
    return api.post( `${ API_LOCATION }/volumes/${ volume_id }/ftp`, { password } )
        .then( response => response.data );
}

export function deleteVolumeFtpUser( volume_id, ftp_id ) {
    return api.delete( `${ API_LOCATION }/volumes/${ volume_id }/ftp/${ ftp_id }` )
        .then( response => response.data );
}

export function getVolumeSizes( volume_id ) {
    return api.get( `${ API_LOCATION }/volumes/${ volume_id }/sizes` )
        .then( response => response.data );
}

/** TAXES */
export function getTaxExpenses() {
    return api.get( `${ API_LOCATION }/taxes/expenses` )
        .then( response => response.data );
}

export function getTaxExpense( id ) {
    return api.get( `${ API_LOCATION }/taxes/expenses/${ id }` )
        .then( response => response.data );
}

export function createTaxExpense( target_id, target_name, name, date, price, attachment ) {
    return api.post( `${ API_LOCATION }/taxes/expenses`, { target_id, target_name, name, price, date, attachment } )
        .then( response => response.data );
}

export function getWolks() {
    return api.get( `https://api.wolkairbag.com/v1/wolks` )
        .then( response => response.data );
}

export function getFirmwares() {
    return api.get( `https://api.wolkairbag.com/v1/testfws` )
        .then( response => response.data );
}

export function uploadFirmware( version, test, st, ble ) {
    return api.post( `https://api.wolkairbag.com/v1/fws`, { version, test, st, ble } )
        .then( response => response.data );
}


/** FRONTPAGE */

export function getNtpScores( ip ) {
    return api.get( `${ API_LOCATION }/ntpstats/${ ip }` )
        .then( response => response.data );
}


export default api;
