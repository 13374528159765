import { GET_TICKETS, GET_TICKET, CREATE_TICKET, CREATE_TICKET_MESSAGE } from '../actions/Tickets';
import { LOGOUT } from '../actions/Auth';

export default function Tickets( state = {}, action ) {
  switch ( action.type ) {
    case GET_TICKETS.SUCCESS:
      let store = {};
      action.payload.map( ticket => {
        store[ ticket.id ] = Object.assign(state[ ticket.id ] || {}, ticket);
        return true;
      } );
      return store;

    case GET_TICKET.SUCCESS:
      state[ action.payload.id ] = action.payload;
      return state;

    case CREATE_TICKET.SUCCESS:
      state[ action.payload.id ] = action.payload;
      return state;

    case CREATE_TICKET_MESSAGE.SUCCESS:
      state[ action.payload.id ].messages.push( action.payload.message );
      return state;

    case LOGOUT.SUCCESS:
      return {};

    default:
      return state;
  }
}