import Store from "../../services/Store";

export function countVisibles( array ) {
    if ( !isInitialized( array ) ) return false;
    return Object.values( array ).filter( a => a.visible === undefined || a.visible ).length;
}

export function resetVisibles( array ) {
    if ( !isInitialized( array ) ) return false;
    Object.values( array ).map( a => {
        a.visible = undefined;
        return true;
    } );
}

export function visibleTitle( array, name ) {
    if ( !isInitialized( array ) ) return false;
    let count = countVisibles( array );
    let string = count;
    let displayName = (count === 1) ? name[ 1 ] : name[ 0 ];
    if ( count < array.length ) {
        string += `/${ array.length } ${ name[ 0 ] } shown`
    } else {
        string += ` ${ displayName }`
    }
    return string;
}


export function visiblesTitle( array, visibles, name ) {
    if ( !isInitialized( array ) ) return false;
    let count = visibles.length;
    let string = count;
    let displayName = (count === 1) ? name[ 1 ] : name[ 0 ];
    if ( count < array.length ) {
        string += `/${ array.length } ${ name[ 0 ] } shown`
    } else {
        string += ` ${ displayName }`
    }
    return string;
}
export function isInitialized( toTest ) {
    return (toTest !== undefined && toTest !== null && toTest !== "" && toTest !== {});
}

export function containsRule( needle, search ) {
    return needle.indexOf( search ) >= 0;
}

export function equalsZeroRule( value ) {
    return value === 0;
}

export function NewSearch(rules, getProps) {
    const search = {
        term: "",
        update: [],
        rules: rules,
        visibles: [],
        propsFn: getProps
    };
    search.refresh = () => {
        search.visibles = searchArray( search.term, search.propsFn(), search.rules);
        search.update.map(fn => fn());
    }
    search.handleInput = e => {
        search.term = e.target.value;
        search.refresh();
    }
    search.onUpdate = fn => {
        search.update.push(fn);
    }
    search.getVisibles = () => {
        return search.visibles;
    }
    search.printVisibles = (titles) => {
        search.visibles = searchArray( search.term, search.propsFn(), search.rules);
        return visiblesTitle(search.propsFn(), search.visibles, titles)
    }
    return search;
}

export function searchArray( needle, array, ruleSet ) {
    if ( !isInitialized( array ) ) return false;
    const searchInput = needle.trim().toLowerCase();

    if (searchInput === "") {
        return array;
    }
    let results = [];
    Object.values( array ).map( a => {
        if ( testSearchRules( a, ruleSet, searchInput ) ) {
            results.push(a);
            return true;
        }
        return false;
    } );
    return results;
}

function testSearchRules(arrayElem, ruleSet,  searchInput) {
    let result = false;
    ruleSet.map( rule => {
        if ( rule( searchInput, arrayElem ) ) {
            result = true;
        }
        return result;
    } );
    return result;
}

export function meIsAdmin() {
    const userInfo = Store.getState().UserInfo;
    return (userInfo.id !== undefined && userInfo.admin);
}

export function meIsId( id ) {
    const userInfo = Store.getState().UserInfo;
    return (userInfo.id !== undefined && userInfo.id === id);
}
