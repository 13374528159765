import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ContentWrapper from '../Layout/ContentWrapper';
import { Col, Row } from 'reactstrap';
import { bindActionCreators } from "redux";
import DomainsPanel from "./DomainsPanel";
import { getDomains } from "../../actions/Domains";
import DomainCheckPanel from "./DomainCheckPanel";

class Domains extends React.Component {

  componentDidMount() {
    this.props.getDomains();
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          Domains
        </div>
        <Row>
          <Col lg={ 8 }>
            <DomainsPanel/>
          </Col>

          <Col lg={ 4 }>
            <DomainCheckPanel />
          </Col>

        </Row>
      </ContentWrapper>
    );
  }
}

function mapDispatchToProps( dispatch ) {
  return bindActionCreators( { getDomains }, dispatch )
}

const mapStateToProps = ( state ) => {
  return {
    domains: state.Domains
  }
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( Domains ) );
