import React from 'react';
import { withRouter } from "react-router";
import connect from "react-redux/es/connect/connect";
import { Link } from "react-router-dom";

class Footer extends React.Component {

  render() {
    const year = new Date().getFullYear();
    return (
      <footer className={this.props.layout.sidebar !== undefined ? "footer-container nosidebarcontainer" : "footer-container"}>
              <span>&copy; {year} - PushTo.Space | <Link style={{cursor:'pointer'}} to={`/`}>Homepage</Link></span>
      </footer>
    );
  }

}


const mapStateToProps = ( state ) => {
  return {
    layout: state.Layout
  }
};

export default connect( mapStateToProps )( withRouter( Footer ) );
