import React from 'react';
import Container from "reactstrap/es/Container";
import { Col, Row } from "reactstrap";
import HomepageCard from "./HomepageCard";
import BetaSignupPanel from "./BetaSignupPanel";

class HomepageCardBlock extends React.Component {

    render() {
        return (
            <section className="section section-lg pt-lg-0 mt--200"
                     style={ { marginTop: "-80px", marginBottom: "100px" } }>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Row className="row-grid justify-content-center">
                                <Col lg="4">
                                    <HomepageCard
                                        index={ 1 }
                                        title={ "Create an account" }
                                        color={ "primary" }
                                        content={ <BetaSignupPanel /> }/>
                                </Col>
                                <Col lg="4">
                                    <HomepageCard
                                        index={ 2 }
                                        title={ "Build Something" }
                                        color={ "success" }
                                        content={ "Setup your website or webapp and configure additional services. Check the console to see the possibilities for your project." }/>
                                </Col>
                                <Col lg="4">
                                    <HomepageCard
                                        index={ 3 }
                                        title={ "Relax" }
                                        color={ "warning" }
                                        content={ "After the setup is complete, your project is ready to serve its users. We take care of the availability of your engines." }/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

}

export default HomepageCardBlock;
