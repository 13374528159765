import { AUTH, AUTH_REFRESH } from '../actions/Auth';

export default function setAuth(api) {
  function setAuthHeader(token) {
    if (!token) return;
    api.defaults.headers.common['Authorization'] = `Bearer pts:v1 ${token}`; // eslint-disable-line
  }

  return store => {
    setAuthHeader(store.getState().Authentication.accessToken);
    return next => action => {
      if (action.type === AUTH.SUCCESS) {
        setAuthHeader(action.payload.access_token.token);
      }
      if (action.type === AUTH_REFRESH.SUCCESS) {
        setAuthHeader(action.payload);
      }

      return next(action);
    };
  };
}
