import React from 'react';
import Timestamp from "react-timestamp";
import { Card, CardBody, CardHeader } from "reactstrap";


class TicketFirstMessagePanel extends React.Component {

  constructor() {
    super();
    this.state = {
      ticket: undefined
    };
  }

  componentDidMount() {
    this.setState( { ticket: this.props.ticket } );
  }

  componentWillReceiveProps( props ) {
    this.setState( { ticket: props.ticket } );
  }

  render() {
    const { ticket } = this.props;
    return (
      ticket !== undefined &&
      <Card className="card-default">
        <CardHeader>
          <div className="media mt0">
            <div className="media-left">
              <img className="align-self-start mx-2 circle thumb32" src="/img/logo_colored.png" alt="Avatar" />
            </div>
            <div className="media-body media-middle">
              <h5 className="media-heading m0 text-bold" style={{marginBottom: '0'}}>PushTo.Space</h5>
              <small className="text-muted"><Timestamp date={ ticket.date }/></small>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <p>Hi { ticket.user.name },<br/><br/>Before you start typing your message above, please read the guidelines on
            submitting a ticket.<br/><br/>
            <b>PushTo.Space will never ask you for your password in an unsolicited email or phone call. And we'll never
              ask you to send your password or credit card information by email.</b><br/><br/>You are ready! Go ahead
            and send us your question!</p>

        </CardBody>
      </Card>
    );
  }
}

export default TicketFirstMessagePanel;

