import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getVolumeSizes } from "../../actions/Volumes";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Line } from "react-chartjs-2";
import moment from "moment";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";


class VolumeSizePanel extends React.Component {


  getMonthName( index ) {
    let monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    let month = ( new Date() ).getMonth() + index - ( 12 * Math.floor( ( ( new Date() ).getMonth() + index ) / 12 ) );
    return monthNames[ month ];
  }

  calculateMonthAverage( sizes, index, recursive = false ) {
    let inRangeSizes = sizes.filter( size => size.date > moment().subtract( index, 'M' ).startOf( 'month' ).unix() && size.date < moment().subtract( index, 'M' ).endOf( 'month' ).unix() );
    if ( inRangeSizes.length === 0 ) {
      return 0;
    }
    let sum = 0;
    inRangeSizes.map( size => {
      sum = sum + size.size;
      return true;
    } );
    let avg = ( sum / inRangeSizes.length ) / 1000000;
    return Math.round( avg * 100 ) / 100;
  }

  constructor() {
    super();
    this.state = {
      parsed: false,
      data: {
        labels: [ this.getMonthName( -4 ), this.getMonthName( -3 ), this.getMonthName( -2 ), this.getMonthName( -1 ), this.getMonthName( 0 ) ],
        datasets: [
          {
            label: 'Volume size',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [ 0, 0, 0, 0, 0 ]
          }
        ]
      }
    };
  }

  componentDidMount() {
    this.setState( { volume: this.props.volume } );
    if ( this.props.volume ) {
      this.getSizes( this.props.volume.id );
    }
  }

  componentWillReceiveProps( props ) {
    this.setState( { volume: props.volume } );
    this.getSizes( props.volume.id );
  }

  makeSense( dataPoints ) {
    for ( let i = 0; i < dataPoints.length; i++ ) {
      if ( dataPoints[ i ] === 0 && dataPoints[ i - 1 ] && dataPoints[ i - 1 ] > 0 ) {
        dataPoints[ i ] = dataPoints[ i - 1 ];
      }
    }
    return dataPoints;
  }

  getSizes( id ) {
    this.props.getVolumeSizes( id ).then( sizes => {
      return sizes;
    } ).then( sizes => {
      const calc = this.calculateMonthAverage;
      let data = this.state.data;
      data.datasets[ 0 ].data = this.makeSense( [ calc( sizes, 4 ), calc( sizes, 3 ), calc( sizes, 2 ), calc( sizes, 1 ), calc( sizes, 0 ) ] );
      this.setState( { data, parsed: true } );
    } );
  }

  render() {
    return <Card className="card-default">
      <CardHeader>
        <div style={ { fontSize: '14px' } }>
          Average size (in MBs)
        </div>
      </CardHeader>
      <CardBody>
        { this.state.parsed ?
          <Line legend={ { display: false } } data={ this.state.data } options={ {
            scales: {
              yAxes: [ {
                ticks: {
                  beginAtZero: true,
                  min: 0
                }
              } ]
            }
          } }/>
          :
          <Row>
            <Col sm={ 4 }/>
            <Col sm={ 4 }>
              <img style={ { margin: '0 auto', width: '100%' } } alt="loading" src="/img/svg/bars.svg"/>
            </Col>
            <Col sm={ 4 }/>
          </Row>
        }

      </CardBody>
    </Card>;
  }
}

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { getVolumeSizes }, dispatch )
};

export default connect( null, mapDispatchToProps )( VolumeSizePanel );

