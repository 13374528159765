import React from 'react';
import { withRouter } from 'react-router';
import { CardBody, Col, Row } from "reactstrap";
import Hero from "../hero";
import Card from "reactstrap/lib/Card";

class PrivacyPolicy extends React.Component {

    render() {

        return (
            <div style={ { display: 'block', margin: '0', width: '100%', height: '450px' } }>

                <Hero content={
                    <h1 className={ 'triangle-title' } style={ { textAlign: 'center' } }>Cookie Policy</h1>
                }/>

                <div className="container container-lg" style={ { marginBottom: '80px', marginTop: '30px' } }>
                    <Row>
                        <Col sm={ 12 } lg={ 12 }>
                            <Card>
                                <CardBody>
                                    <h2>COOKIE POLICY</h2><br/>
                                    <br/>
                                    <i>Last updated January 01, 2020</i>
                                    <br/>
                                    <br/>
                                    <br/>
                                    This Cookie Policy explains how PushTo.Space ("Company", "we", "us", and "our") uses
                                    cookies and similar technologies to recognize you when you visit our websites at
                                    https://pushto.space, ("Websites"). It explains what these technologies are and why
                                    we use them, as well as your rights to control our use of them.
                                    <br/>
                                    In some cases we may use cookies to collect personal information, or that becomes
                                    personal information if we combine it with other information.
                                    <br/><br/>
                                    <h3>What are cookies?</h3>
                                    <br/>
                                    Cookies are small data files that are placed on your computer or mobile device when
                                    you visit a website. Cookies are widely used by website owners in order to make
                                    their websites work, or to work more efficiently, as well as to provide reporting
                                    information.
                                    <br/>
                                    Cookies set by the website owner (in this case, PushTo.Space) are called "first
                                    party cookies". Cookies set by parties other than the website owner are called
                                    "third party cookies". Third party cookies enable third party features or
                                    functionality to be provided on or through the website (e.g. like advertising,
                                    interactive content and analytics). The parties that set these third party cookies
                                    can recognize your computer both when it visits the website in question and also
                                    when it visits certain other websites.
                                    <br/><br/>
                                    <h3>Why do we use cookies?</h3>
                                    <br/>
                                    We use first and third party cookies for several reasons. Some cookies are required
                                    for technical reasons in order for our Websites to operate, and we refer to these as
                                    "essential" or "strictly necessary" cookies. Other cookies also enable us to track
                                    and target the interests of our users to enhance the experience on our Online
                                    Properties. Third parties serve cookies through our Websites for advertising,
                                    analytics and other purposes. This is described in more detail below.
                                    <br/>
                                    The specific types of first and third party cookies served through our Websites and
                                    the purposes they perform are described below (please note that the specific cookies
                                    served may vary depending on the specific Online Properties you visit):
                                    <br/><br/>
                                    <h3>How can I control cookies?</h3>
                                    <br/>
                                    You have the right to decide whether to accept or reject cookies. Essential cookies
                                    cannot be rejected as they are strictly necessary to provide you with services. If
                                    you do not want any cookie to be present, you can logout of the console. This will
                                    the local storage.
                                    <br/>
                                    The Cookie Consent Manager can be found in the notification banner and on our
                                    website. If you choose to reject cookies, you may still use our website though your
                                    access to some functionality and areas of our website may be restricted. You may
                                    also set or amend your web browser controls to accept or refuse cookies. As the
                                    means by which you can refuse cookies through your web browser controls vary from
                                    browser-to-browser, you should visit your browser's help menu for more information.
                                    <br/>
                                    In addition, most advertising networks offer you a way to opt out of targeted
                                    advertising. If you would like to find out more information, please visit
                                    http://www.aboutads.info/choices/ or http://www.youronlinechoices.com.
                                    <br/>
                                    The specific types of first and third party cookies served through our Websites and
                                    the purposes they perform are described in the table below (please note that the
                                    specific cookies served may vary depending on the specific Online Properties you
                                    visit):
                                    <br/><br/>
                                    <h3>Cookies:</h3>
                                    <br/>
                                    <table>
                                        <tr>
                                            <td>Name</td>
                                            <td>pushto.space</td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td>html_local_storage / persistent</td>
                                        </tr>
                                        <tr>
                                            <td>Country</td>
                                            <td>The Netherlands</td>
                                        </tr>
                                        <tr>
                                            <td>Purpose</td>
                                            <td>Maintaining login session</td>
                                        </tr>
                                    </table>
                                    <br/><br/>
                                    <h3>What about other tracking technologies, like web beacons?</h3>
                                    <br/>
                                    Cookies are not the only way to recognize or track visitors to a website. We may use
                                    other, similar technologies from time to time, like web beacons (sometimes called
                                    "tracking pixels" or "clear gifs"). These are tiny graphics files that contain a
                                    unique identifier that enable us to recognize when someone has visited our Websites
                                    or opened an e-mail including them. This allows us, for example, to monitor the
                                    traffic patterns of users from one page within a website to another, to deliver or
                                    communicate with cookies, to understand whether you have come to the website from an
                                    online advertisement displayed on a third-party website, to improve site
                                    performance, and to measure the success of e-mail marketing campaigns. In many
                                    instances, these technologies are reliant on cookies to function properly, and so
                                    declining cookies will impair their functioning.
                                    <br/><br/>
                                    <h3>Do you use Flash cookies or Local Shared Objects?</h3>
                                    <br/>
                                    No
                                    <br/><br/>
                                    <h3>Do you serve targeted advertising?</h3>
                                    <br/>
                                    No
                                    <br/><br/>
                                    <h3>How often will you update this Cookie Policy?</h3>
                                    <br/>
                                    We may update this Cookie Policy from time to time in order to reflect, for example,
                                    changes to the cookies we use or for other operational, legal or regulatory reasons.
                                    Please therefore re-visit this Cookie Policy regularly to stay informed about our
                                    use of cookies and related technologies.
                                    <br/>
                                    The date at the top of this Cookie Policy indicates when it was last updated.
                                    <br/><br/>
                                    <h3>Where can I get further information?</h3>
                                    <br/>
                                    If you have any questions about our use of cookies or other technologies, please
                                    email us at general@pushto.space or by post to:
                                    <br/><br/>
                                    <b>PushTo.Space</b><br />
                                    Stationsplein 13F<br />
                                    4702VZ Roosendaal, Noord-Brabant <br />
                                    Netherlands<br />
                                    Phone: 003182081995<br />
                                    <br />
                                    general@pushto.space<br />
                                    <br />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>);
    }
}

export default withRouter( PrivacyPolicy );
