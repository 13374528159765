import React from 'react';
import CardBody from "reactstrap/es/CardBody";
import Card from "reactstrap/es/Card";

class HomepageCard extends React.Component {

    render() {
        return (
            <Card className="card-lift--hover shadow border-0">
                <CardBody className="py-5">
                    <div className={ `icon icon-shape icon-shape-${this.props.color} rounded-circle mb-4 text-md`}>
                        <b>{ this.props.index }</b>
                    </div>
                    <h6 className={`text-${this.props.color} text-uppercase`}>
                        {this.props.title}
                    </h6>
                    <p className="description mt-3">
                        {this.props.content}
                    </p>
                </CardBody>
            </Card>
        );
    }

}

export default HomepageCard;
