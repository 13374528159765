import React from 'react';
import { connect } from "react-redux";
import { createTicketMessage } from "../../actions/Tickets";
import { bindActionCreators } from "redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import { meIsAdmin } from "../Common/Utils";
import Switch from "react-switch";


class TicketNewMessagePanel extends React.Component {

  constructor() {
    super();
    this.state = {
      sendMessageButton: { text: "Post", style: "btn-primary " },
      ticket: undefined,
      send_mail: true,
      message: ""
    };
    this.handleMessage = this.handleMessage.bind( this );
    this.handleSendMail = this.handleSendMail.bind( this );
    this.handleSubmit = this.handleSubmit.bind( this );
  }

  handleSendMail( checked ) {
    this.setState( { send_mail: checked } );
  }

  handleMessage( event ) {
    this.setState( { message: event.target.value } );
  }

  handleSubmit( as_user ) {
    this.setState( { sendMessageButton: { text: "Posting...", style: "btn-warning" } } );
    if ( this.state.message.trim() === "" ) {
      this.setState( { message: "", sendMessageButton: { text: "Error", style: "btn-danger" } } );
      setTimeout( function() {
        this.setState( { sendMessageButton: { text: "Post", style: "btn-primary " } } );
      }.bind( this ), 5000 );
      return false;
    }
    this.props.createTicketMessage( this.state.ticket.id, this.state.message, this.state.send_mail, as_user ).then( data => {
      this.props.callback( data.message );
      this.setState( { message: "", sendMessageButton: { text: "Posted", style: "btn-success" } } );
      setTimeout( function() {
        this.setState( { sendMessageButton: { text: "Post", style: "btn-primary " } } );
      }.bind( this ), 5000 );
    } );
  }

  componentDidMount() {
    this.setState( { ticket: this.props.ticket } );
  }

  componentWillReceiveProps( props ) {
    this.setState( { ticket: props.ticket } );
  }

  render() {
    const { ticket } = this.state;
    if ( ticket !== undefined && ticket.status === 0 ) {
      return (
        <Card className="card-default">
          <CardHeader>
            <h3>New message</h3>
          </CardHeader>
          <CardBody>
            <textarea value={ this.state.message }
                      onChange={ this.handleMessage } placeholder="Message..." style={ { marginBottom: "8px" } }
                      rows="3" className="form-control no-resize"/>

            <div className="clearfix">
              {/*<div className="pull-left">*/ }
              {/*Posting as {user.name}*/ }
              {/*</div>*/ }
              <div className="pull-right">
                { meIsAdmin() &&
                <label style={ { padding: '20px' } }>
                  <span>Send email update</span>
                  <Switch onChange={ this.handleSendMail } checked={ this.state.send_mail }/>
                </label>
                }
                {
                  meIsAdmin() &&
                  <button style={ { marginRight: "5px" } } onClick={ () => {
                    this.handleSubmit( true );
                  } } type="button"
                          className={ "btn btn-sm " + this.state.sendMessageButton.style }>{ this.state.sendMessageButton.text } as
                    user</button>
                }
                <button onClick={ () => {
                  this.handleSubmit( false );
                } } type="button"
                        className={ "btn btn-sm " + this.state.sendMessageButton.style }>{ this.state.sendMessageButton.text }</button>
              </div>
            </div>
          </CardBody>
        </Card> );
    } else {
      return ( <span/> );
    }
  }
}

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( {
    createTicketMessage
  }, dispatch )
};

export default connect( null, mapDispatchToProps )( TicketNewMessagePanel );

