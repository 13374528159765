import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from "reactstrap/es/Table";
import { Card, CardBody, CardHeader } from "reactstrap";

class UserPhones extends Component {

  render() {
    const phones = Object.values( this.props.phones );
    const callPhone = this.props.call;
    const { user } = this.props;
    return (

      <Card className="card-default">
        <CardHeader>Phones</CardHeader>
        <CardBody>
          { ( phones !== undefined && phones instanceof Array ) &&
          <Table id="table-ext-1" responsive hover>
            <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Number</th>
            </tr>
            </thead>
            <tbody>
            {
              phones.map( function( phone ) {
                  return <tr key={ phone.id }>
                    <td>{ phone.id }</td>
                    <td>{ phone.name }</td>
                    <td> { user.admin ?
                      <span onClick={ () => { callPhone( phone.id ); } }
                         style={ { color:'#5d9cec', cursor: "pointer" } }>{ phone.phone }</span>
                      :
                      <span>{ phone.phone }</span>
                    }
                    </td>
                  </tr>
                }
              )
            }
            </tbody>
          </Table>
          }
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ( state ) => {
  return {
    user: state.UserInfo
  }
};

export default connect( mapStateToProps )( UserPhones );
