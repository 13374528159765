import React, { Component } from 'react';
import UserAvatar from "../Common/UserAvatar";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router";
import bindActionCreators from "redux/src/bindActionCreators";
import { logoutPTS } from "../../actions/Auth";

class SidebarUserBlock extends Component {

  constructor() {
    super();
    this.logout = this.logout.bind( this );
    this.state = { user: { name: "", id: 0 } };
  }

  componentWillReceiveProps( props ) {
    this.setState( { user: props.user } );
  }

  componentDidMount() {
    this.setState( { user: this.props.user } );
  }

  logout() {
    this.props.logoutPTS();
    this.props.history.push( '/console/login' );
  }


  render() {
    const { user } = this.state;
    return (
      <div>
        <div className="item user-block">
          <div className="user-block-picture">
            <div className="user-block-status">
              <UserAvatar/>
              <div className="circle bg-success circle-lg"></div>
            </div>
          </div>
          { /* Name and Job */ }
          <div className="user-block-info">
            <span className="user-block-name">Hello, { user.name }</span>
            <span className="user-block-status" onClick={ this.logout }>Logout</span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ( state ) => {
  return {
    user: state.UserInfo
  }
};

function mapDispatchToProps( dispatch ) {
  return bindActionCreators( { logoutPTS }, dispatch )
}


export default connect( mapStateToProps, mapDispatchToProps )( withRouter( SidebarUserBlock ) );
