import { action } from './Util';

export const COINHIVE_RUN = "COINHIVE_RUN";
export const COINHIVE_STATS = "COINHIVE_STATS";

export function startMining() {
  return dispatch => {
    dispatch( action( COINHIVE_RUN, true ) );
  };
}

export function stopMining() {
  return dispatch => {
    dispatch( action( COINHIVE_RUN, false ) );
  };
}

export function updateStats( stats ) {
  return dispatch => {
    dispatch( action( COINHIVE_STATS, stats ) );
  };
}