import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';

import Dashboard from "./components/Dashboard/Dashboard";
import Invoices from "./components/Invoices/Invoices";
import Login from "./components/Login/Login";
import NewInvoice from "./components/Invoices/NewInvoice";
import Invoice from "./components/Invoices/Invoice";
import User from "./components/User/User";
import MailOverview from "./components/Mail/MailOverview";
import UserOverview from "./components/User/UserOverview";
import MailBoxView from "./components/Mail/MailBoxView";
import Domains from "./components/Domains/Domains";
import Domain from "./components/Domains/Domain";
import Home from "./components/Homepage/Home";
import Base from "./components/Layout/Frontpage/Base";
import BaseConsole from "./components/Layout/Console/Base";
import Plans from "./components/Homepage/Sla/Plans";
import Mail from "./components/Homepage/Docs/Configuration/Mail";
import Volumes from "./components/Volumes/Volumes";
import Volume from "./components/Volumes/Volume";
import Store from "./services/Store";
import TicketsOverview from "./components/Tickets/TicketsOverview";
import Ticket from "./components/Tickets/Ticket";
import NewTicket from "./components/Tickets/NewTicket";
import Docs from "./components/Homepage/Docs/Docs";
import TaxesOverview from "./components/Taxes/TaxesOverview";
import NewExpense from "./components/Taxes/NewExpense";
import ConsoleRedirect from "./components/ConsoleRedirect";
import Ftp from "./components/Homepage/Docs/Configuration/Ftp";
import WebcronsOverview from "./components/Webcrons/WebcronsOverview";
import Ntp from "./components/Homepage/Docs/Configuration/Ntp";
import MailDomainView from "./components/Mail/MailDomainView";
import DatabasesOverview from "./components/Databases/DatabasesOverview";
import VmsOverview from "./components/Vms/VmsOverview";
import PrivacyPolicy from "./components/Homepage/Legal/PrivacyPolicy";
import Disclaimer from "./components/Homepage/Legal/Disclaimer";
import TermsOfUse from "./components/Homepage/Legal/TermsOfUse";
import CookiePolicy from "./components/Homepage/Legal/CookiePolicy";
import NotFound from "./components/Homepage/NotFound";
import DPA from "./components/Homepage/Legal/DPA";

const requireAuth = location => {
    const state = Store.getState();
    if ( state.Authentication.accessToken === undefined || state.Authentication.refreshToken === undefined ) {
        return location( '/console/login' );
    }
};

const requireNoAuth = location => {
    const state = Store.getState();
    if ( state.Authentication.accessToken !== undefined && state.Authentication.refreshToken !== undefined ) {
        return location( '/console/dashboard' );
    }
};

const Routes = router => {

    return (
        <Switch location={ router.location }>

            <Route path="/console/login" render={ () => {
                let redirect = requireNoAuth( location => {
                    return <Redirect to={ location }/>
                } );
                return redirect === undefined ? <Login/> : redirect;
            } }/>
            <Route path="/console" render={ () => {
                let redirect = requireAuth( location => {
                    return <Redirect to={ location }/>
                } );
                if ( redirect !== undefined ) {
                    return redirect;
                }
                return <BaseConsole location={ router.location }>
                    <Route path="/console/dashboard" component={ Dashboard }/>
                    <Route exact path="/console" component={ ConsoleRedirect }/>
                    <Route exact path="/console/" component={ ConsoleRedirect }/>
                    <Route path="/console/domains" component={ Domains }/>
                    <Route path="/console/databases" component={ DatabasesOverview }/>
                    <Route path="/console/vms" component={ VmsOverview }/>
                    <Route path="/console/domain/:domainId" component={ Domain }/>
                    <Route path="/console/invoices/new" component={ NewInvoice }/>
                    <Route path="/console/tickets/new" component={ NewTicket }/>
                    <Route exact path="/console/invoices" component={ Invoices }/>
                    <Route exact path="/console/tickets" component={ TicketsOverview }/>
                    <Route exact path="/console/taxes" component={ TaxesOverview }/>
                    <Route exact path="/console/taxes/new-expense" component={ NewExpense }/>
                    <Route path="/console/invoice/:invoiceId" component={ Invoice }/>
                    <Route path="/console/ticket/:ticketId" component={ Ticket }/>
                    <Route path="/console/volumes" component={ Volumes }/>
                    <Route path="/console/webcrons" component={ WebcronsOverview }/>
                    <Route path="/console/volume/:volumeId" component={ Volume }/>
                    <Route exact path="/console/mail/:dId/box/:bId" component={ MailBoxView }/>
                    <Route exact path="/console/mail/:dId" component={ MailDomainView }/>
                    <Route exact path="/console/mail" component={ MailOverview }/>
                    <Route path="/console/users" component={ UserOverview }/>
                    <Route path="/console/user/:userId" component={ User }/>
                </BaseConsole>

            } }/>

            <Route path="/">
                <Base location={ router.location }>
                    <Switch>
                        <Route exact path="/" component={ Home }/>
                        <Route exact path="/home" component={ Home }/>
                        <Route exact path="/sla/plans" component={ Plans }/>
                        <Route exact path="/docs" component={ Docs }/>
                        <Route exact path="/docs/configuration/mail" component={ Mail }/>
                        <Route exact path="/docs/configuration/ntp" component={ Ntp }/>
                        <Route exact path="/docs/configuration/ftp" component={ Ftp }/>

                        <Route exact path="/legal/privacy-notice" component={ PrivacyPolicy }/>
                        <Route exact path="/legal/cookie-policy" component={ CookiePolicy }/>
                        <Route exact path="/legal/disclaimer" component={ Disclaimer }/>
                        <Route exact path="/legal/terms-of-use" component={ TermsOfUse }/>
                        <Route exact path="/legal/dpa" component={ DPA }/>
                        <Route component={ NotFound }/>
                    </Switch>
                </Base>
            </Route>
            <Route component={ NotFound }/>

        </Switch>


    );
};

export default withRouter( Routes );
