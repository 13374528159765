import {
  GET_INVOICES,
  GET_INVOICE,
  UPDATE_INVOICE,
  CREATE_INVOICE_MANDATE_PAYMENT,
  CREATE_INVOICE
} from '../actions/Invoices';
import { LOGOUT } from '../actions/Auth';

export default function Invoices( state = {}, action ) {
  switch ( action.type ) {
    case GET_INVOICES.SUCCESS:
      let store = {};
      action.payload.map( invoice => {
        store[ invoice.id ] = Object.assign( state[ invoice.id ] || {}, invoice );
        return true;
      } );
      return store;

    case UPDATE_INVOICE.SUCCESS:
      state[ action.payload.id ] = action.payload;
      return state;

    case CREATE_INVOICE.SUCCESS:
      if (action.payload.payments == null) {
        action.payload.payments = [];
      }
      state[ action.payload.id ] = action.payload;
      return state;

    case GET_INVOICE.SUCCESS:
      state[ action.payload.id ] = action.payload;
      return state;

    case CREATE_INVOICE_MANDATE_PAYMENT.SUCCESS:
      state[ action.payload.id ] = action.payload;
      return state;

    case LOGOUT.SUCCESS:
      return {};

    default:
      return state;
  }
}