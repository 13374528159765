import React from 'react';
import { withRouter } from 'react-router';
import { Col, Row } from "reactstrap";
import Banner from "../Banner";

class Plans extends React.Component {

    render() {
        return (
            <div>
                <Banner title={ "SLA plans" }/>

                <div className="container container-lg" style={ { marginTop: '50px' } }><Row>
                    <Col md={ 4 }>
                        <div className="plan">
                            <h3 className="plan-header">Level 1</h3>
                            <div className="plan-price">
                                <div className="text-lg">Free</div>
                            </div>
                            <ul className="plan-features">
                                <li><em className="fa fa-check"/>Email support</li>
                                <li><em className="fa fa-times plan-feature-disable"/>Phone support</li>
                                <li><em className="fa fa-times plan-feature-disable"/>Response within 30 min</li>
                                <li><em className="fa fa-times plan-feature-disable"/>Response within 24 hours</li>
                                <li><em className="fa fa-check"/>Response within 1 working day</li>
                                <li><em className="fa fa-times plan-feature-disable"/>Onsite within 24 hours</li>
                                <li><em className="fa fa-times plan-feature-disable"/>Onsite within 2 working days</li>
                                <li><em className="fa fa-check"/>Onsite within 5 working days</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={ 4 }>
                        <div className="plan">
                            <h3 className="plan-header">Level 2</h3>
                            <div className="plan-price">
                                <div className="text-lg"><sup>
                                    <small>&euro;</small>
                                </sup>25/mo
                                </div>
                            </div>
                            <ul className="plan-features">
                                <li><em className="fa fa-check"/>Email support</li>
                                <li><em className="fa fa-check"/>Phone support</li>
                                <li><em className="fa fa-times plan-feature-disable"/>Response within 30 min</li>
                                <li><em className="fa fa-check"/>Response within 24 hours</li>
                                <li><em className="fa fa-check"/>Response within 1 working day</li>
                                <li><em className="fa fa-times plan-feature-disable"/>Onsite within 24 hours</li>
                                <li><em className="fa fa-check"/>Onsite within 2 working days</li>
                                <li><em className="fa fa-check"/>Onsite within 5 working days</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={ 4 }>
                        <div className="plan">
                            <h3 className="plan-header">Level 3</h3>
                            <div className="plan-price">
                                <div className="text-lg"><sup>
                                    <small>&euro;</small>
                                </sup>60/mo
                                </div>
                            </div>
                            <ul className="plan-features">
                                <li><em className="fa fa-check"/>Email support</li>
                                <li><em className="fa fa-check"/>Phone support</li>
                                <li><em className="fa fa-check"/>Response within 30 min</li>
                                <li><em className="fa fa-check"/>Response within 24 hours</li>
                                <li><em className="fa fa-check"/>Response within 1 working day</li>
                                <li><em className="fa fa-check"/>Onsite within 24 hours</li>
                                <li><em className="fa fa-check"/>Onsite within 2 working days</li>
                                <li><em className="fa fa-check"/>Onsite within 5 working days</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                </div>
            </div>

        );
    }

}

export default withRouter( Plans );
