import { createRequestTypes, action } from './Util';
import * as api from '../services/Api';

export const USER_INFO = createRequestTypes('USER_INFO');
export const CALL_USER_PHONE = createRequestTypes('CALL_USER_PHONE');
export const GET_USER = createRequestTypes('GET_USER');
export const GET_STATS = createRequestTypes('GET_STATS');
export const USER_AVATAR = createRequestTypes('USER_AVATAR');

export function getUserInformation() {
  return dispatch => {
    dispatch(action(USER_INFO.REQUEST));
    return api.getUserInformation("me")
      .then(user => {
        dispatch(action(USER_INFO.SUCCESS, user));
        return user;
      })
      .catch(resp => {
        const msg = resp.message;
        dispatch(action(USER_INFO.FAILURE, resp));
        return Promise.reject(msg);
      });
  }
}
export function getStats() {
  return dispatch => {
    dispatch(action(GET_STATS.REQUEST));
    return api.getServerStats()
      .then(stats => {
        dispatch(action(GET_STATS.SUCCESS, stats));
        return stats;
      })
      .catch(resp => {
        const msg = resp.message;
        dispatch(action(GET_STATS.FAILURE, resp));
        return Promise.reject(msg);
      });
  }
}
export function getUser(user_id) {
    return dispatch => {
        dispatch(action(GET_USER.REQUEST));
        return api.getUserInformation(user_id)
            .then(user => {
                dispatch(action(GET_USER.SUCCESS, user));
                return user;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(action(GET_USER.FAILURE, resp));
                return Promise.reject(msg);
            });
    }
}
export function callUserPhone(user_id, phone_id) {
    return dispatch => {
        dispatch(action(CALL_USER_PHONE.REQUEST));
        return api.callUserPhone(user_id, phone_id)
            .then(phone => {
                dispatch(action(CALL_USER_PHONE.SUCCESS, phone));
                return phone;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(action(CALL_USER_PHONE.FAILURE, resp));
                return Promise.reject(msg);
            });
    }
}
export function getUserAvatar(userId) {
    return dispatch => {
        dispatch(action(USER_AVATAR.REQUEST));
        api.getUserAvatar(userId)
            .then(user => dispatch(action(USER_AVATAR.SUCCESS, user)))
            .catch(error => dispatch(action(USER_AVATAR.FAILURE, error)));
    }
}