import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Col, Row } from 'reactstrap';
import PendingInvoicesCard from "../Invoices/PendingInvoicesCard";
import ServerStatusPanel from "./ServerStatusPanel";
import OpenTicketsCard from "../Tickets/OpenTicketsCard";
import { setDefaultLayout } from "../../actions/Layout";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router";

class Dashboard extends React.Component {

  componentDidMount() {
    this.props.setDefaultLayout();
  }
  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Dashboard</div>
        </div>
        <Row>
          <Col md={ 6 }>
            <PendingInvoicesCard />
          </Col>
          <Col md={ 6 }>
            <OpenTicketsCard/>
            <ServerStatusPanel/>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

function mapDispatchToProps( dispatch ) {
  return bindActionCreators( { setDefaultLayout }, dispatch )
}

const mapStateToProps = ( state ) => {
  return {}
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( Dashboard ) );
