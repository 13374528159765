import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { getInvoices } from "../../actions/Invoices";
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import Loader from "../Common/Loader";
import { containsRule, countVisibles, equalsZeroRule, isInitialized, meIsAdmin, NewSearch } from "../Common/Utils";
import InvoicesOverviewRow from "./InvoicesOverviewRow";
import bindActionCreators from "redux/src/bindActionCreators";
import connect from "react-redux/es/connect/connect";
import NoResults from "../Common/NoResults";
import Switch from "react-switch";
import SearchBar from "../Common/SearchBar";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Invoices extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            showDrafts: false
        };

        this.search = NewSearch( [
            ( n, c ) => {
                return (containsRule( n, "+" ) && equalsZeroRule( c.grand_total - c.payments_total ))
            },
            ( n, c ) => {
                return (containsRule( n, "-" ) && !equalsZeroRule( c.grand_total - c.payments_total ))
            },
            ( n, c ) => {
                return containsRule( c.id.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.user.name.toLowerCase(), n )
            }
        ], () => Object.values( this.props.invoices )
            .filter( a => a.due_date !== "DRAFT" || this.state.showDrafts ) );
        this.search.onUpdate( () => {this.forceUpdate() } );

    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.search.refresh();
    }

    componentDidMount() {
        this.props.getInvoices();
        this.search.refresh();
    }

    render() {
        const { showDrafts } = this.state;
        const { history } = this.props;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    Invoices
                </div>
                <Row>
                    <Col lg={ 12 }>
                        <Card className="card-default">
                            <CardHeader>
                                <h3 style={ { display: 'inline' } }>{ this.search.printVisibles( [ "invoices", "invoice" ] ) }</h3>
                                { meIsAdmin() &&
                                <Button onClick={ () => {
                                    history.push( '/console/invoices/new' );
                                } } color="primary" className="pull-right">
                                    <FontAwesomeIcon icon={ faPlusCircle } style={{marginRight: '5px'}} />
                                    New Invoice
                                </Button>
                                }
                                <SearchBar search={ this.search }/>
                                { meIsAdmin() &&
                                <Switch className="pull-right"
                                        onChange={ () => this.setState( { showDrafts: !showDrafts }) }
                                        checked={ showDrafts } />
                                }
                            </CardHeader>
                            <CardBody>
                                { isInitialized( this.search.getVisibles() ) ?
                                    <div>
                                        <Table responsive hover>
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                { meIsAdmin() &&
                                                <th>User</th>
                                                }
                                                <th>Date</th>
                                                <th>Due date</th>
                                                <th>Total</th>
                                                <th>Paid</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.search.getVisibles()
                                                    .sort( ( a, b ) => b.date - a.date )
                                                    .map( invoice => {
                                                            return <InvoicesOverviewRow key={ invoice.id }
                                                                                        invoice={ invoice }/>
                                                        }
                                                    )
                                            }
                                            </tbody>
                                        </Table>
                                        { countVisibles( this.search.getVisibles() ) === 0 &&
                                        <NoResults/>
                                        }
                                    </div>
                                    :
                                    <Loader/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ContentWrapper>);
    }
}

const mapStateToProps = ( state ) => {
    return {
        invoices: state.Invoices
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { getInvoices }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( Invoices );
