import React from 'react';
import { Button, Col, Form, FormGroup } from 'reactstrap';
import { createVolume } from "../../actions/Volumes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import {
    ButtonDropdown,
    Card,
    CardBody,
    CardHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import Switch from "react-switch";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import UserSelect from "../User/UserSelect";
import { meIsAdmin } from "../Common/Utils";

class NewVolumePanel extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            name: "",
            engine: "php73",
            template: "html",
            backup: "weekly",
            account: true,
            user_id: null,
            createVolumeButton: { text: "Create", disabled: false, style: "primary" }
        };
        this.createVolume = this.createVolume.bind( this );
    }

    convertBackupPolicy( text ) {
        switch ( text ) {
            case "monthly":
                return 1;
            case "weekly":
                return 5;
            case "bi-weekly":
                return 10;
            case "daily":
                return 20;
            default:
                return 0;
        }
    }

    createVolume( e ) {
        e.preventDefault();
        this.setState( { createVolumeButton: { text: "Creating", disabled: true, style: "warning" } } );
        //name, engine, template, account, backup_policy, user_id

        if ( this.name.value === "" ) {
            this.setState( { createVolumeButton: { disabled: true, text: "Error", style: "danger" } } );
            setTimeout( function () {
                this.setState( { createVolumeButton: { text: "Create", disabled: false, style: "primary" } } );
            }.bind( this ), 5000 );
            return;
        }

        this.props.createVolume( this.name.value, this.state.engine, this.state.template, this.state.account, this.convertBackupPolicy( this.state.backup ), this.state.user_id ).then( volume => {
            this.setState( { createVolumeButton: { text: "Created", disabled: true, style: "success" } } );
            setTimeout( function () {
                this.props.history.push( `/console/volume/${ volume.id }` );
            }.bind( this ), 100 );
        } ).catch( () => {
            this.setState( { createVolumeButton: { disabled: true, text: "Error", style: "danger" } } );
            setTimeout( function () {
                this.setState( { createVolumeButton: { text: "Create", disabled: false, style: "primary" } } );
            }.bind( this ), 5000 );
        } );
    }

    toggleDD = dd => {
        this.setState( {
            [ dd ]: !this.state[ dd ]
        } )
    };


    render() {
        const { user, users } = this.props;
        const { createVolumeButton } = this.state;
        return (
            <Card className="card-default">
                <CardHeader>
                    <h3 style={ { display: 'inline' } }>Create volume</h3>
                </CardHeader>
                <CardBody>
                    <Form className="form-horizontal" onSubmit={ this.createMailbox }>
                        <FormGroup>
                            <Col lg={ 2 }>
                                <Label>Name</Label>
                            </Col>
                            <Col lg={ 10 }>
                                <Input
                                    innerRef={ input => this.name = input }
                                    type="text" placeholder={ `Some name` }/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col lg={ 2 }>
                                <Label>Template</Label>
                            </Col>
                            <Col lg={ 10 }>
                                <ButtonDropdown
                                    isOpen={ this.state[ `ddSingl2e` ] }
                                    toggle={ () => this.toggleDD( `ddSingl2e` ) } id={ `template_button` }>
                                    <DropdownToggle caret>{ this.state.template }</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            onClick={ () => this.setState( { template: "html" } ) }>Static
                                            HTML</DropdownItem>
                                        <DropdownItem
                                            onClick={ () => this.setState( { template: "php" } ) }>PHP
                                            Project</DropdownItem>
                                        <DropdownItem
                                            onClick={ () => this.setState( { template: "wordpress" } ) }>Wordpress</DropdownItem>
                                        <DropdownItem
                                            onClick={ () => this.setState( { template: "wordpress-bedrock" } ) }>Wordpress
                                            Bedrock</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </FormGroup>
                        { (this.state.template !== "html") &&

                        <FormGroup>
                            <Col lg={ 2 }>
                                <Label>Engine</Label>
                            </Col>
                            <Col lg={ 10 }>
                                <ButtonDropdown
                                    isOpen={ this.state[ `ddSingle` ] }
                                    toggle={ () => this.toggleDD( `ddSingle` ) } id={ `engine_button` }>
                                    <DropdownToggle caret>{ this.state.engine }</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            onClick={ () => this.setState( { engine: "" } ) }>None</DropdownItem>
                                        <DropdownItem onClick={ () => this.setState( { engine: "php71" } ) }>PHP
                                            7.1</DropdownItem>
                                        <DropdownItem onClick={ () => this.setState( { engine: "php72" } ) }>PHP
                                            7.2</DropdownItem>
                                        <DropdownItem onClick={ () => this.setState( { engine: "php73" } ) }>PHP
                                            7.3</DropdownItem>
                                        <DropdownItem onClick={ () => this.setState( { engine: "php74" } ) }>PHP
                                            7.4</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </FormGroup>
                        }
                        { (user.admin) &&
                        <FormGroup>
                            <Col lg={ 2 }>
                                <Label>Account</Label>
                            </Col>
                            <Col lg={ 10 }>
                                <Switch onChange={ checked => this.setState( { account: checked } ) }
                                        checked={ this.state.account }/>
                            </Col>
                        </FormGroup>
                        }

                        <FormGroup>
                            <Col sm={ 6 }>
                                <Label>Backup policy</Label>
                            </Col>
                            <Col sm={ 6 }>
                                <ButtonDropdown
                                    isOpen={ this.state[ `ddSing3le` ] }
                                    toggle={ () => this.toggleDD( `ddSing3le` ) } id={ `backup_button` }>
                                    <DropdownToggle caret>{ this.state.backup }</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            onClick={ () => this.setState( { backup: "never" } ) }>never</DropdownItem>
                                        <DropdownItem
                                            onClick={ () => this.setState( { backup: "weekly" } ) }>weekly</DropdownItem>
                                        <DropdownItem
                                            onClick={ () => this.setState( { backup: "bi-weekly" } ) }>bi-weekly</DropdownItem>
                                        <DropdownItem
                                            onClick={ () => this.setState( { backup: "daily" } ) }>daily</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </FormGroup>
                        { meIsAdmin() &&
                        <FormGroup>
                            <Col sm={ 6 }>
                                <Label>User</Label>
                            </Col>
                            <Col sm={ 6 }>
                                <UserSelect users={ users } callback={ user_id => {
                                    this.setState( { user_id } )
                                } }/>
                            </Col>
                        </FormGroup>
                        }
                        <Col lg={ 7 }>
                        </Col>
                        <Col lg={ 5 }>
                            <Button ref="createVolumeButton" onClick={ this.createVolume }
                                    className={ 'pull-right' }
                                    disabled={ createVolumeButton.disabled }
                                    color={ createVolumeButton.style }>
                                { createVolumeButton.text }
                            </Button>
                        </Col>
                    </Form>
                </CardBody>
            </Card>);
    }
}

const mapStateToProps = ( state ) => {
    return {
        user: state.UserInfo,
        users: state.Users
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { createVolume }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( NewVolumePanel ) );
