import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Table, Card, CardBody, CardHeader, Col, Row, Button } from "reactstrap";
import Loader from "../Common/Loader";
import {
    containsRule, countVisibles, isInitialized, NewSearch
} from "../Common/Utils";
import NoResults from "../Common/NoResults";
import VmsOverviewRow from "./VmsOverviewRow";
import NewVmPanel from "./NewVmPanel";
import SearchBar from "../Common/SearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { getVms } from "../../actions/Vms";


class VmsOverview extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            newPanel: false
        };

        this.search = NewSearch( [
            ( n, c ) => {
                return containsRule( c.id.toLowerCase(), n )
            },
            ( n, c ) => {
                return containsRule( c.name.toLowerCase(), n )
            },
            // ( n, c ) => {
            //     return containsRule( c.user.name.toLowerCase(), n )
            // }
        ], () => Object.values( this.props.vms ) );
        this.search.onUpdate( () => this.forceUpdate() );
        this.update = this.update.bind(this);
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    update() {
        this.forceUpdate();
        this.props.getVms();
    }

    componentDidMount() {
        this.props.getVms();
        this.search.refresh();
        let interval = setInterval( () => {
            this.props.getVms();
        }, 45000 );
        this.setState({interval});
    }

    componentWillReceiveProps( nextProps, nextContext ) {
        this.search.refresh();
    }


    render() {
        const { newPanel } = this.state;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    Virtual machines
                </div>
                <Row>
                    <Col lg={ newPanel ? 8 : 12 }>
                        <Card className="card-default">
                            <CardHeader>
                                <h3 style={ { display: 'inline' } }>{ this.search.printVisibles( [ "vms", "vm" ] ) }</h3>
                                { !newPanel &&
                                <Button onClick={ () => {
                                    this.setState( { newPanel: true } )
                                } } color="primary" className="pull-right">
                                    <FontAwesomeIcon icon={ faPlusCircle } style={ { marginRight: '5px' } }/>
                                    New VM
                                </Button>
                                }
                                <SearchBar search={ this.search }/>
                            </CardHeader>
                            <CardBody>
                                { isInitialized( this.search.getVisibles() ) ?
                                    <div>
                                        <Table responsive hover>
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Image</th>
                                                <th>State</th>
                                                <th>Ip</th>
                                                <th>Cpus</th>
                                                <th>Memory</th>
                                                <th>Disk</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            { this.search.getVisibles().map( vm => {
                                                return <VmsOverviewRow callback={this.update} vm={ vm }/>;
                                            } ) }
                                            </tbody>
                                        </Table>
                                        { countVisibles( this.search.getVisibles() ) === 0 &&
                                        <NoResults/>
                                        }
                                    </div>
                                    :
                                    <Loader/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    { newPanel &&
                    <Col lg={ 4 }>
                        <NewVmPanel callback={() => {
                            this.update();
                            this.setState( { newPanel: true } )
                        }}  close={ () => {
                            this.setState( { newPanel: false } )
                        } }/>
                    </Col>
                    }
                </Row>
            </ContentWrapper>);
    }
}

const mapStateToProps = ( state ) => {
    return {
        users: state.Users,
        vms: state.Vms
    }
};

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators( { getVms }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( VmsOverview );
