import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table from "reactstrap/es/Table";
import { withRouter } from "react-router";
import { getTickets } from "../../actions/Tickets";
import Loader from "../Common/Loader";
import { Card, CardBody, CardHeader } from "reactstrap";
import { equalsZeroRule, isInitialized, visibleTitle } from "../Common/Utils";
import TicketRow from "./TicketRow";

class OpenTicketsCard extends Component {

  componentDidMount() {

    this.props.getTickets();
  }

  render() {
    const { userId } = this.props;
    const openTickets = Object.values( this.props.tickets ).filter( a => {
      return equalsZeroRule( a.status );
    } ).filter( a => {
      if ( userId === undefined ) return true;
      return userId === a.user.id
    } );
    return (
      <Card className="card-default">
        <CardHeader>
          <h3>{ visibleTitle( openTickets, ["open tickets","open ticket"] ) }</h3>
        </CardHeader>
        <CardBody>
          { isInitialized( openTickets ) ?

            <Table hover responsive>
              <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Date</th>
              </tr>
              </thead>
              <tbody>
              {
                openTickets.sort( ( a, b ) => a.date - b.date )
                  .map( ticket => {
                    return <TicketRow key={ticket.id} ticket={ticket} userCol={true} nameCol={true} dateCol={true} />; })
              }
              </tbody>
            </Table>

            :
            <Loader/>
          }
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ( state ) => {
  return {
    user: state.UserInfo,
    tickets: state.Tickets
  }
};

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { getTickets }, dispatch )
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( OpenTicketsCard ) );
