import React from 'react';
import { withRouter } from 'react-router';

class RocketLaunch extends React.Component {


  constructor(props) {
    super(props);

    this.canvas = undefined;
    this.context = undefined;
    this.imageObj = undefined;
    this.imageObjBurn = undefined;

    this.start_canvas_animation = this.start_canvas_animation.bind( this );
    this.drawGround = this.drawGround.bind( this );
    this.drawAir = this.drawAir.bind( this );
    this.drawCloud = this.drawCloud.bind( this );
    this.drawStar = this.drawStar.bind( this );
    this.drawRocket = this.drawRocket.bind( this );
    this.animateRocket = this.animateRocket.bind( this );
  }

  animateRocket( y ) {
    const context = this.refs.canvas.getContext( '2d' );

    if ( y < 20 ) {
      y = y + 1;
    }
    if ( y >= 20 && y < 60 ) {
      y = y + 2;
    }
    if ( y >= 60 && y < 80 ) {
      y = y + 3;
    }
    if ( y >= 80 && y < 150 ) {
      y = y + 4;
    }
    if ( y >= 150 ) {
      y = y + 10;
    }

    if ( y > 10000 ) {
      y = 1700;
    }

    var rocketY = y;
    var backY = 0;
    if ( rocketY > ( ( this.canvas.height / 2 ) - ( this.canvas.height / 3 ) ) ) {
      rocketY = ( ( this.canvas.height / 2 ) - ( this.canvas.height / 3 ) );
      backY = y - ( ( this.canvas.height / 2 ) - ( this.canvas.height / 3 ) );
    }

    context.clearRect( 0, 0, this.canvas.width, this.canvas.height );


    this.drawAir( context, y );
    this.drawGround( context, backY );

    var clouds = [
      { x: 70, y: 100 },
      { x: 1200, y: 110 },
      { x: 600, y: 220 },
      { x: 1000, y: 320 },
      { x: 200, y: 490 },
      { x: 650, y: 550 },
      { x: 200, y: 660 },
      { x: 1050, y: 770 },
      { x: 240, y: 880 },
      { x: 670, y: 990 },
      { x: 980, y: 1100 }
    ];

    for ( let i = 0; i < clouds.length; i++ ) {
      this.drawCloud( context, clouds[ i ].x, clouds[ i ].y, backY );
    }

    var stars = [
      { x: 300, y: 500 },
      { x: 1240, y: 600 },
      { x: 800, y: 750 },
      { x: 100, y: 800 },
      { x: 1400, y: 1000 },
      { x: 750, y: 1050 },
      { x: 200, y: 1300 },
      { x: 500, y: 1600 }
    ];

    for ( var i = 0; i < stars.length; i++ ) {
      this.drawStar( context, stars[ i ].x, stars[ i ].y, backY );
    }

    this.drawRocket( context, rocketY, true );

    var self = this;
    if ( y === 1 ) {
      setTimeout( function() {
        self.animateRocket( y );
      }, 1000 );
    } else {
      window.requestAnimFrame( function() {
        self.animateRocket( y );
      } );
    }

  }

  componentDidMount() {
    this.canvas = this.refs.canvas;
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    window.requestAnimFrame = ( function( callback ) {
      return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame ||
        function( callback ) {
          window.setTimeout( callback, 1000 / 60 );
        };
    } )();
    this.start_canvas_animation();

  }

  start_canvas_animation() {
    this.context = this.canvas.getContext( '2d' );

    this.imageObj = new Image();
    this.imageObjBurn = new Image();
    var self = this;
    this.imageObj.onload = function() {
      self.animateRocket( 0 );
    };
    this.imageObj.src = '/img/rocket/rocket.png';
    this.imageObjBurn.src = '/img/rocket/fire.png';

  }

  drawGround( context, baseY ) {
    if ( baseY <= ( this.canvas.height / 3 ) ) {
      context.beginPath();
      context.rect( 0, ( this.canvas.height / 3 ) * 2 + baseY, this.canvas.width, this.canvas.height / 3 );
      context.fillStyle = '#bcd55e';
      context.fill();
    }
  }

  drawAir( context, y ) {
    context.beginPath();
    context.rect( 0, 0, this.canvas.width, this.canvas.height );
    y -= 900;
    if ( y > 0 ) {
      var green = ( 255 - ( Math.floor( y / 4 ) ) < 11 ? 11 : 212 - ( Math.floor( y / 4 ) ) );
      var blue = ( 255 - ( Math.floor( y / 4 ) ) < 70 ? 70 : 255 - ( Math.floor( y / 4 ) ) );
      context.fillStyle = 'rgb(0,' + green + ',' + blue + ')';
    } else {
      context.fillStyle = 'rgb(54,212,255)';
    }
    context.fill();
  }

  drawCloud( context, x, y, baseY ) {
    y -= this.canvas.height / 2;
    if ( baseY > y - this.canvas.height && baseY < y + this.canvas.height + 50 ) {
      context.beginPath();
      context.arc( x, ( baseY - y ) + 8, 20, 0, 2 * Math.PI, false );
      context.fillStyle = 'white';
      context.fill();
      context.beginPath();
      context.arc( x + 30, ( baseY - y ), 30, 0, 2 * Math.PI, false );
      context.fillStyle = 'white';
      context.fill();
      context.beginPath();
      context.arc( x + 60, ( baseY - y ) + 1, 28, 0, 2 * Math.PI, false );
      context.fillStyle = 'white';
      context.fill();
      context.beginPath();
      context.arc( x + 90, ( baseY - y ) + 4, 20, 0, 2 * Math.PI, false );
      context.fillStyle = 'white';
      context.fill();
    }
  }

  drawStar( context, x, y, baseY ) {
    baseY = baseY / 5;
    y -= this.canvas.height / 2;
    if ( baseY > y - this.canvas.height && baseY < y + this.canvas.height + 50 ) {
      context.beginPath();
      context.arc( x, ( baseY - y ), 1, 0, 2 * Math.PI, false );
      context.fillStyle = 'white';
      context.fill();
    }
  }

  drawRocket( context, y ) {
    if ( y > 1 ) {
      var xDiff = ( Math.floor( Math.random() * 2 ) + 1 )
      context.drawImage( this.imageObjBurn, ( this.canvas.width / 3 ) - 25 + xDiff, ( ( this.canvas.height / 3 ) * 2 ) - y + 28, 50 - xDiff * 2, 55 + ( Math.floor( Math.random() * 5 ) + 1 ) * 2 );
    }
    context.drawImage( this.imageObj, ( this.canvas.width / 3 ) - 50, ( ( this.canvas.height / 3 ) * 2 ) - y - 120, 100, 160 );
  }


  render() {
    return (
        <canvas width="1500" height="1000" ref="canvas" style={ { width: '100%', background: '#36d4f0' } }/>
    );
  }

}

export default withRouter( RocketLaunch );
