import React from 'react';
import Timestamp from "react-timestamp";
import Loader from "../Common/Loader.jsx";
import { Card, CardBody, CardHeader } from "reactstrap";


class TicketMessagePanel extends React.Component {

  constructor() {
    super();
    this.state = {
      message: undefined
    };
  }

  componentDidMount() {
    this.setState( { message: this.props.message } );
  }

  componentWillReceiveProps( props ) {
    this.setState( { message: props.message } );
  }
  breakLine(text) {
    var br = React.createElement('br');
    var regex = /(<br \/>)/g;
    return text.split(regex).map(function(line, index) {
      return line.match(regex) ? <br key={"key_" + index} /> : line;
    });
  }

  render() {
    const { message } = this.state;
    return (
      message !== undefined ?
        <Card className="card-default">
          <CardHeader>
            <div className="media mt0">
              <div className="media-left">
                { message.user === undefined || message.user === null ?
                  <img className="align-self-start mx-2 circle thumb32" src="/img/logo_colored.png" alt="Avatar" />
                  :
                  <img className="align-self-start mx-2 circle thumb32" src={ message.user.avatar } alt="Avatar" />
                }

              </div>
              <div className="media-body media-middle">
                <h5 className="media-heading m0 text-bold" style={{marginBottom: '0'}}>{ message.user === undefined || message.user === null ?
                  <span>PushTo.Space</span>
                  :
                  message.user.name
                }</h5>
                <small className="text-muted"><Timestamp date={ message.date }/></small>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <p>{ message.user === undefined || message.user === null ?
              <b>{message.content}</b>
              :
              this.breakLine(message.content)
            }</p>
          </CardBody>
        </Card>
        :
        <Loader/>
    );
  }
}

export default TicketMessagePanel;

