import { createRequestTypes, action } from './Util';
import * as api from '../services/Api';

export const GET_USERS = createRequestTypes('GET_USERS');
export const SEND_CONTACT = createRequestTypes('SEND_CONTACT');
export const SIGNUP_BETA = createRequestTypes('SIGNUP_BETA');
export const GET_USER_MANDATES = createRequestTypes('GET_USER_MANDATES');
export const CHECK_POSTAL_CODE = createRequestTypes('CHECK_POSTAL_CODE');
export const CREATE_NEW_USER = createRequestTypes('CREATE_NEW_USER');
export const CREATE_MANDATE_PAYMENT = createRequestTypes('CREATE_MANDATE_PAYMENT');

export const getUserMandatesDispatchers = {
    request: () => action(GET_USER_MANDATES.REQUEST),
    success: (buckets) => action(GET_USER_MANDATES.SUCCESS, buckets),
    failure: (error) => action(GET_USER_MANDATES.FAILURE, error),
};
export const signupBetaDispatchers = {
    request: () => action(SIGNUP_BETA.REQUEST),
    success: (result) => action(SIGNUP_BETA.SUCCESS, result),
    failure: (error) => action(SIGNUP_BETA.FAILURE, error),
};
export const sendContactDispatchers = {
    request: () => action(SEND_CONTACT.REQUEST),
    success: (result) => action(SEND_CONTACT.SUCCESS, result),
    failure: (error) => action(SEND_CONTACT.FAILURE, error),
};

export const getUsersDispatchers = {
    request: () => action(GET_USERS.REQUEST),
    success: (buckets) => action(GET_USERS.SUCCESS, buckets),
    failure: (error) => action(GET_USERS.FAILURE, error),
};

export const createMandatePaymentDispatchers = {
    request: () => action(CREATE_MANDATE_PAYMENT.REQUEST),
    success: (payment) => action(CREATE_MANDATE_PAYMENT.SUCCESS, payment),
    failure: (error) => action(CREATE_MANDATE_PAYMENT.FAILURE, error),
};

export const checkPostalCodeDispatchers = {
    request: () => action(CHECK_POSTAL_CODE.REQUEST),
    success: (buckets) => action(CHECK_POSTAL_CODE.SUCCESS, buckets),
    failure: (error) => action(CHECK_POSTAL_CODE.FAILURE, error),
};

export const createNewUserDispatchers = {
    request: () => action(CREATE_NEW_USER.REQUEST),
    success: (user) => action(CREATE_NEW_USER.SUCCESS, user),
    failure: (error) => action(CREATE_NEW_USER.FAILURE, error),
};


export function getUsers() {
    return dispatch => {
        dispatch(getUsersDispatchers.request());
        return api.getUsers()
            .then(users => {
                dispatch(getUsersDispatchers.success(users));
                return users;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(getUsersDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function sendContactMessage(name, email, message) {
    return dispatch => {
        dispatch(sendContactDispatchers.request());
        return api.sendContactMessage(name, email, message)
            .then(result => {
                dispatch(sendContactDispatchers.success(result));
                return result;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(sendContactDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}
export function signupBeta(email) {
    return dispatch => {
        dispatch(signupBetaDispatchers.request());
        return api.signupBeta(email)
            .then(result => {
                dispatch(signupBetaDispatchers.success(result));
                return result;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(signupBetaDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function getMandates(user_id) {
    return dispatch => {
        dispatch(getUserMandatesDispatchers.request());
        return api.getUserMandates(user_id)
            .then(mandates => {
                dispatch(getUserMandatesDispatchers.success(mandates));
                return mandates;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(getUserMandatesDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function createMandatePayment(user_id, redirect) {
    return dispatch => {
        dispatch(createMandatePaymentDispatchers.request());
        return api.createUserMandatePayment(user_id, redirect)
            .then(payment => {
                dispatch(createMandatePaymentDispatchers.success(payment));
                return payment;
            })
            .catch(resp => {
                const msg = resp.message;
                dispatch(createMandatePaymentDispatchers.failure(msg));
                return Promise.reject(msg);
            });
    };
}

export function checkPostalCode(country_code, postal_code, number) {
  return dispatch => {
    dispatch(checkPostalCodeDispatchers.request());
    return api.getPostalCodeDetails(country_code, postal_code, number)
        .then(details => {
          dispatch(checkPostalCodeDispatchers.success(details));
          return details;
        })
        .catch(resp => {
          const msg = resp.message;
          dispatch(checkPostalCodeDispatchers.failure(msg));
          return Promise.reject(msg);
        });
  };
}

export function createNewUser(username, addressTitle, streetName, streetNumber, postalCode, city) {
  return dispatch => {
    dispatch(createNewUserDispatchers.request());
    return api.createNewUser(username, addressTitle, streetName, streetNumber, postalCode, city)
        .then(user => {
          dispatch(createNewUserDispatchers.success(user));
          return user;
        })
        .catch(resp => {
          const msg = resp.error;
          dispatch(createNewUserDispatchers.failure(msg));
          return Promise.reject(msg);
        });
  };
}
