import React from 'react';
import { withRouter } from 'react-router';
import Hero from "./hero.js";
import classnames from "classnames";
import StatusPanel from "./StatusPanel";
import { Link } from "react-router-dom";
import KeyFeatures from "./KeyFeatures";
import HighlightBlock from "./HighlightBlock";
import HomepageCardBlock from "./HomepageCardBlock";
import UserGreetingOrLogin from "./UserGreetingOrLogin";
import ContactForm from "./ContactForm";

class Home extends React.Component {

    render() {

        return (
            <div
                style={ { display: 'block', margin: '0', marginTop: '0', width: '100%', height: '450px' } }>
                <Hero content={
                    <div style={ { marginLeft: "100px" } }>
                        <h1 className={ 'triangle-title' }>Your managed hosting.</h1>
                        <p className={ 'not-mobile' } style={ {
                            color: 'white',
                            marginTop: '20px',
                            width: '60%',
                            marginLeft: '5%',
                            fontSize: '20px'
                        } }>With own servers we are able to deliver high quality hosting and keeping the costs low.</p>
                        <li className={ classnames( { 'cssmenu-link': true, 'mobile': true } ) }><Link style={ {
                            marginLeft: '20px',
                            cursor: 'pointer',
                            border: '1px solid white',
                            borderRadius: '20px',
                            padding: '15px 15px',
                            color: "white",
                            textDecorationUnderline: 'none'
                        } } to={ `/console/dashboard` }><UserGreetingOrLogin/></Link></li>
                    </div>
                }/>

                <HomepageCardBlock/>

                <HighlightBlock/>

                <StatusPanel/>

                <KeyFeatures/>

                <ContactForm/>

            </div>

        );
    }

}

export default withRouter( Home );
