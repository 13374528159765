import React from 'react';
import Loader from "../Common/Loader.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import MailDomainDetailsPanelDkimRow from "./MailDomainDetailsPanelDkimRow";
import MailDomainDetailsPanelSpfRow from "./MailDomainDetailsPanelSpfRow";
import MailDomainDetailsPanelMxRow from "./MailDomainDetailsPanelMxRow";
import { Link } from "react-router-dom";


class MailDomainDetailsPanel extends React.Component {

    renderProvider( provider ) {
        switch ( provider ) {
            case "local":
                return (<span><img src={ '/img/logo_colored.png' } style={ { marginRight: '10px', height: '15px' } }
                                   alt={ "" }/>PushTo.Space</span>);
            case "google":
                return (
                    <span><img src={ '/img/google.png' } style={ { marginRight: '10px', height: '15px' } } alt={ "" }/>Google</span>);
            default:
                return (<span>Unkown</span>);
        }
    }

    render() {
        const { domain } = this.props;
        return (
            <Card className="card-default">
                <CardHeader>
                    <h3>Details</h3>
                </CardHeader>
                <CardBody>
                    { (domain !== undefined && domain.name !== undefined) ?
                        <Table responsive hover>
                            <tbody>
                            <tr>
                                <td>
                                    <strong>Domain</strong>
                                </td>
                                <td><Link
                                    to={ `/console/domain/${ domain.id }` }>{ domain.name }.{ domain.tld.name }</Link>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <strong>Provider</strong>
                                </td>
                                <td>{ this.renderProvider( domain.mail ) }</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Mx-records</strong>
                                </td>
                                <MailDomainDetailsPanelMxRow domainId={ domain.id }/>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Spf</strong>
                                </td>
                                <MailDomainDetailsPanelSpfRow domainId={ domain.id }/>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Dkim</strong>
                                </td>
                                <MailDomainDetailsPanelDkimRow domainId={ domain.id }/>
                            </tr>

                            </tbody>

                        </Table>
                        :
                        <Loader/>
                    }
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        user: state.UserInfo,
        domains: state.Domains
    }
};


export default connect( mapStateToProps )( withRouter( MailDomainDetailsPanel ) );
