import React from 'react';
import { Button, Col, FormGroup, Table } from 'reactstrap';
import Loader from "../Common/Loader.jsx";
import { connect } from "react-redux";
import { createNewFtpUser, deleteFtpUser, getVolumes } from "../../actions/Volumes";
import { bindActionCreators } from "redux";
import Form from "reactstrap/es/Form";
import { Card, CardBody, CardHeader } from "reactstrap";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";


class VolumeFtpPanel extends React.Component {

  constructor() {
    super();
    this.state = {
      volume: undefined,
      addingFtpUser: false,
      addFtpUserButton: { text: "Create", disabled: false, style: "primary" }
    };
    this.addFtpUser = this.addFtpUser.bind( this );
    this.deleteFtpUser = this.deleteFtpUser.bind( this );
  }

  componentDidMount() {
    this.setState( { volume: this.props.volume } );
  }

  componentWillReceiveProps( nextProps, nextContext ) {
    this.setState( { volume: nextProps.volume } );
  }

  addFtpUser() {
    this.setState( { addFtpUserButton: { text: "Creating...", disabled: true, style: "warning" } } );
    this.props.createNewFtpUser( this.props.volume.id, this.passwordInput.value ).then( ftp => {
      this.passwordInput.value = "";
      let volume = this.state.volume;
      volume.ftp.push( ftp );
      this.setState( { volume, addingFtpUser: false } );
      this.setState( { addFtpUserButton: { text: "Add", disabled: false, style: "primary" } } );
    } ).catch( () => {
      this.setState( { addFtpUserButton: { disabled: true, text: "Error", style: "danger" } } );
      setTimeout( function() {
        this.setState( { addFtpUserButton: { text: "Add", disabled: false, style: "primary" } } );
      }.bind( this ), 5000 );
    } );
  }

  deleteFtpUser( ftpId ) {
    let volume = this.state.volume;
    let ftps = volume.ftp;
    volume.ftp = [];
    ftps.map( ftp => {
      if ( ftp.id !== ftpId ) {
        volume.ftp.push( ftp );
      }
      return true;
    } );
    this.setState( {
      volume
    } );
    this.props.deleteFtpUser( this.props.volume.id, ftpId );
  }
  render() {
    const { volume, addFtpUserButton } = this.state;
    const { addFtpUser, deleteFtpUser } = this;
    return (
      <Card className="card-default">
        <CardHeader>
          <div style={ { fontSize: '14px' } }>
            FTP
            { ( this.state.addingFtpUser ) ?
              volume !== undefined && volume.ftp !== undefined && volume.ftp.length > 0 &&
              <Button onClick={ () => {
                this.setState( {
                  addingFtpUser: false
                } );
              } } className={ 'pull-right' } color={ "danger" } style={ { color: 'white' } }>Cancel</Button>
              :
              volume !== undefined && volume.ftp !== undefined && volume.ftp.length > 0 &&
              <Button onClick={ () => {
                this.setState( {
                  addingFtpUser: true
                } );
              } } className={ 'pull-right' } color={ "primary" } style={ { color: 'white' } }>New
                ftp-user</Button>
            }
          </div>
        </CardHeader>
        <CardBody>
          { ( volume !== undefined && volume.ftp !== undefined ) ?
            !this.state.addingFtpUser && volume.ftp.length > 0 ?
              <Table responsive hover>
                <thead>
                <tr>
                  <th>User</th>
                  <th>Password</th>
                  <th />
                </tr>
                </thead>
                <tbody>
                {
                  volume.ftp.map( ftp => {
                      return <tr key={ ftp.id }>
                        <td>{ ftp.username }</td>
                        <td><i>Hidden</i></td>
                        <td><span onClick={ () => { deleteFtpUser( ftp.id ); } }
                                  style={ { color: "red", cursor: "pointer" } }
                                  className="fa fa-times" />
                        </td>
                      </tr>
                    }
                  )
                }
                </tbody>
              </Table>
              :
              <Form className="form-horizontal">
                <FormGroup>
                  <Col lg={ 2 }>
                    <Label>Username</Label>
                  </Col>
                  <Col lg={ 10 }>
                    <Input
                      disabled type="text" value="Generated"/>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col lg={ 2 }>
                    <Label>Password</Label>
                  </Col>
                  <Col lg={ 10 }>
                    <Input
                      innerRef={ input => this.passwordInput = input }
                      type="password" placeholder="Password"/>
                  </Col>
                </FormGroup>
                <Button ref="addFtpUserButton" onClick={ addFtpUser }
                        className={ 'btn-block' }
                        disabled={ addFtpUserButton.disabled }
                        color={ addFtpUserButton.style }>
                  { addFtpUserButton.text }
                </Button>
              </Form>
            :
            <Loader/>
          }
        </CardBody>
      </Card>
    );
  }
}

const mapDispatchToProps = ( dispatch ) => {
  return bindActionCreators( { createNewFtpUser, deleteFtpUser, getVolumes }, dispatch )
};

export default connect( null, mapDispatchToProps )( VolumeFtpPanel );

