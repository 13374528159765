import { createRequestTypes, action } from './Util';
import * as api from '../services/Api';

export const GET_TAX_EXPENSE = createRequestTypes( 'GET_TAX_EXPENSE' );
export const GET_TAX_EXPENSES = createRequestTypes( 'GET_TAX_EXPENSES' );
export const CREATE_TAX_EXPENSE = createRequestTypes( 'CREATE_TAX_EXPENSE' );

export const getTaxExpensesDispatchers = {
  request: () => action( GET_TAX_EXPENSES.REQUEST ),
  success: ( expenses ) => action( GET_TAX_EXPENSES.SUCCESS, expenses ),
  failure: ( error ) => action( GET_TAX_EXPENSES.FAILURE, error ),
};
export const getTaxExpenseDispatchers = {
  request: () => action( GET_TAX_EXPENSE.REQUEST ),
  success: ( expense ) => action( GET_TAX_EXPENSE.SUCCESS, expense ),
  failure: ( error ) => action( GET_TAX_EXPENSE.FAILURE, error ),
};
export const createTaxExpenseDispatchers = {
  request: () => action( CREATE_TAX_EXPENSE.REQUEST ),
  success: ( expense ) => action( CREATE_TAX_EXPENSE.SUCCESS, expense ),
  failure: ( error ) => action( CREATE_TAX_EXPENSE.FAILURE, error ),
};

export function getExpenses() {
  return dispatch => {
    dispatch( getTaxExpensesDispatchers.request() );
    return api.getTaxExpenses()
      .then( expenses => {
        dispatch( getTaxExpensesDispatchers.success( expenses ) );
        return expenses;
      } )
      .catch( resp => {
        const msg = resp.message;
        dispatch( getTaxExpensesDispatchers.failure( msg ) );
        return Promise.reject( msg );
      } );
  };
}

export function getExpense(id) {
  return dispatch => {
    dispatch( getTaxExpenseDispatchers.request() );
    return api.getTaxExpense(id)
      .then( expense => {
        dispatch( getTaxExpenseDispatchers.success( expense ) );
        return expense;
      } )
      .catch( resp => {
        const msg = resp.message;
        dispatch( getTaxExpenseDispatchers.failure( msg ) );
        return Promise.reject( msg );
      } );
  };
}

export function createNewExpense( target_id, target_name, name, date, price, attachment) {
  return dispatch => {
    dispatch(createTaxExpenseDispatchers.request());
    return api.createTaxExpense(target_id, target_name, name, date, price, attachment)
      .then(expense => {
        dispatch(createTaxExpenseDispatchers.success(expense));
        return expense;
      })
      .catch(resp => {
        const msg = resp.message;
        dispatch(createTaxExpenseDispatchers.failure(msg));
        return Promise.reject(msg);
      });
  };
}
